@media (max-width: 450px) {
  .ditj-dialog {
    width: 300px;
  }
  .ditj-preview-wrapper {
    max-width: 260px;
    max-height: 196px;
  }
  .ditj-preview-wrapper .ditj-question-preview {
    transform: scale(0.63);
    transform-origin: top left;
  }
  .ditj-question-form-preview {
    min-height: 150px;
  }
  .ditj-question-form-preview .ditj-preview-wrapper {
    position: fixed;
    z-index: 1;
    bottom: 80px;
  }
  .ditj-form .ditj-answers {
    text-align: center;
  }
  .ditj-form .ditj-answers .answer {
    float: none;
    vertical-align: middle;
    padding: 20px;
  }
  .ditj-form .ditj-answers .answer .form-control,
  .ditj-form .ditj-answers .answer .form-group {
    width: 100%;
    vertical-align: middle;
  }
  .ditj-form .ditj-answers .answer .font-color-container {
    vertical-align: middle;
  }
  .ditj-form .ditj-answers .answer .btn {
    width: 203px;
  }
  .ditj-form .ditj-answers .answer .font-family {
    width: 203px;
  }
}
