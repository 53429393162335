@import 'react-flagpack/dist/style.css';
.left-bar {
  position: fixed;
  z-index: 1001;
  background: #c8c8c8;
  color: white;
  width: 240px;
  height: 100vh;
  left: 0;
  top: 0;
  transition: all 500ms;
}
.left-bar.hidden {
  left: -240px;
}
.left-bar .link-container a {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  font-size: 1.1rem;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.left-bar .nav-icon {
  padding-right: 10px;
}
.left-bar .nav-item {
  background: #323232;
  transition: background 1s;
}
.left-bar .nav-item.mygames {
  background: #211d70;
}
.left-bar .nav-item.organization {
  background: #d6de23;
}
.left-bar .nav-item.myfloor {
  background: #00A79D;
}
.left-bar .nav-item.playlist {
  background: #00c736;
}
.left-bar .nav-item.users {
  background: #652d90;
}
.left-bar .nav-item.floor {
  background: #f48120;
}
.left-bar .nav-item.library {
  background: #00adee;
}
.left-bar .nav-item.tutorials {
  background: #3C3C3C;
}
.left-bar .nav-item.stats {
  background: #e61088;
}
.left-bar .nav-item:hover {
  background: #710640;
}
.left-bar .nav-item:hover > a {
  padding-left: 35px;
}
.left-bar .nav-item:hover > a svg {
  scale: 1.3;
}
.left-bar .nav-item a {
  transition: padding 200ms;
  color: white;
}
.left-bar .nav-item a svg {
  transition: scale 500ms;
}
.left-bar .nav-item.active {
  background: white;
}
.left-bar .nav-item.active a {
  padding-left: 35px;
  color: black;
}
.left-bar .nav-item.active a svg {
  scale: 1;
}
.left-bar .nav-item .sub-nav {
  transition: background 1s;
  position: relative;
  background: #63424f;
}
.left-bar .nav-item .sub-nav:first-of-type::before {
  content: "";
  top: 0;
  position: absolute;
  display: block;
  height: 7px;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
}
.left-bar .nav-item .sub-nav a {
  color: white;
  transition: padding 200ms;
}
.left-bar .nav-item .sub-nav svg {
  transition: scale 500ms;
}
.left-bar .nav-item .sub-nav:hover {
  background: #710640;
}
.left-bar .nav-item .sub-nav:hover a {
  padding-top: 5px;
  padding-bottom: 5px;
}
.left-bar .nav-item .sub-nav:hover svg {
  scale: 1.5;
}
.left-bar .nav-item .sub-nav.active {
  background: white;
}
.left-bar .nav-item .sub-nav.active a {
  padding-left: 45px;
  color: black;
  padding-top: 0;
  padding-bottom: 0;
}
.left-bar .nav-item .sub-nav.active svg {
  scale: 1;
}
.left-bar .toggle-menu {
  position: absolute;
  top: 0;
  right: -40px;
  width: 40px;
  height: 40px;
  background: #787878;
  border-bottom-right-radius: 15px;
}
.left-bar .toggle-menu .icon {
  width: 20px;
  height: 20px;
  margin: 10px;
}
@media screen and (min-width: 768px) {
  .left-bar .toggle-menu {
    display: none;
  }
  .left-bar.hidden {
    left: 0;
  }
}
.content {
  margin-top: 40px;
}
html body .version-pixel {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  cursor: pointer;
}
html body .changelog-dialog .changelog ul {
  margin-bottom: 30px;
}
html body .changelog-dialog .changelog ul li {
  margin-bottom: 2px;
  margin-left: 10px;
  list-style: "- ";
}
@media screen and (min-width: 768px) {
  .left-bar + .content {
    margin-left: 240px;
  }
  .content {
    margin-top: 65px;
  }
}
.content .discover .focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}
.content .discover .carousel {
  position: relative;
}
.content .discover .carousel li:focus {
  outline: none;
}
.content .discover .carousel .carousel-btn {
  position: absolute;
  top: calc(50% - 1em);
  right: 1%;
  height: 2em;
  line-height: 2em;
  font-size: 2em;
  background: none;
  border: none;
  color: white;
  text-shadow: 0 0 5px black;
  cursor: pointer;
}
.content .discover .carousel .carousel-btn:focus {
  transform: scale(1.1);
  outline: none;
}
.content .discover .carousel .carousel-btn.back {
  right: initial;
  left: 1%;
}
.content .discover .rows {
  margin-top: 20px;
}
.content .discover a {
  color: inherit;
}
.content .discover .component-slider-container .hover-trigger-2 .hover-msg-2 {
  bottom: 2em;
  pointer-events: initial;
}
.content .discover .row {
  position: relative;
  padding-bottom: 20px;
}
.content .discover .row:hover {
  z-index: 1;
}
.content .discover .row h2 {
  padding-left: 40px;
}
.content .discover .row .author-card {
  position: relative;
  width: 100%;
  background: linear-gradient(22.5deg, #000038, #00a79d);
  color: white;
  text-align: left;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
}
.content .discover .row .author-card .close-x {
  position: absolute;
  top: 0;
  right: 0;
  width: 1em;
  font-size: 5em;
  cursor: pointer;
  text-align: center;
}
.content .discover .row .author-card .close-x path {
  transition: all 200ms;
  transform-origin: center;
}
.content .discover .row .author-card .close-x:hover path {
  transform: rotate(360deg);
  color: #AA0000;
}
.content .discover .row .author-card .left {
  width: 75%;
  text-align: left;
}
.content .discover .row .author-card .left .popular-games {
  position: relative;
  min-height: 200px;
  max-height: 230px;
  overflow: hidden;
  display: flex;
  gap: 10px;
}
.content .discover .row .author-card .left .account-title {
  margin-bottom: 1vw;
}
.content .discover .row .author-card .left .account-title .author-name {
  font-size: calc(16pt + 0.5vw);
  height: calc(16pt + 0.5vw);
  font-weight: bold;
  cursor: pointer;
  transition: all 200ms;
}
.content .discover .row .author-card .left .account-title .author-name .to-public {
  padding-left: 5px;
  font-size: calc(1pt + 0.5vw);
}
.content .discover .row .author-card .left .account-title .author-name:hover {
  color: #f48120;
}
.content .discover .row .author-card .left .account-title .org-name {
  font-style: italic;
  font-size: calc(10pt + 0.5vw);
  transition: all 200ms;
}
.content .discover .row .author-card .left .account-title .org-name:hover {
  padding-left: 5px;
  color: #f48120;
}
.content .discover .row .author-card .left .taglist {
  margin-bottom: 10px;
}
.content .discover .row .author-card .left .author-description {
  font-size: calc(8pt + 0.5vw);
  line-break: auto;
  height: calc((8pt + 0.5vw) * 4 + 1px);
  margin-bottom: 10px;
  white-space: normal;
  overflow: hidden;
}
.content .discover .row .author-card .right {
  width: 25%;
  padding-right: 5%;
  box-sizing: border-box;
}
.content .discover .row .author-card .right .author-preview {
  width: 100%;
  height: initial;
  border-radius: 100%;
  max-height: 100%;
}
.content .discover .game-slider-preview-wrapper {
  min-height: 100px;
  overflow: hidden;
}
.content .discover .game-slider-preview-wrapper.hidden {
  height: 0;
  min-height: 0;
}
.content .org-profile {
  padding: 40px;
  min-height: 80vh;
}
.content .org-profile a {
  color: inherit;
}
.content .org-profile .filters-container {
  margin-bottom: 20px;
}
.content .org-profile .container {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 20px;
}
.content .org-profile .container h2 {
  font-size: 1.8em;
  display: inline-block;
}
.content .org-profile .container h2:after {
  content: "";
  display: block;
  width: 33%;
  margin: 10px 0 20px 0;
  box-sizing: content-box;
  border-bottom: 1px solid rgba(50, 50, 50);
}
.content .org-profile .author-list {
  display: flex;
  flex-wrap: wrap;
}
.content .org-profile .author-list .author-preview {
  float: none;
}
.content .org-profile .game-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.content .org-profile .game-list .game {
  float: none;
}
.create-account-form-dialog .author-preview {
  float: none;
}
.content .users {
  padding: 40px;
  min-height: 200px;
}
.content .users .color-warning {
  color: #d4d100;
}
.content .users .color-error {
  color: red;
}
.content .users .color-success {
  color: black;
}
.content .users .search-form .form-group .form-control {
  color: black;
  border-color: black;
}
.content .users form {
  margin: 0;
}
.content .user-group-select {
  margin-top: 10px;
}
.content .user-group h3 {
  font-size: 1.5em;
  font-weight: bold;
}
.content .user-group .user-group-header {
  display: flex;
  gap: 15px;
  align-items: baseline;
}
.content .user-group .user-group-header .collapse {
  cursor: pointer;
}
.content .user-group .user-group-header .user-group-name {
  padding: 8px 0;
  width: fit-content;
  margin-left: 0;
}
.content .user-group .user-group-header .user-group-name.edit {
  cursor: pointer;
}
.content .user-group .user-group-header .user-group-name.edit:hover {
  border: 3px dashed #676565;
  border-radius: 15px;
  padding: 5px;
  margin-left: -8px;
}
.content .user-group .user-group-header .user-group-name.uncategorized {
  color: #bdb5b5;
}
.content .user-container {
  position: relative;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
}
.content .user-container .user-card {
  position: relative;
  background: linear-gradient(120deg, #f0f0f0, #ffffff);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  margin: 0 20px 20px 0;
  padding: 15px;
  width: 250px;
  transition: all 200ms;
  cursor: pointer;
}
.content .user-container .user-card .last-login-info {
  font-size: 0.9em;
  margin-bottom: 4px;
}
.content .user-container .user-card .last-login-info .label {
  font-weight: bold;
}
.content .user-container .user-card .last-login-info span {
  color: darkcyan;
  cursor: pointer;
}
.content .user-container .user-card .last-login-info span:hover {
  color: purple;
}
.content .user-container .user-card:hover {
  box-shadow: 0 0 5px black;
}
.content .user-container .user-card .name {
  box-sizing: border-box;
}
.content .user-container .user-card .profile-img {
  width: 100px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: white;
}
.content .user-container .user-card .profile-name {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  margin: 0.5em 0;
}
.content .user-container .user-card .profile-email {
  margin-bottom: 0.5em;
  font-weight: bold;
}
.content .user-container .user-card .permissions-title {
  box-sizing: border-box;
  padding-right: 10px;
  font-size: 0.8em;
  font-weight: bold;
  width: 100%;
}
.content .user-container .user-card .permissions {
  box-sizing: border-box;
  bottom: 0.2em;
  padding: 0 10px 5px 0;
  width: 100%;
}
.content .user-container .user-card .permissions div {
  font-size: 0.7em;
}
.content .user-container .user-card .permissions .enabled {
  color: green;
}
.content .user-container .user-card .permissions .disabled {
  color: grey;
}
.content .user-container .user-card.deactivated {
  opacity: 0.5;
}
.content .edit-group-dialog .edit-user-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.content .edit-group-dialog .edit-user-group .user-group-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content .edit-group-dialog .edit-user-group .existing-members {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.content .edit-group-dialog .edit-user-group .existing-members .actions {
  padding-top: 30px;
  display: flex;
  align-self: flex-end;
  gap: 20px;
}
.content .edit-group-dialog .edit-user-group .existing-members .action {
  cursor: pointer;
}
.content .edit-group-dialog .edit-user-group .existing-members .action:hover {
  color: #AA0000;
}
.content .edit-group-dialog .edit-user-group .existing-members .action .hover-msg {
  min-width: 100px;
  bottom: 20px;
  left: -80px;
}
.content .edit-group-dialog .edit-user-group .existing-members .simple-table td {
  overflow: visible;
}
.content .edit-group-dialog .edit-user-group .existing-members .simple-table td .form-error {
  color: #ff5454;
}
.content .edit-group-dialog .edit-user-group .existing-members .simple-table td .hover-msg {
  left: 15px;
  display: block;
  text-wrap: wrap;
  overflow-wrap: break-word;
  white-space: break-spaces;
  min-width: 50px;
  max-width: 180px;
  bottom: 25px;
}
.content .edit-group-dialog .edit-user-group .existing-members .simple-table td .trash.hover-msg {
  left: -30px;
}
.content .edit-group-dialog .edit-user-group .existing-members .simple-table td .data-container {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  padding-bottom: 1px;
}
.content .organization-container table {
  min-width: 40vw;
}
.content .organization-container table,
.content .organization-container th,
.content .organization-container td {
  border: 1px solid black;
  padding: 2px;
  transition: all 100ms;
}
.content .organization-container th {
  font-weight: bold;
}
.content .organization-container .org-link {
  cursor: pointer;
  padding: 2px;
  margin: 0 5px 5px 0;
}
.content .organization-container .org-link .org-link-name {
  height: 1rem;
}
.content .organization-container .org-link.active {
  text-decoration: underline;
}
.organization-chooser-dialog .form-group {
  text-align: left;
}
.organization-chooser-dialog.dialog .form-group .form-control {
  float: initial;
}
.admin-edit-account-dialog .permissions-title {
  margin-bottom: 5px;
}
.admin-edit-account-dialog .permission {
  border: 1px solid white;
  padding: 10px;
  height: initial;
  margin: 0 0 10px 0;
  transition: all 200ms;
}
.admin-edit-account-dialog .permission .icon {
  float: right;
}
.admin-edit-account-dialog .permission.add,
.admin-edit-account-dialog .permission.remove {
  cursor: pointer;
}
.admin-edit-account-dialog .permission.add:hover {
  border-color: #00c736;
  color: #00c736;
  padding: 10px 15px 10px 15px;
}
.admin-edit-account-dialog .permission.remove:hover {
  border-color: #AA0000;
  color: #AA0000;
  padding: 10px 15px 10px 15px;
}
.admin-edit-account-dialog h2 {
  margin-bottom: 20px;
}
.admin-edit-account-dialog .account-data-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}
.admin-edit-account-dialog .account-data-view img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.admin-edit-account-dialog .account-data-view .info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: end;
}
.admin-edit-account-dialog .name {
  font-weight: bold;
  height: 1em;
  cursor: pointer;
  transition: all 200ms;
}
.admin-edit-account-dialog .name .to-public {
  padding-left: 5px;
  font-size: calc(1pt + 0.5vw);
}
.admin-edit-account-dialog .name:hover {
  color: #f48120;
}
.admin-edit-account-dialog .dialog-icon {
  cursor: pointer;
}
.admin-edit-account-dialog .dialog-icon:hover {
  color: #AA0000;
}
.admin-edit-account-dialog .form-error {
  width: 250px;
  box-sizing: border-box;
}
.user-tags-dialog {
  max-width: 500px;
}
.user-tags-description {
  margin-bottom: 10px;
  max-width: 400px;
}
.tv-video-edit-dialog.dialog .tv-video-form {
  width: 250px;
}
.tv-video-edit-dialog.dialog .form-group .form-control {
  float: left;
}
.tv-video-edit-dialog.dialog .preview {
  overflow: auto;
}
.tv-video-preview {
  position: relative;
  overflow: hidden;
  float: left;
}
.tv-video-preview.tv-container {
  width: 206px;
  height: 171px;
  padding: 0 10px 10px 0;
}
.tv-video-preview .video-container {
  padding: 18px 15px 20px 5px;
}
.tv-video-preview img {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}
.tv-youtubeids-container .tv-video-preview.clickable {
  cursor: pointer;
}
.tv-youtubeids-container .tv-video-preview.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.tv-video-edit-dialog.dialog .dialog-icon {
  cursor: pointer;
}
.tv-video-edit-dialog.dialog .dialog-icon:hover {
  color: #a00000;
}
.tv-video-flex-column {
  display: flex;
  flex-direction: column;
}
.content .tutorials {
  padding: 40px;
  max-width: 100%;
}
.content .tutorials p {
  margin-bottom: 30px;
}
.content .tutorials p a {
  display: block;
  margin: 5px 0;
}
.content .tutorials .tutorial-container {
  display: flex;
  flex-wrap: wrap;
}
.content .tutorials h1,
.content .tutorials h2 {
  margin-bottom: 20px;
}
.content .tutorials .tutorial-container .tutorial {
  padding: 0 10px 20px 0;
  max-width: 256px;
}
.content .tutorials .tutorial-container .tutorial iframe {
  box-shadow: 0 0 10px black;
  margin-bottom: 10px;
}
.content .tutorials .tutorial-container .tutorial h3 {
  font-weight: bold;
}
.header-container {
  overflow: initial;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.header {
  background: #ffffff;
  /*rgb(0, 167, 157);*/
  height: 40px;
  overflow: initial;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}
.header .buttons {
  margin-left: 50px;
  display: flex;
  align-items: center;
  height: 40px;
}
.header .buttons .form-group {
  margin-bottom: 0;
}
.header .buttons .sort-container {
  display: none;
}
.header .profile {
  position: relative;
  cursor: pointer;
  color: black;
  display: flex;
}
.header .profile .name {
  display: none;
  height: 100%;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0 2px;
  line-height: 40px;
  max-width: 150px;
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.header .profile img {
  box-sizing: border-box;
  border-radius: 100%;
  height: 99%;
  padding: 2px 2px 2px 2px;
}
.header .profile .profile-menu {
  box-sizing: border-box;
  font-size: 2em;
  padding: 0 5px 0 2px;
  line-height: 40px;
  vertical-align: middle;
  overflow: hidden;
}
.header .profile .profile-nav {
  position: absolute;
  right: 0;
  background: #323232;
  top: 40px;
  width: 100vw;
  color: white;
}
.header .buttons .btn {
  height: 30px;
  margin-right: 10px;
}
.header .buttons .btn .svg-inline--fa {
  margin: 0;
}
.header a {
  color: white;
  transition: transform 0.5s;
}
.header a:active,
.header a:focus,
.header a:visited {
  text-decoration: none;
  outline: none;
}
.header .link-container a {
  display: inline-block;
  text-align: center;
  font-size: 1.4em;
  padding: 0 5px;
  transform: scale(0.8);
  line-height: 40px;
  vertical-align: middle;
}
.header a:hover {
  text-decoration: none;
  transform: scale(1);
}
.profile-nav .menu-point-container {
  font-size: 1.2rem;
  line-height: 4rem;
  height: 4rem;
  padding: 0 20px 0 37px;
  border-bottom: 1px solid white;
}
.profile-nav .menu-point-container .menu-point {
  display: inline-block;
  float: left;
  transition: all 200ms;
  transform-origin: left;
  transform: scale(0.8);
}
.profile-nav .menu-point-container:hover > .menu-point {
  transform: scale(1);
}
.dialog-account textarea,
.dialog-account input {
  box-sizing: border-box;
  width: 200px;
}
.dialog-account textarea {
  height: 120px;
}
.dialog-account .change-pass-btn {
  width: 240px;
  float: right;
}
.dialog-account .form-group-pass {
  margin: 10px 0;
}
.dialog-account .profile {
  text-align: center;
  margin-bottom: 40px;
}
.dialog-account .profile span {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.dialog-account .profile img {
  width: 100px;
  display: inline-block;
  border-radius: 100%;
}
.dialog-profile-image .crop-container {
  width: 200px;
}
@media screen and (min-width: 768px) {
  .header {
    height: 65px;
    padding-left: 240px;
  }
  .header .link-container a {
    padding: 0 20px;
    line-height: 65px;
  }
  .header .profile .name {
    display: initial;
    padding: 0 5px;
    line-height: 65px;
  }
  .header .profile img {
    padding: 10px 10px 10px 10px;
  }
  .header .profile .profile-menu {
    padding: 0 5px 0 5px;
    line-height: 65px;
  }
  .header .profile .profile-nav {
    width: initial;
    min-width: 300px;
    top: 65px;
  }
  .header .profile .profile-nav .menu-point-container {
    line-height: 3rem;
    height: 3rem;
    padding: 0 20px;
  }
  .header .buttons {
    margin-left: 20px;
    height: 65px;
  }
  .header .buttons .sort-container {
    display: flex;
  }
  .header .buttons .sort-container .form-control {
    color: black;
    border-color: black;
    max-width: 150px;
  }
  .header .buttons .sort-container .asc {
    cursor: pointer;
    font-size: 1.4em;
    padding: 5px 10px 5px 10px;
    border: 1px solid black;
    border-radius: 3px;
    margin-left: 0.5em;
  }
  .dialog-account textarea,
  .dialog-account input {
    width: 240px;
  }
  .dialog-profile-image .crop-container {
    width: 400px;
  }
}
@media screen and (min-width: 1800px) {
  .header .logo .logo-text {
    display: initial;
  }
}
.stb-round-selector {
  display: flex;
  gap: 1em;
  justify-content: space-between;
}
.spin-round-form-dialog form {
  width: 250px;
}
.spin-round-form-dialog .dialog-icon {
  transition: all 200ms;
  cursor: pointer;
}
.spin-round-form-dialog .dialog-icon:hover {
  color: #b40000;
  border-color: #b40000;
}
.spin-round-preview {
  position: relative;
  width: 325px;
  height: 201px;
}
.spin-round-preview .background {
  width: 100%;
  height: 100%;
}
.spin-round-preview .text-container {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 314px;
  height: 152px;
  line-height: 162px;
  vertical-align: middle;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.spin-round-preview .text-container.and-image {
  top: 33px;
  left: 155px;
  height: 129px;
  width: 148px;
  line-height: 129px;
}
.spin-round-preview .text-container .text {
  text-align: center;
  word-wrap: break-word;
  line-height: initial;
  display: inline-block;
  width: 324px;
  white-space: pre-wrap;
}
.spin-round-preview .text-container .text.and-image {
  width: 148px;
}
.item-container .spin-round-preview {
  margin: 0 5px 5px 0;
  transition: all 100ms;
}
.item-container .spin-round-preview.clickable:hover {
  cursor: pointer;
  transform: scale(1.05, 1.05);
}
.spin-round-preview .image {
  position: absolute;
  top: 9px;
  left: 85px;
  width: 156px;
  height: 156px;
}
.spin-round-preview .image.and-text {
  top: 33px;
  left: 17px;
  width: 129px;
  height: 129px;
}
.spin-round-preview .audio {
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 3px;
  right: 3px;
}
.spin-round-preview .audio img {
  width: 100%;
}
.smackthefly-round-dialog {
  overflow: auto;
}
.smackthefly-round-dialog .float {
  float: left;
}
.smackthefly-preview-wrapper {
  box-sizing: border-box;
  width: 380px;
  height: 237px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.smackthefly-preview-wrapper .smackthefly-round-preview {
  width: 640px;
  height: 400px;
  transform: scale(0.5925);
  transform-origin: top left;
  overflow: hidden;
  user-select: none;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .announcer {
  position: absolute;
  width: 20%;
  bottom: 20px;
  right: 20px;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .bubble {
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 120px;
  right: 150px;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .bubble .bubble-image {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .bubble .hint-text {
  position: relative;
  z-index: 3;
  padding: 20px;
  line-height: initial;
  box-sizing: border-box;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .bubble .hint-image {
  position: relative;
  z-index: 3;
  padding: 0 20px 20px;
  width: 100%;
  box-sizing: border-box;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .bubble .hint-sound {
  position: absolute;
  color: black;
  z-index: 3;
  padding: 30px 15px;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .round-time {
  position: absolute;
  left: 8px;
  bottom: 10px;
  font-size: 25px;
  width: 50px;
  line-height: 45px;
  text-align: center;
  color: white;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .position-preview {
  position: absolute;
  z-index: 3;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .position-preview:active {
  cursor: pointer;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .position-preview .smackthefly-answer-preview-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .position-preview .smackthefly-answer-preview {
  width: 100%;
  height: 100%;
}
.smackthefly-preview-wrapper .smackthefly-round-preview .position-preview .smackthefly-answer-preview .myfloor-text {
  line-height: inherit;
  white-space: nowrap;
}
.smackthefly-preview-wrapper .smackthefly-round-question-preview {
  position: absolute;
  width: 94px;
  height: 170px;
  right: 26px;
  bottom: 16px;
}
.smackthefly-preview-wrapper .smackthefly-round-question-preview .round-question {
  position: absolute;
  top: 80px;
  left: -20%;
  background: #b4b4b4;
  height: 30px;
  overflow: hidden;
  width: 140%;
  text-align: center;
}
.dialog.smackthefly-round-dialog .smackthefly-round-form {
  width: 250px;
  margin: 0 40px 0 0;
}
.dialog.smackthefly-round-dialog .smackthefly-round-form .form-group {
  text-align: left;
}
.smackthefly-answer-container .answer {
  box-sizing: border-box;
  float: left;
  border: 2px solid black;
  width: 100px;
  height: 100px;
  background: white;
  color: black;
  cursor: pointer;
}
.smackthefly-answer-container .answer.new-answer {
  padding: 0;
  background: transparent;
  border: 0;
}
.smackthefly-answer-container .answer.new-answer img {
  width: 50%;
}
.smackthefly-answer-preview-wrapper {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
.smackthefly-answer-preview-wrapper .smackthefly-answer-preview {
  box-sizing: border-box;
  position: relative;
  width: 200px;
  height: 200px;
  border: 2px solid #fb4545;
  overflow: hidden;
}
.smackthefly-answer-preview-wrapper .smackthefly-answer-preview.correct {
  border-color: #23e06e;
}
.smackthefly-answer-preview-wrapper .marked-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 50px);
  min-width: 25px;
}
.smackthefly-answer-preview-wrapper .marked-wrapper img.marked {
  width: 100%;
  margin-top: -50%;
  margin-left: -50%;
}
.smackthefly-answer-container .smackthefly-answer-preview-wrapper {
  width: 100px;
  height: 100px;
}
.smackthefly-answer-container .smackthefly-answer-preview-wrapper .smackthefly-answer-preview {
  transform: scale(0.5);
  transform-origin: top left;
}
.smackthefly-answer-preview-wrapper .smackthefly-answer-preview .text {
  text-align: center;
  font-size: 24px;
}
.smackthefly-answer-preview-wrapper .smackthefly-answer-preview .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.smackthefly-rounds-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.round-with-answers {
  max-width: 380px;
  max-height: 337px;
  overflow: hidden;
}
.round-with-answers .answers {
  position: relative;
  width: 460px;
  max-height: 50px;
  height: 50px;
  margin-top: 10px;
  overflow: hidden;
}
.smackthefly-round-sortable .answers .infinite {
  width: 999999px;
}
.smackthefly-round .answers .answers-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #ffffff 85%);
}
.dialog .smackthefly-round-sortable .answers .answers-cover {
  background: none;
}
.round-with-answers .answers .smackthefly-answer-preview-wrapper {
  width: 50px;
  height: 50px;
}
.smackthefly-round-sortable .answers .smackthefly-answer-preview {
  transform-origin: top left;
  transform: scale(0.25);
}
.smackthefly-round-dialog .dialog-bottom .dialog-icon,
.smackthefly-answer-dialog .dialog-bottom .dialog-icon {
  cursor: pointer;
  transition: all 200ms;
}
.smackthefly-round-dialog .dialog-bottom .dialog-icon:hover,
.smackthefly-answer-dialog .dialog-bottom .dialog-icon:hover {
  color: #b40000;
  border-color: #b40000;
}
.smackthefly-answer-dialog .left {
  float: left;
  margin-right: 20px;
}
.smackthefly-answer-dialog .left form {
  margin-top: 0;
}
.smackthefly-answer-dialog .right {
  float: left;
  width: 380px;
}
.smackthefly-answer-dialog .right p {
  margin-bottom: 5px;
}
.smackthefly-answer-dialog .right .smackthefly-preview-wrapper {
  margin-bottom: 10px;
}
.smackthefly-answer-dialog .smackthefly-answer-form {
  width: 250px;
}
.smackthefly-answer-dialog .smackthefly-answer-form p {
  margin-bottom: 10px;
}
@media (max-width: 660px) {
  .round-with-answers .smackthefly-preview-wrapper {
    width: 280px;
    height: 175px;
  }
  .round-with-answers .smackthefly-preview-wrapper .smackthefly-round-preview {
    transform: scale(0.4355);
  }
  .smackthefly-preview-wrapper {
    width: 300px;
    height: 188px;
  }
  .smackthefly-preview-wrapper .smackthefly-round-preview {
    transform: scale(0.46785);
  }
  .round-with-answers .answers {
    width: 280px;
  }
}
.quizninja-preview-wrapper {
  width: 380px;
  height: 237px;
  overflow: hidden;
}
.quizninja-preview-wrapper .quizninja-round-preview {
  width: 640px;
  height: 400px;
  transform: scale(0.5925);
  transform-origin: top left;
}
.quizninja-preview-wrapper .quizninja-round-preview .quizninja-round-outer {
  position: relative;
  width: 100%;
  height: 100%;
}
.quizninja-preview-wrapper .quizninja-round-preview .quizninja-round-outer .background {
  width: 100%;
  height: 100%;
}
.quizninja-preview-wrapper .quizninja-round-preview .quizninja-round-inner {
  position: absolute;
  top: 0;
  left: 0;
  margin: 50px 100px 70px 100px;
  width: 440px;
  height: 280px;
}
.quizninja-preview-wrapper .quizninja-round-preview .quizninja-round-inner .ninja {
  float: left;
  box-sizing: border-box;
  height: 100%;
  padding: 32px;
}
.quizninja-preview-wrapper .quizninja-round-preview .quizninja-round-inner .round-text {
  float: left;
  box-sizing: border-box;
  height: 100%;
  width: 50%;
  font-family: "Chewy", serif;
  color: white;
}
.quizninja-preview-wrapper .quizninja-round-preview .quizninja-round-inner .round-text .round-title {
  margin-top: 60px;
  line-height: initial;
}
.quizninja-preview-wrapper .quizninja-round-preview .quizninja-round-inner .round-text .round-description {
  margin-top: 10px;
  line-height: initial;
}
.quizninja-preview-wrapper .quizninja-round-preview .clock {
  position: absolute;
  width: 100px;
  height: 125px;
  bottom: 5px;
  left: 5px;
}
.quizninja-preview-wrapper .quizninja-round-preview .clock img {
  width: 100%;
  height: 100%;
}
.quizninja-preview-wrapper .quizninja-round-preview .clock .time-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  line-height: 97px;
  margin-top: 28px;
  text-align: center;
  font-size: 34px;
  font-family: "Chewy", serif;
  color: white;
  text-shadow: black 1px 0 10px;
}
.quizninja-preview-wrapper .quizninja-round-question-preview {
  position: absolute;
  width: 94px;
  height: 170px;
  right: 26px;
  bottom: 16px;
}
.quizninja-preview-wrapper .quizninja-round-question-preview .ninja {
  width: 100%;
  height: 100%;
}
.quizninja-preview-wrapper .quizninja-round-question-preview .round-question {
  position: absolute;
  top: 80px;
  left: -20%;
  background: #b4b4b4;
  height: 30px;
  overflow: hidden;
  width: 140%;
  text-align: center;
}
.quizninja-preview-wrapper .quizninja-round-question-preview .round-question .question-text {
  line-height: 30px;
}
.quizninja-preview-wrapper .quizninja-round-question-preview .question-image {
  position: absolute;
  width: 140%;
  left: -20%;
  top: -11px;
}
.dialog.quizninja-round-dialog {
  box-sizing: border-box;
  max-width: 743px;
  background: red;
}
.dialog.quizninja-round-dialog .quizninja-round-form {
  width: 250px;
}
.dialog.quizninja-round-dialog .quizninja-round-form .form-group {
  text-align: left;
}
.quizninja-answer-container .answer {
  box-sizing: border-box;
  float: left;
  border: 2px solid black;
  width: 100px;
  height: 100px;
  background: white;
  color: black;
  cursor: pointer;
}
.quizninja-answer-container .answer.new {
  padding: 0;
  background: transparent;
  border: 0;
}
.quizninja-answer-container .answer.new img {
  width: 50%;
}
.quizninja-answer-container .answer:disabled {
  background: red;
}
.quizninja-answer-preview-wrapper {
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
.quizninja-answer-preview-wrapper .element-background {
  width: 640px;
  height: 400px;
  position: absolute;
}
.quizninja-answer-preview-wrapper .quizninja-answer-preview {
  box-sizing: border-box;
  position: relative;
  width: 100px;
  height: 100px;
  border: 2px solid #fb4545;
}
.quizninja-answer-preview-wrapper .quizninja-answer-preview .text {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 100px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.quizninja-answer-preview-wrapper .quizninja-answer-preview .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.quizninja-round-sortable {
  float: left;
  margin: 0 10px 10px 0;
  max-width: 380px;
  max-height: 337px;
  overflow: hidden;
}
.quizninja-round-sortable .answers {
  position: relative;
  width: 460px;
  max-height: 50px;
  height: 50px;
  margin-top: 10px;
  overflow: hidden;
}
.quizninja-round-sortable .answers .infinite {
  width: 999999px;
  display: flex;
}
.quizninja-round-sortable .answers .answers-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #ffffff 85%);
}
.quizninja-round-sortable .answers .quizninja-answer-preview-wrapper {
  width: 50px;
  height: 50px;
}
.quizninja-round-sortable .answers .quizninja-answer-preview {
  transform-origin: top left;
  transform: scale(0.5);
}
.quizninja-answer-dialog .quizninja-answer-preview-wrapper {
  width: 100px;
  height: 100px;
}
.quizninja-round-dialog .dialog-bottom .dialog-icon,
.quizninja-answer-dialog .dialog-bottom .dialog-icon {
  cursor: pointer;
  transition: all 200ms;
}
.quizninja-round-dialog .dialog-bottom .dialog-icon:hover,
.quizninja-answer-dialog .dialog-bottom .dialog-icon:hover {
  color: #b40000;
  border-color: #b40000;
}
.quizninja-answer-dialog .text-note {
  font-size: 0.7em;
  max-width: 250px;
  margin-bottom: 20px;
}
@media (max-width: 660px) {
  .quizninja-round-sortable .quizninja-preview-wrapper {
    width: 280px;
    height: 175px;
  }
  .quizninja-round-sortable .quizninja-preview-wrapper .quizninja-round-preview {
    transform: scale(0.4355);
  }
  .quizninja-preview-wrapper {
    width: 300px;
    height: 188px;
  }
  .quizninja-preview-wrapper .quizninja-round-preview {
    transform: scale(0.46785);
  }
  .quizninja-round-sortable .answers {
    width: 280px;
  }
}
.dialog.ditj-dialog {
  overflow: initial;
}
.ditj-dialog .ditj-question .form-group select.form-control.font-family {
  float: initial;
}
.ditj-dialog .ditj-form .ditj-answers {
  overflow: visible;
}
.public-profile {
  min-height: 5vh;
}
.public-profile .item-container {
  margin: 20px;
}
.public-profile .pp-header {
  position: relative;
  background: linear-gradient(22.5deg, #000038, #00a79d);
  height: 20vh;
  overflow: hidden;
}
.public-profile .pp-header .account-img {
  box-sizing: border-box;
  float: left;
  padding: 2.5vh;
}
.public-profile .pp-header .account-img .author-preview {
  max-height: 15vh;
  max-width: 100%;
}
.public-profile .pp-header .account-text {
  box-sizing: border-box;
  float: left;
  padding: 2.5vh;
  max-width: 70%;
  height: 100%;
}
.public-profile .social-list h1 {
  margin-bottom: 5px;
}
.public-profile .social-list h3 {
  font-size: 0.7em;
  font-weight: bold;
}
.public-profile .pp-header {
  color: white;
}
.public-profile .pp-header .account-title {
  padding-bottom: 1.25vh;
  white-space: nowrap;
}
.public-profile .pp-header .account-title .pp-name {
  font-weight: bold;
  font-size: 1.5em;
}
.public-profile .pp-header .account-title .org-name {
  font-style: italic;
}
.public-profile .pp-header .pp-description {
  box-sizing: border-box;
}
.public-profile .pp-menu {
  margin: 20px 0 10px 30px;
}
.public-profile .pp-menu .btn {
  margin: 0 10px 10px 0;
}
.public-profile .pp-menu .btn .count-text {
  display: inline-block;
  margin-left: 5px;
  font-style: italic;
  font-size: 0.6em;
}
.public-profile .pp-menu .btn.active {
  background: #882144;
}
.public-profile .game-list,
.public-profile .playlist-list {
  padding-left: 30px;
}
.public-profile .following-list {
  margin-bottom: 20px;
}
.public-profile .pp-dashboard h2 {
  padding-left: 40px;
  margin-bottom: 0;
}
.public-profile .pp-dashboard .component-slider-container {
  margin-bottom: 60px;
}
.public-profile .follow-btn-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  color: white;
}
.public-profile .follow-btn-container .btn {
  padding: 8px 30px;
  background: none;
  color: white;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.public-profile .follow-btn-container .btn:hover {
  background: white;
  color: black;
}
.public-profile .follow-btn-container .icon {
  padding-right: 10px;
}
.public-profile .social-list {
  padding-left: 30px;
}
.post {
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 10px;
  overflow: auto;
  background: linear-gradient(45deg, rgba(240, 240, 240, 0.5), rgba(230, 230, 230, 0.5));
  border-radius: 7px;
}
.post .profile {
  float: left;
  box-sizing: border-box;
  padding: 10px;
  max-width: 33%;
  border-right: 1px solid black;
}
.post .profile img {
  width: 100%;
}
.post .post-content {
  float: left;
  box-sizing: border-box;
  max-width: 67%;
  padding: 10px;
}
.post .post-time {
  width: 100%;
}
.post .post-content .post-content-text {
  padding-bottom: 10px;
  width: 100%;
}
.post .post-content .post-content-image {
  text-align: left;
  width: 100%;
}
.post .post-content .post-content-image img {
  border: 1px solid lightgreen;
}
.playlists {
  overflow: auto;
  min-height: 200px;
  padding: 15px;
}
.playlists .container {
  background: rgba(200, 200, 200, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
.playlists .container h2,
.playlists .container h1 {
  display: inline-block;
}
.playlists .container h2:after,
.playlists .container h1:after {
  content: "";
  display: block;
  width: 33%;
  margin: 10px 0 20px 0;
  box-sizing: content-box;
  border-bottom: 1px solid rgba(50, 50, 50);
}
.playlists .confirm {
  max-width: 550px;
}
.playlist-overview .item-container .item-container-heading,
.playlist-explorer .item-container .item-container-heading {
  display: block;
}
.playlist-overview .item-container .item-container-heading a,
.playlist-explorer .item-container .item-container-heading a {
  margin-bottom: 15px;
}
.playlist-overview .item-container .item-container-heading h1:after,
.playlist-explorer .item-container .item-container-heading h1:after {
  content: "";
  display: block;
  width: 33%;
  margin: 10px 0 15px 0;
  box-sizing: content-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.playlist-overview .item-container .item-container-heading:after,
.playlist-explorer .item-container .item-container-heading:after {
  content: none;
  display: none;
}
.playlist-overview .item-container .item-container-sub-heading h3,
.playlist-explorer .item-container .item-container-sub-heading h3 {
  max-width: 650px;
}
.playlist-overview .item-container .item-container-sub-heading .playlist-path-map,
.playlist-explorer .item-container .item-container-sub-heading .playlist-path-map {
  border-radius: 20px;
}
.playlist-overview .item-container .item-container-sub-heading form .radio-group,
.playlist-explorer .item-container .item-container-sub-heading form .radio-group {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.playlist-overview .item-container .item-container-sub-heading form .radio-group .form-group,
.playlist-explorer .item-container .item-container-sub-heading form .radio-group .form-group {
  width: 150px;
}
.playlist-overview .item-container .item-container-sub-heading form .radio-group .form-group .checkbox-container,
.playlist-explorer .item-container .item-container-sub-heading form .radio-group .form-group .checkbox-container {
  background-color: #F2F2F2;
  border-radius: 1em;
}
.playlist-overview .item-container .item-container-sub-heading form .radio-group .form-group .checkbox-container .checkbox-label,
.playlist-explorer .item-container .item-container-sub-heading form .radio-group .form-group .checkbox-container .checkbox-label {
  text-align: left;
}
.playlist-overview .item-container .item-container-sub-heading form .radio-group .form-group .checkbox-container.active,
.playlist-explorer .item-container .item-container-sub-heading form .radio-group .form-group .checkbox-container.active {
  background-color: rgba(255, 0, 128, 0.4);
}
@keyframes pulse-animation {
  0% {
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }
  100% {
    filter: drop-shadow(0 0 0 rgba(255, 255, 255, 0.5));
  }
}
.override-panel {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.override-panel .override-local-panel,
.override-panel .override-reseller-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px;
  background: linear-gradient(to right, rgba(229, 255, 0, 0.1), transparent);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}
.override-panel .override-local-panel .btn,
.override-panel .override-reseller-panel .btn {
  margin: 0;
  padding: 2px 6px;
}
.override-panel .override-local-panel .form-group,
.override-panel .override-reseller-panel .form-group {
  margin: 0;
  padding: 0;
}
.override-panel .override-local-panel .form-group select.form-control,
.override-panel .override-reseller-panel .form-group select.form-control {
  height: 20px;
  padding: 0;
  width: initial;
}
.override-reseller-panel {
  background: linear-gradient(to right, rgba(255, 153, 0, 0.1), transparent);
}
.override-reseller-panel .form-group select.form-control {
  background: white;
}
.override-diff {
  max-width: 80vw;
}
.override-diff .item-container-table {
  margin-bottom: 20px;
}
.override-diff .item-container-table .active {
  background: #f48120;
}
.override-diff .compare {
  align-items: center;
}
.override-diff .compare section {
  margin-bottom: 20px;
}
.override-diff .compare .arrow {
  font-size: 50px;
}
.override-diff .compare .description {
  margin: 5px 0;
  max-width: 220px;
}
.override-diff .override-item {
  position: relative;
  padding: 7px;
  background: linear-gradient(320deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.1));
  border-radius: 3px;
}
.override-diff .override-item .description {
  margin-bottom: 3px;
}
.override-diff .override-item.added {
  background: linear-gradient(320deg, rgba(0, 200, 0, 0.3), rgba(0, 200, 0, 0.5));
  animation: alternate infinite 1s ease-in-out pulse-animation;
}
.override-diff .override-item.removed {
  background: linear-gradient(320deg, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.5));
  animation: alternate infinite 1s ease-in-out pulse-animation;
}
.override-diff .override-item.removed .icon-wrapper {
  filter: saturate(0.2);
}
.override-diff .override-item .action-container {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
}
@-webkit-keyframes coloranimation {
  0% {
    box-shadow: 0 0 40px #f48120;
  }
  33.33% {
    box-shadow: 0 0 40px #652d90;
  }
  66.66% {
    box-shadow: 0 0 40px #00adee;
  }
  100.0% {
    box-shadow: 0 0 40px #f48120;
  }
}
@keyframes coloranimation {
  0% {
    box-shadow: 0 0 40px #f48120;
  }
  33.33% {
    box-shadow: 0 0 40px #652d90;
  }
  66.66% {
    box-shadow: 0 0 40px #00adee;
  }
  100.0% {
    box-shadow: 0 0 40px #f48120;
  }
}
.confirm .view-mode-change {
  text-align: initial;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.playlist-explorer {
  position: relative;
}
.playlist-explorer .override-warning {
  cursor: pointer;
  position: absolute;
  display: flex;
  gap: 5px;
  right: 0;
  bottom: 0;
  margin: 2px;
  color: #ffd900;
  font-weight: bold;
  background-color: #505050;
  border-radius: 3px;
  padding: 4px;
  font-size: 0.8em;
}
.playlist-explorer .override-warning .hover-msg {
  right: 10px;
  bottom: 15px;
  left: unset;
  width: 250px;
}
.playlist-explorer .item-container .icon {
  transition: transform 200ms, scale 200ms, outline 0ms;
}
.playlist-explorer .item-container .dropable-spacer {
  background: transparent;
  height: 200px;
  padding: 0 40px;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
}
.playlist-explorer .item-container .hover .dropable-spacer {
  background: goldenrod;
}
.playlist-explorer .item-container .list .no-gap {
  position: absolute;
}
.playlist-explorer .item-container .item-icon.grey {
  filter: grayscale(1);
}
.playlist-explorer .item-container .item-icon.high .icon {
  outline: 2px solid #f48120;
  box-shadow: 0 0 40px orange;
  animation-name: coloranimation;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.playlist-explorer .item-container .item-container-heading {
  color: inherit;
}
.playlist-explorer .item-container .item-container-heading a {
  color: inherit;
}
.playlist-explorer .item-container .item-container-heading a:hover {
  text-decoration: underline;
}
.playlist-explorer .bread-crumb {
  background: rgba(0, 0, 0, 0.07);
  padding: 10px;
}
.playlist-explorer .bread-crumb .root {
  background: orangered;
}
.playlist-explorer .bread-crumb .node {
  cursor: pointer;
}
.playlist-explorer .bread-crumb .sortable {
  transition: 50ms;
  outline: 2px dashed transparent;
}
.playlist-explorer .bread-crumb .sortable:hover {
  text-decoration: underline;
}
.playlist-explorer .bread-crumb .sortable.dropable {
  outline: 2px dashed black;
}
.playlist-explorer .bread-crumb .sortable.hover {
  outline: 2px solid green;
}
.playlist-explorer .bread-crumb .sortable.hover .icon,
.playlist-explorer .bread-crumb .sortable.hover .root {
  outline: 2px solid green;
  box-sizing: border-box;
}
.playlist-explorer .bread-crumb .flex {
  padding-bottom: 0;
  margin-bottom: 0;
}
.playlist-explorer .bread-crumb .flex:before {
  content: none;
}
.playlist-explorer .bread-crumb .flex .seperator {
  line-height: initial;
}
.dialog.playlist-add-playlist {
  width: 85vw;
}
.dialog.playlist-add-playlist .playlist-info {
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.dialog.playlist-add-playlist .playlist-info .author-name {
  color: white;
}
.dialog.playlist-add-playlist .playlist-preview .pl-description:after {
  display: none;
}
.playlist-container {
  overflow: auto;
  padding-top: 10px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.sortable .playlist-preview .icon-menu.hidden {
  display: none;
}
.playlist-settings-dialog.dialog .flex {
  gap: 20px;
}
.playlist-settings-dialog.dialog .playlist-preview .pl-description:after {
  display: none;
}
.content .playlist-content {
  min-height: 400px;
  padding: 15px;
}
.content .playlist-content p {
  margin-bottom: 20px;
}
.content .playlist-content .game-icon .game-icon-icon {
  color: #c8c8c8;
}
.content .playlist-content .content-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
}
.content .playlist-content .content-list .sortable {
  margin: 0 10px 10px 0;
}
.content .playlist-content .content-list .sortable .game {
  float: initial;
}
.related-info-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.related-info-buttons .btn {
  text-align: start;
}
.dialog-overlay .dialog.playlist-overview {
  min-width: 50vw;
}
.dialog-overlay .dialog.playlist-overview .pl-description::after {
  display: none;
}
.floor-overview-table {
  margin-top: 20px;
}
.floor-overview-table thead {
  font-weight: bold;
}
.floor-overview-table tr {
  border-bottom: 1px dotted white;
}
.floor-overview-table tr td {
  padding: 2px 4px;
}
.floor-overview-table tr.sum {
  border-top: 1px solid white;
  border-bottom: none;
  font-weight: bold;
}
@keyframes animate-appear {
  0% {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 0.7;
  }
}
.playlists .detailed-preview {
  margin-bottom: 20px;
}
.playlists .item-container {
  overflow: hidden;
}
.playlists .item-container .sort-preview {
  opacity: 0.7;
}
.playlists .item-container .sortable {
  position: relative;
}
.playlists .item-container .sortable .add-icon {
  transition: all 500ms;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: rgba(231, 216, 40, 0.9);
  pointer-events: none;
}
.playlists .item-container .sortable .add-icon.restrict {
  color: #AA0000;
}
.playlists .item-container .sortable.dropable .icon {
  outline: 6px dashed black;
}
.playlists .item-container .sortable.dropable .icon .icon {
  outline: none;
}
.playlists .item-container .sortable.hover .icon-wrapper {
  box-shadow: 0 0 20px green;
}
.playlists .item-container .sortable.hover .icon {
  outline: 6px solid green;
  box-sizing: border-box;
}
.playlists .item-container .sortable.hover .icon .icon-wrapper {
  box-shadow: none;
}
.playlists .item-container .sortable.hover .icon .icon {
  outline: none;
}
.playlists .item-container .sortable.hover .add-icon {
  opacity: 1;
}
.playlists .item-container .sortable.dragged {
  transform-origin: top left;
  transform: scale(0.7);
}
.playlists .item-container .sortable.ghost {
  transform-origin: 50% 25%;
  animation-duration: 500ms;
  animation-name: animate-appear;
  animation-delay: 0;
  animation-fill-mode: backwards;
  transition: all 500ms;
}
.playlists .item-container .sortable.ghost.invis {
  opacity: 0;
  scale: 0;
}
.playlists .item-container .sortable.ghost.hover .icon {
  outline: none;
}
.playlists .item-container .sortable.ghost.hover .add-icon {
  opacity: 0;
}
.playlist-floor-preview {
  width: 400px;
  height: 250px;
  background: #323232;
  position: relative;
  overflow: hidden;
}
.playlist-floor-preview .name {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #8b8f92;
}
.playlist-floor-preview .arrow {
  position: absolute;
  bottom: 45px;
  width: 20px;
}
.playlist-floor-preview .arrow.left {
  left: 0;
}
.playlist-floor-preview .arrow.right {
  right: 0;
}
.playlist-floor-preview .pull-up-menu-container {
  width: 100%;
  position: absolute;
  bottom: -65px;
  text-align: center;
}
.playlist-floor-preview .pull-up-menu-container .pull-up-menu {
  width: 80px;
}
.playlist-floor-preview.default .items {
  position: absolute;
  bottom: 40px;
  left: 20px;
  display: flex;
  gap: 2px;
}
.playlist-floor-preview.grid .name {
  color: white;
  z-index: 2;
  top: 30px;
  bottom: initial;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
}
.playlist-floor-preview.grid .row {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.playlist-floor-preview.grid .row.no-0 {
  top: 30px;
}
.playlist-floor-preview.grid .row.no-1 {
  top: 107px;
}
.playlist-floor-preview.grid .row.no-2 {
  top: 182px;
}
.playlist-floor-preview.grid .row .rect {
  background: #505050;
  height: 48px;
  width: 70px;
}
.playlist-floor-preview.grid .exit-img {
  position: absolute;
  z-index: 2;
  top: 9px;
  right: 9px;
  width: 16px;
}
.playlist-floor-preview.grid .arrow {
  position: relative;
  bottom: initial;
}
.playlist-floor-preview.grid .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.playlist-floor-preview.grid .overlay .items {
  z-index: 2;
  box-sizing: border-box;
  height: 164px;
  width: 231px;
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  gap: 6px;
  overflow: hidden;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  margin-top: 10px;
  align-content: flex-start;
}
.bread-crumb {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
}
.bread-crumb .root {
  height: 75px;
  width: 110px;
  position: relative;
}
.bread-crumb .root .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  z-index: 2;
  padding: 0.2em;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #000, transparent);
  font-size: 0.8em;
  text-shadow: 1px 1px #000;
  text-align: center;
}
.bread-crumb .root img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.bread-crumb .marked {
  border: 2px solid orange;
  border-radius: 2px;
  padding: 3px;
  box-sizing: border-box;
}
.playlist-path-map .flex {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
}
.playlist-path-map .flex:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 150px;
  border-bottom: 1px solid white;
}
.playlist-path-map .flex .seperator {
  line-height: 75px;
}
.playlist-path-map .flex .root {
  height: 75px;
  width: 110px;
  position: relative;
}
.playlist-path-map .flex .root .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  z-index: 2;
  padding: 0.2em;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #000, transparent);
  font-size: 0.8em;
  text-shadow: 1px 1px #000;
  text-align: center;
}
.playlist-path-map .flex .root img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.playlist-path-map .flex .marked {
  border: 2px solid orange;
  border-radius: 2px;
  padding: 3px;
  box-sizing: border-box;
}
.playlist-edit .title-section {
  margin: 10px 0;
  padding: 10px 0 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.playlist-edit .sub-section {
  margin-left: 10px;
}
.playlist-edit .item-icon {
  margin-bottom: 20px;
}
.playlist-edit .item-icon:hover .image {
  box-shadow: 5px 5px 5px rgba(0, 50, 0, 0.5);
}
.playlist-edit .item-icon .image {
  width: 220px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}
.playlist-edit .item-icon .edit-icon {
  position: absolute;
  font-size: 2rem;
  top: -0.5rem;
  right: -0.5rem;
  stroke: black;
  stroke-width: 5;
}
.playlist-edit-dialog .form-left-part,
.playlist-edit-dialog .form-right-part {
  max-width: 250px;
}
.playlist-edit-dialog .form-left-part {
  padding-bottom: 40px;
}
.playlist-edit-dialog .close-button,
.playlist-edit-dialog .dialog-icon {
  cursor: pointer;
}
.playlist-edit-dialog .close-button:hover,
.playlist-edit-dialog .dialog-icon:hover {
  color: #b40000;
}
.playlist-edit-dialog .playlist-games {
  overflow: auto;
}
.playlist-edit-dialog .playlist-games .game {
  position: relative;
  float: left;
  width: 100px;
  min-height: 50px;
  margin: 0 10px 10px 0;
}
.playlist-edit-dialog .playlist-games .game * {
  width: 100%;
}
.playlist-edit-dialog .playlist-games .game .game-image {
  box-shadow: 5px 5px 5px black;
}
.playlist-edit-dialog .playlist-games .game .game-title {
  max-height: 1rem;
  margin: 0.6rem 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dialog.playlist-edit-dialog .icon-container .form-group {
  float: left;
}
.dialog.playlist-edit-dialog .icon-container .form-group.icon-btn .btn {
  width: 190px;
}
.dialog.playlist-edit-dialog .icon-container .form-group.color-group {
  padding-top: 7px;
}
@media screen and (min-width: 768px) {
  .playlist-edit-dialog .form-left-part {
    float: left;
    padding-bottom: initial;
  }
  .playlist-edit-dialog .form-right-part {
    float: left;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    display: block;
  }
}
.preview-wrapper .picturebook-slide-preview {
  position: relative;
  width: 640px;
  height: 400px;
}
.preview-wrapper .picturebook-slide-preview img {
  position: absolute;
}
.preview-wrapper .picturebook-slide-preview .backward-arrow-image {
  width: 58px;
  left: 5px;
  bottom: 5px;
}
.preview-wrapper .picturebook-slide-preview .forward-arrow-image {
  width: 58px;
  right: 5px;
  bottom: 5px;
}
.preview-wrapper .picturebook-slide-preview .audio img {
  position: absolute;
  width: 58px;
  bottom: 5px;
  right: 69px;
}
.preview-wrapper .picturebook-slide-preview .image {
  width: 100%;
}
.picturebook .item-container .list .preview-wrapper {
  width: 280px;
  height: 175px;
}
.picturebook .item-container .list .picturebook-slide-preview {
  transform-origin: top left;
  transform: scale(0.4375);
}
.picturebook .item-container .list .picturebook-slide-preview.clickable {
  cursor: pointer;
}
.picturebook .item-container .list .picturebook-slide-preview.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.picturebook .item-container .list .sortable {
  width: 280px;
  height: 175px;
}
.picturebook-slide-edit-dialog .form-group {
  text-align: left;
}
.picturebook-slide-edit-dialog .dialog-icon {
  cursor: pointer;
}
.picturebook-slide-edit-dialog .dialog-icon:hover {
  color: #a00000;
}
/* RTL FIX */
.item-container[dir="rtl"] .list .picturebook-slide-preview {
  transform-origin: top right;
}
.org-cat {
  background-color: #f5f5f5;
}
.org-cat.SalesPartner {
  background-color: #f48120;
}
.chart-wrapper {
  position: relative;
  width: 100%;
  min-width: 400px;
  height: 420px;
}
.chart-wrapper .chart {
  width: 100%;
  height: 100%;
}
.chart-wrapper .chart.hide {
  display: none;
}
.chart-wrapper .btns {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.charts-dashboard {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 10px;
}
.charts-dashboard .charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 10px;
}
.charts-dashboard .charts .chart-wrapper {
  width: 45%;
  min-width: 400px;
}
.charts-dashboard .charts .chart-wrapper .chart {
  width: 100%;
  height: 100%;
}
.charts-dashboard .charts .chart-wrapper .chart-table {
  position: absolute;
}
.charts-dashboard .charts .chart-wrapper .btns {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.charts-dashboard .charts .chart-with-input {
  position: relative;
  width: 45%;
  min-width: 400px;
  height: 420px;
}
.charts-dashboard .charts .chart-with-input .form-group {
  position: absolute;
  z-index: 2;
}
.charts-dashboard .charts .chart-with-input .chart-wrapper {
  width: 100%;
}
.charts-dashboard .charts .chart-with-input .chart-table {
  top: 50px;
}
.charts-dashboard form {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background: rgba(200, 200, 200, 0.1);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  padding: 5px;
}
.charts-dashboard form .form-group .checkbox-container,
.charts-dashboard form .form-group .form-control {
  background: #ffffff;
  border: 1px solid black;
}
.charts-dashboard form .game-types-quick-selectors {
  display: flex;
  gap: 10px;
}
.bulk-price table th {
  border-bottom: 1px solid black;
}
.bulk-price table td {
  padding: 5px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.content .organization {
  padding: 10px;
  min-height: 200px;
}
.content .organization .support-org-table-heading {
  font-weight: bold;
}
.content .organization .top {
  margin-bottom: 20px;
}
.content .organization .top .tag-list {
  margin-bottom: 15px;
}
.content .organization h1 {
  margin-bottom: 0;
}
.content .organization .system-name {
  font-size: 1em;
  font-weight: bold;
  margin: 2px 0 5px 0;
}
.content .organization .charts-dashboard {
  margin-bottom: 20px;
}
.content .organization .tag-list {
  display: flex;
  gap: 5px;
}
.content .organization .reseller {
  margin-bottom: 20px;
}
.content .organization .contacts {
  padding: 10px 0;
  margin-bottom: 20px;
}
.content .organization .contacts .main {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.content .organization .contact {
  background: linear-gradient(45deg, #fffff5, #f5f5ff);
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.content .organization .contact h2 {
  margin-bottom: 4px;
}
.content .organization .contact h2::after {
  display: block;
  content: "";
  border-bottom: 1px solid black;
  width: 66%;
  margin: 2px 0;
}
.content .organization .contact .subheading {
  font-size: 0.9em;
  font-weight: bold;
  font-style: italic;
  max-width: 400px;
  margin-bottom: 4px;
  white-space: pre-wrap;
}
.content .organization .contact .missing {
  font-size: 0.8em;
  color: #4b4b4b;
}
.content .organization .renewal-request-table {
  margin: 30px 0 30px 0;
}
.content .org-preview {
  position: relative;
}
.content .org-preview {
  background: linear-gradient(120deg, #dcdcdc, #ffffff);
  width: 220px;
  height: 150px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  margin: 0 10px 10px 0;
  transition: all 200ms;
  cursor: pointer;
}
.content .org-preview .title {
  text-align: right;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 20px;
  height: 60px;
  width: 100%;
  padding: 20px 20px 0 0;
  font-weight: bold;
}
.content .org-preview .contact {
  box-sizing: border-box;
  height: 68px;
  font-size: 16px;
  line-height: 16px;
  padding: 20px;
  width: 100%;
  text-align: right;
}
.content .org-preview:hover {
  transform: translateY(-10px);
  box-shadow: 5px 5px 5px rgba(0, 50, 0, 0.2);
}
.content .org-preview .systemname {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.5rem;
  font-weight: bold;
  padding: 5px;
}
.content .org-preview .country {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  font-size: 0.7rem;
  font-weight: bold;
}
.content .org-preview .type {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  font-size: 0.5rem;
  font-weight: bold;
}
.dialog .org-cat {
  border-color: white;
}
table .org-catgories {
  display: flex;
  gap: 3px;
}
.org-cat {
  font-size: 0.9em;
  border: 1px solid black;
  color: black;
  border-radius: 3px;
  padding: 2px 4px;
  user-select: none;
}
form .org-category-field {
  max-width: 250px;
}
form .org-category-field .selected {
  border: 1px solid white;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 5px 5px 10px 5px;
  min-height: 1em;
}
form .org-category-field .un-selected,
form .org-category-field .selected {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
form .org-category-field .un-selected .org-cat,
form .org-category-field .selected .org-cat {
  cursor: pointer;
  transition: all 200ms;
}
form .org-category-field .un-selected .org-cat:hover,
form .org-category-field .selected .org-cat:hover {
  text-decoration: underline;
  box-shadow: 0 0 4px white;
}
.org-form {
  width: 90vw;
}
.org-form .form-error {
  text-align: left;
}
.org-form .form-group {
  max-width: 250px;
}
.org-form form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.org-form form .well,
.org-form form p {
  max-width: 250px;
}
.org-form form .well p,
.org-form form p p {
  max-width: 230px;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  font-style: italic;
}
.org-form form .well h2,
.org-form form p h2 {
  margin-bottom: 0;
}
.org-form form .well .form-group,
.org-form form p .form-group {
  max-width: 230px;
}
.org-form .finansial-info h2,
.org-form .finansial-info p {
  max-width: 250px;
}
.org-form .finansial-info p {
  margin-bottom: 1em;
}
.org-form .license-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.org-form .license-info h2 {
  margin-right: auto;
}
.org-form .license-info button {
  width: 90%;
  margin-top: 10px;
}
.org-form .license-info .licenses-box {
  min-width: 200px;
  border: 1px solid white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.org-form .license-info .licenses-box .license {
  padding: 5px;
}
.org-form .license-info .licenses-box .license:nth-child(even) {
  background: #737777;
}
.org-name a {
  text-decoration: none;
  color: inherit;
}
.org-name a:hover {
  color: #f48120;
}
.form-group.no-width .form-control {
  width: 100%;
  min-width: 50px;
}
.form-group.short .form-control {
  width: 70px;
  min-width: 50px;
}
.org-profile .search-results {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.my-translations {
  padding: 40px;
  overflow: visible;
}
.my-games {
  padding: 40px;
  overflow: visible;
}
.my-games .secondary {
  color: #787878;
}
.my-games .title {
  display: inline-block;
}
.my-games .container {
  background: rgba(200, 200, 200, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
.my-games .container h2,
.my-games .container h1 {
  display: inline-block;
}
.my-games .container h2:after,
.my-games .container h1:after {
  content: "";
  display: block;
  width: 33%;
  margin: 10px 0 20px 0;
  box-sizing: content-box;
  border-bottom: 1px solid rgba(50, 50, 50);
}
.game-chooser {
  max-width: 900px;
  overflow: auto;
  padding-top: 25px;
}
.game-chooser .game-choice {
  position: relative;
  color: white;
  overflow: initial;
  cursor: pointer;
  width: 130px;
  min-height: 160px;
  padding: 10px;
  float: left;
  text-align: center;
}
.game-chooser .game-choice.active:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 90px;
  right: 40px;
  transform: rotate(45deg);
  height: 48px;
  width: 24px;
  border-bottom: 14px solid #00c736;
  border-right: 14px solid #00c736;
}
.game-chooser .game-choice.soon {
  cursor: initial;
}
.game-chooser .game-choice.soon:hover > img {
  transform: none;
}
.game-chooser .game-choice.soon:hover > .game-name {
  font-weight: initial;
}
.game-chooser .game-choice.soon img {
  filter: grayscale();
}
.game-chooser .game-choice.soon .hover-msg {
  position: fixed;
}
.game-chooser .game-choice .soon-text {
  position: absolute;
  color: #AA0000;
  background: rgba(255, 255, 255, 0.7);
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  left: 20px;
  right: 20px;
  padding: 5px;
  top: 25px;
  border-radius: 5px;
}
.game-chooser .game-choice:active,
.game-chooser .game-choice:focus {
  outline: 0;
}
.game-chooser .game-choice:hover > img,
.game-chooser .game-choice:active > img,
.game-chooser .game-choice:focus > img {
  transform: scale(1.4);
}
.game-chooser .game-choice:hover > .game-name {
  font-weight: bold;
}
.game-chooser .game-choice img {
  transform-origin: bottom;
  transition: all 300ms;
  height: 110px;
}
.game-chooser .game-choice .game-name {
  font-size: 0.8rem;
  text-align: center;
}
.game-list {
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.game-list .game {
  transition: all 200ms;
  margin: 0 10px 10px 0;
}
.game-list .game:hover {
  transform: translateY(-10px);
}
.playlist-list {
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.playlist-list .playlist {
  transition: all 200ms;
}
.my-games-edit-dialog .dialog-icon {
  cursor: pointer;
}
.my-games-edit-dialog .dialog-icon:hover {
  color: #a00000;
}
@media screen and (min-width: 768px) {
  .game-chooser .game-choice {
    width: 130px;
    min-height: 180px;
    padding: 20px;
  }
  .game-chooser .game-choice .game-name {
    font-size: 1.2rem;
  }
}
.content .menu-content .row {
  margin-bottom: 50px;
}
.content .menu-content .component-slider-container .center {
  padding-top: 4px;
}
.content .menu-content .preview-container {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  width: 1280px;
}
.content .menu-content .preview-container .preview-menu {
  box-sizing: border-box;
  transform-origin: top left;
  color: white;
  width: 1280px;
  height: 800px;
  background: #323232;
  padding: 40px 0;
}
.content .menu-content .preview-container .preview-menu .component-slider-container .center {
  width: calc(100% - 160px);
}
.content .menu-content .preview-container .preview-menu .component-slider-container .arrow {
  background: none;
  width: 80px;
}
.content .menu-content .preview-container .preview-menu .component-slider-container .arrow.right {
  background: linear-gradient(to right, transparent, #323232);
}
.content .menu-content .preview-container .preview-menu .component-slider-container .arrow.left {
  background: linear-gradient(to left, transparent, #323232);
}
.content .menu-content .preview-container .preview-menu .row-heading-container {
  display: flex;
  padding: 0 80px;
  cursor: pointer;
}
.content .menu-content .preview-container .preview-menu .row-heading-container h2 {
  padding-right: 10px;
}
.content .menu-content .preview-container .preview-menu .row-heading-container .btn {
  font-size: 0.75em;
  margin-top: 0.1em;
  margin-bottom: 1.2em;
}
.sort-menu-dialog .explainer {
  margin-bottom: 20px;
}
@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004Hao.ttf) format('truetype');
}
.style-preview {
  flex-direction: row;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 20px 0 0 0;
}
.style-preview .card-preview {
  width: initial;
  float: none;
  margin-bottom: initial;
}
.memory .item-container .list {
  gap: 20px;
}
.memory .item-container .list .pair {
  display: flex;
  position: relative;
}
.memory .item-container .list .pair.clickable {
  cursor: pointer;
}
.memory .item-container .list .pair.clickable:hover .card-preview {
  animation-name: wiggle;
  animation-duration: 200ms;
  animation-iteration-count: infinite;
}
.memory .item-container .list .pair .card-preview {
  will-change: transform;
}
.memory .item-container .list .pair .card-preview:first-child {
  transform: rotate(-5deg);
}
.memory .item-container .list .pair .card-preview:last-child {
  transform: rotate(5deg);
}
.memory .item-container .list .pair .card-preview .card {
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.5);
}
.memory-settings .flex-container {
  display: flex;
}
.memory-settings .flex-container .flex-child {
  flex: 1;
  width: 320px;
}
.memory-settings .form-group.player-image {
  max-width: 155px;
}
.memory-settings .form-group.player-image .btn {
  width: 60px;
}
.memory-settings form {
  width: 640px;
}
.memory-settings form h3 {
  margin-top: 16px;
}
.memory-settings .preview {
  position: relative;
  width: 640px;
  height: 400px;
  background-color: white;
  transform-origin: top left;
}
.memory-settings .preview .player {
  position: absolute;
  font-family: 'Permanent Marker', cursive;
  width: 60px;
  height: 68px;
  margin: 12px;
}
.memory-settings .preview .player.number {
  font-size: 40px;
  color: black;
  text-align: center;
  height: 61px;
  width: 55px;
}
.memory-settings .preview .card {
  position: absolute;
  width: 120px;
  height: 150px;
}
.memory-settings .preview .card.left {
  margin-Left: 170px;
  margin-Top: 125px;
}
.memory-settings .preview .card.right {
  margin-Left: 350px;
  margin-Top: 125px;
}
.memory-settings .preview .topleft {
  left: 5px;
  top: 0px;
  transform: rotate(135deg);
}
.memory-settings .preview .topright {
  right: 5px;
  top: 0px;
  transform: rotate(-135deg);
}
.memory-settings .preview .bottomleft {
  bottom: 0px;
  left: 5px;
  transform: rotate(45deg);
}
.memory-settings .preview .bottomright {
  right: 5px;
  bottom: 0px;
  transform: rotate(-45deg);
}
.memory-settings .preview .background-image {
  position: absolute;
  width: 640px;
  height: auto;
  max-width: 640px;
}
.pair-dialog.dialog .dialog-bottom .dialog-icon {
  cursor: pointer;
  transition: all 200ms;
}
.pair-dialog.dialog .dialog-bottom .dialog-icon:hover {
  color: #AA0000;
  border-color: #AA0000;
}
.memory-pair-form .form {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.memory-pair-form .pair {
  box-sizing: border-box;
  float: left;
  width: 100%;
  overflow: initial;
  margin-bottom: 30px;
  width: 250px;
}
.memory-pair-form .general-error {
  text-align: left;
  margin: 20px 0;
}
.memory-pair-form .card-previews-container {
  display: flex;
  gap: 10px;
}
.memory-pair-form .card-previews-container .card-with-background {
  position: relative;
  height: 167.11px;
  width: 268px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.memory-pair-form .card-previews-container .card-with-background .background {
  width: 100%;
  position: absolute;
}
.card-preview {
  position: relative;
  background: #ececea;
}
.card-preview.transparent {
  background: transparent;
}
.card-preview .front {
  position: absolute;
  width: 105px;
  z-index: 0;
}
.card-preview .audio-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 24px;
  color: black;
  z-index: 1;
}
.card-preview .audio-icon svg {
  filter: drop-shadow(1px 1px 2px #ffffff);
}
.card-preview .card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  margin: auto;
  transform-origin: left;
  line-height: initial;
  height: 122px;
  width: 105px;
}
.card-preview .card.only-text {
  justify-content: center;
}
.card-preview .card img {
  box-sizing: border-box;
  max-width: 100%;
  min-height: 14px;
  padding: 5px 5px 2px 5px;
  margin: 0 auto;
  z-index: 1;
}
.card-preview .card div {
  box-sizing: border-box;
  color: black;
  width: 100%;
  padding: 2px 5px;
  z-index: 1;
  white-space: pre-wrap;
}
@media screen and (min-width: 768px) {
  .memory-pair-form .pair h2 {
    display: none;
  }
}
.word-wizard-form {
  max-width: 250px;
}
.word-wizard-wordpreview {
  z-index: 100;
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-60%, -50%);
}
.word-container {
  float: left;
  padding: 0;
  min-height: 500px;
}
.word-container .item-container-sub-heading h3 {
  text-align: left;
}
.word-wizard-img-size {
  width: 100%;
  height: 100%;
}
.word-wizard-wordpreviewimg {
  z-index: 100;
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-58%, -65%);
  width: 260px;
}
.myfloor-text.word-wizard-text-bottom {
  z-index: 100;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-55%, -50%);
  width: 300px;
}
.myfloor-text.word-wizard-text-center {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
  width: 300px;
}
.word-wizard-wordpreview-sound {
  z-index: 100;
  position: absolute;
  top: 26%;
  left: 16%;
  transform: translate(-60%, -90%);
}
.word-wizard_wordToSpellText {
  color: gray;
  margin-bottom: -50px;
  margin-left: 40px;
}
.word-wizard_wordToSpellText_error {
  color: #ff0000;
  margin-bottom: -50px;
  margin-left: 40px;
}
.word-wizard_maxToSpell {
  color: red;
  font-size: 0.85em;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px;
  max-width: 250px;
  box-sizing: border-box;
  border-radius: 2px;
}
.changeRed {
  color: red;
}
.word-wizard-container-all {
  float: left;
}
.word-wizard_container {
  position: relative;
  overflow: hidden;
  width: 520px;
  height: 380px;
  float: left;
  transform: scale(0.6);
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
  margin-left: -80px;
  margin-right: -60px;
}
.word-wizard_container.onClick {
  cursor: pointer;
}
.word-wizard_container.onClick:hover {
  transform: translateY(-10px) scale(0.7);
}
.login-content {
  position: relative;
}
.login-form-container {
  overflow: visible;
  background: linear-gradient(22.5deg, #000038, #00a79d);
  /*linear-gradient(22.5deg,rgb(0,20,255), rgb(255, 0, 128));*/
  padding: 50px 10px;
}
.login-form-container .login-form {
  margin: auto;
  width: 280px;
  width: 250px;
}
.login-form-container .login-form .form-error {
  color: #ff6565;
}
.login-form-container .login-form .btn-split-container {
  margin-top: 1em;
}
.login-form-container .login-form .form-group {
  position: relative;
  text-align: center;
}
.login-form-container .login-form .form-group .form-control {
  border-color: white;
  color: white;
}
.login-form-container .login-form .form-group.icon-group .input-icon {
  color: white;
}
.login-form-container .profile {
  width: 150px;
  margin: auto;
  margin-bottom: 30px;
}
.login-form-container .profile img {
  width: 100%;
  border-radius: 50%;
}
.login-form-container .forgot-password {
  text-align: right;
  font-style: italic;
  color: white;
  line-height: 1.2em;
}
.login-form-container .forgot-password a {
  color: white;
}
.login-form-container .forgot-password span,
.login-form-container .forgot-password a {
  overflow: visible;
  margin-right: 3px;
  display: block;
  transition: all 0.2s;
  transform: scale(1);
  transform-origin: right;
}
.login-form-container .forgot-password span:hover,
.login-form-container .forgot-password a:hover {
  text-decoration: underline;
  cursor: pointer;
  color: white;
  transform: scale(1.2);
}
.login-form-container-bottom {
  background: #ffffff;
  height: 100px;
}
.loading-spinner {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 4em;
  line-height: 1;
  padding: 20px;
  color: black;
}
.request-dialog {
  max-width: 400px;
}
@media screen and (min-width: 768px) {
  .login-form-container {
    padding: 75px;
  }
}
.top-menu .search-form .hidden,
.top-menu .language-form .hidden {
  width: 0;
}
.top-menu .search-form {
  float: left;
}
.top-menu .search-form input {
  color: black;
  border: 1px solid black;
  width: 200px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 35px;
  font-size: 14px;
  transition: all 200ms;
}
.top-menu .search-form .search-icon {
  position: relative;
  font-size: 25px;
  left: 30px;
  padding-top: 5px;
  vertical-align: -7px;
}
.top-menu .language-form {
  float: left;
}
.top-menu .language-form .form-group .icon {
  position: relative;
  left: 25px;
  font-size: 1.2em;
}
.top-menu .language-form .form-group select {
  border: 1px solid black;
  height: 35px;
  padding-left: 25px;
  color: black;
  max-width: 200px;
  transition: all 200ms;
}
.content .library-content {
  padding: 40px;
}
.content .library-content .search-result-container {
  min-height: 200px;
  position: relative;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px -5px -5px -5px;
}
.content .library-content .search-result-container .game,
.content .library-content .search-result-container .playlist-preview {
  margin: 5px;
}
.content .library-content .navigation-menu {
  margin-bottom: 20px;
  display: flex;
}
.content .library-content .navigation-menu .btn {
  margin-right: 10px;
}
.content .library-content .navigation-menu .btn.active {
  background: #882144;
}
.content .library-content .navigation-menu .btn.verified {
  display: flex;
  padding-right: 0.5em;
  gap: 5px;
  align-items: center;
}
.content .library-content .navigation-menu .btn.verified img {
  width: 15px;
}
.content .library-content .load-more {
  position: relative;
  min-height: 200px;
}
.filters-container {
  padding-bottom: 10px;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.25);
  color: black;
  cursor: pointer;
}
.filters-container .minimized .filters {
  width: 80%;
  float: left;
  margin: 10px 0;
}
.filters-container .minimized .filters .filter {
  margin-bottom: 8px;
}
.filters-container .minimized .filters .checkbox-container {
  max-width: fit-content;
  position: relative;
  pointer-events: none;
}
.filters-container .minimized .filters .checkbox-container .checkbox-slide {
  position: relative;
}
.filters-container .minimized .tag {
  padding: 3px 4px;
  font-size: 0.8em;
  margin: 0 2px;
}
.filters-container .minimized .buttons {
  margin-bottom: 10px;
  font-size: 1.2em;
}
.filters-container .minimized .buttons .svg-inline--fa {
  width: 2em;
}
.filters-container .maximized .filters {
  display: flex;
  flex-wrap: wrap;
}
.filters-container .maximized .form-control {
  color: black;
  border-color: black;
}
.filters-container .maximized .filter {
  margin: 0 40px 0 0;
  width: 250px;
  min-height: 100px;
  float: left;
}
.filters-container .maximized .search-and-sort {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.filters-container .maximized .search-and-sort .btn {
  margin: 10px 10px 2px 0;
}
.filters-container .sort-container {
  display: inline-block;
}
.filters-container .sort-container .flex {
  display: flex;
  flex-direction: row;
}
.filters-container .sort-container .flex .asc {
  cursor: pointer;
  font-size: 1.4em;
  padding: 5px 10px 5px 10px;
  border: 1px solid white;
  border-radius: 3px;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
}
.filters-container .sort-container .flex .checkbox-label {
  padding-left: 0;
  padding-right: 10px;
}
.sortable .game .hidden {
  display: none;
}
.game-copies {
  position: relative;
  margin: 10px 0 10px 0;
}
.game-copies h3 {
  font-weight: bold;
  font-size: 0.8em;
}
.game-copies .btn {
  padding: 0.3em 1em 0.3em 0.3em;
  margin: 5px 7px 0 0;
  background: #e6e6e6;
}
.game-copies .btn.original .owner-img {
  height: 2.5em;
  width: 2.5em;
}
.game-copies .btn.original .game-symbol {
  height: 2.5em;
}
.game-copies .btn.active {
  background: #00A79D;
  color: white;
}
.game-copies .btn .title {
  font-weight: bold;
  min-height: 1em;
}
.game-copies .btn .owner {
  text-align: left;
  font-size: 0.7em;
}
.game-copies .btn .star {
  text-align: left;
  font-size: 0.7em;
}
.game-copies .btn .owner-img {
  height: 2em;
  width: 2em;
  margin-right: 0.5em;
  padding: 0;
}
.game-copies .btn .owner-img img {
  width: 100%;
}
.game-copies .btn .game-symbol {
  height: 2em;
  filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.75));
  margin-left: 0.5em;
  margin-right: -0.3em;
  padding: 0;
}
.game-copies .btn .game-symbol img {
  width: inherit;
  height: inherit;
}
.dialog.playlist-chooser {
  width: 85%;
}
.dialog.playlist-chooser .game-detailed-preview {
  color: white;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  padding: 10px;
}
.dialog.playlist-chooser .game-detailed-preview .author-preview .author-name {
  color: white;
}
.dialog.playlist-chooser .game-detailed-preview .text-content h2 {
  font-weight: bold;
}
.dialog.playlist-chooser .playlist-preview .pl-description::after {
  display: none;
}
.dialog.playlist-chooser .playlist-list {
  display: flex;
  flex-wrap: wrap;
}
.dialog.playlist-chooser .playlist-list .playlist-preview {
  float: none;
}
.settings {
  max-width: 800px;
}
.settings .skin-preview {
  width: 300px;
}
.my-games-edit-dialog {
  overflow: initial;
}
.my-games-edit-dialog .func-button {
  padding-bottom: 20px;
}
.my-games-edit-dialog .icon-container .form-group {
  float: left;
}
.my-games-edit-dialog .icon-container .form-group.icon-btn .btn {
  width: 200px;
}
.my-games-edit-dialog .form-left-part,
.my-games-edit-dialog .form-right-part {
  max-width: 250px;
}
.my-games-edit-dialog .form-left-part {
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .my-games-edit-dialog .form-left-part {
    float: left;
    padding-bottom: initial;
  }
  .my-games-edit-dialog .form-right-part {
    float: left;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    display: block;
  }
}
.author-chooser-dialog .authors-container {
  position: relative;
  min-height: 100px;
  max-width: 80vw;
}
.author-chooser-dialog .authors-container .hover-trigger {
  position: initial;
}
.requirements-journey {
  width: 80%;
  max-width: 300px;
  min-height: 50px;
  position: fixed;
  bottom: 2%;
  right: 20px;
  transition: all 500ms;
  background: white;
  border: 1px solid black;
  cursor: pointer;
  padding: 10px 10px 20px 10px;
  font-size: 0.95em;
}
.requirements-journey.hidden {
  transform: translateX(92.5%);
}
.requirements-journey .toggle {
  position: absolute;
  background: white;
  bottom: 3px;
  left: 3px;
}
.requirements-journey ul {
  margin-left: 5px;
  margin-bottom: 10px;
}
.requirements-journey ul li {
  font-weight: bold;
}
.requirements-journey ul li.missing {
  color: #AA0000;
}
.requirements-journey ul li.done {
  color: #00A79D;
}
.requirements-journey ul ul {
  margin-left: 0;
  margin-bottom: 5px;
}
.requirements-journey ul ul li {
  font-weight: normal;
  margin-bottom: 0;
}
.requirements-journey ul ul li.missing {
  margin-left: 1.2em;
}
.requirements-journey ul ul li span:hover {
  text-decoration: underline;
}
.create-static-game {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-game-item {
  margin-top: 5px;
  width: 40px;
  height: 40px;
}
.new-game-item .new-game-item-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.7);
  border-radius: 100%;
}
.new-game-item:hover .new-game-item-btn {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
}
.new-game-item .hover-msg {
  min-width: 100px;
  bottom: 100%;
}
.new-game-item.disabled .new-game-item-btn {
  background-color: rgba(0, 0, 0, 0.1);
  border: unset;
  color: rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
  box-shadow: unset;
}
.floor-chooser-dialog .btn {
  margin: 0 10px 10px 0;
}
.floor-chooser .admin-options .btn {
  margin: 15px 0;
  display: block;
}
.floor-chooser .hand {
  margin: 10px 0;
}
.floor-chooser form {
  width: 250px;
}
.education-node-form .dialog-icon:hover {
  color: #AA0000;
  cursor: pointer;
}
.delete-edu-game .edu-bread-crumb {
  margin-bottom: 0;
}
.delete-edu-game .form-group {
  margin-bottom: 20px;
}
.delete-edu-game .description {
  margin-bottom: 20px;
}
.education-admin .preview .dialog-top {
  max-width: 90vw;
  min-width: 20vw;
  min-height: 20vh;
}
.add-edugame-form {
  min-width: 33.3vw;
  color: white;
}
.add-edugame-form .edu-bread-crumb .bread-crumb-title {
  color: white;
}
.add-edugame-form .edu-bread-crumb .bread-crumb-back {
  color: white;
}
.education-plan-container {
  display: flex;
  flex-direction: row;
}
.education-plan-container .education-plan {
  margin: 0 20px 20px 0;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.education-plan-form-dialog .dialog-icon {
  cursor: pointer;
}
.education-plan-form-dialog .dialog-icon:hover {
  color: #b40000;
}
.edu-bread-crumb {
  margin-bottom: 30px;
  color: darkgrey;
  font-size: 0.8em;
}
.edu-bread-crumb .bread-crumb-title {
  color: black;
}
.edu-bread-crumb .bread-crumb-back {
  cursor: pointer;
  margin: 0.5em 0;
  color: black;
}
.edu-bread-crumb .bread-crumb-back:hover {
  color: black;
}
.edu-bread-crumb .node-name {
  cursor: pointer;
}
.edu-bread-crumb .node-name:hover {
  color: black;
}
.education-tree .node {
  padding: 20px;
  margin: 0 2em 1em 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
  float: left;
  cursor: pointer;
  position: relative;
  background: white;
  color: black;
}
.education-tree .node:hover {
  box-shadow: 0 0 10px #000000;
}
.education-tree .node .node-edit {
  position: absolute;
  bottom: -0.75em;
  right: -1em;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
  padding: 0.15em 0.25em;
  background: white;
  z-index: 2;
  border-radius: 0.25em;
  color: #3c3c3c;
}
.education-tree .node .node-edit:hover {
  color: #eb008b;
}
.pop-msg {
  position: fixed;
  bottom: 2vh;
  right: 2vh;
  width: 17em;
  height: 9em;
  background: #211d70;
  color: white;
  border-radius: 5px;
  animation: animate-up 0.5s linear, animate-down 0.5s linear 5s;
  animation-fill-mode: forwards;
  box-shadow: 3px 3px 7px black;
}
.pop-msg .text {
  text-align: center;
  margin-top: 0.4em;
  padding: 0.4em;
  font-size: 1.2em;
}
.pop-msg .sub-text {
  text-align: center;
  margin-top: 0.4em;
  font-size: 0.8em;
}
.pop-msg .sub-text .underline {
  text-decoration: underline;
}
.pop-msg .icon-container {
  text-align: center;
  margin-top: 1em;
}
.pop-msg .icon-container .icon {
  width: 2em;
  height: 2em;
}
@keyframes animate-up {
  from {
    bottom: -20vh;
  }
  to {
    bottom: 2vh;
  }
}
@keyframes animate-down {
  from {
    bottom: 2vh;
  }
  to {
    bottom: -20vh;
  }
}
.education-admin .list .unverified-game {
  border-bottom: 1px solid black;
  padding: 10px;
  background: linear-gradient(to bottom, rgba(220, 220, 220, 0.3), rgba(255, 255, 255, 0));
}
.education-admin .list .unverified-game .float {
  margin: 10px;
}
.education-admin .list .unverified-game .info {
  padding: 0.2em 0;
}
.education-admin .list .unverified-game .edu-bread-crumb {
  margin: 0.2em 0;
}
.education-admin .list .unverified-game .btn {
  margin: 10px;
}
.education-admin .list .unverified-game .author-name {
  display: inline;
}
.preview-wrapper .dj-soundclip-preview {
  position: relative;
  width: 640px;
  height: 480px;
}
.preview-wrapper .dj-soundclip-preview .background-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 640px;
  height: 400px;
  border: 2px solid black;
}
.preview-wrapper .dj-soundclip-preview img {
  position: absolute;
}
.preview-wrapper .dj-soundclip-preview .audio {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 3rem;
}
.preview-wrapper .dj-soundclip-preview .position {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 2rem;
}
.preview-wrapper .dj-soundclip-preview .image {
  width: 100%;
}
.dj-soundclips .dj-soundclip-container {
  overflow: hidden;
  min-height: 210px;
  padding: 0 20px 20px 0;
}
.dj-soundclips .dj-soundclip-container .preview-wrapper {
  width: 280px;
  height: 210px;
  margin: auto auto 20px;
}
.dj-soundclips .dj-soundclip-container .preview-wrapper.clickable {
  cursor: pointer;
}
.dj-soundclips .dj-soundclip-container .preview-wrapper.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.dj-soundclips .dj-soundclip-container .preview-wrapper .dj-soundclip-preview {
  transform-origin: top left;
  transform: scale(0.4375);
}
.dj-soundclip-edit-dialog .form-group {
  text-align: left;
}
.dj-soundclip-edit-dialog .form-group .form-control {
  float: initial;
}
.dj-soundclips .dj-soundclip-container .sortable.drag-source {
  border: 5px solid yellow;
}
.dj-soundclips .dj-soundclip-container .sortable.drag-target {
  border: 5px solid green;
}
.dj-soundclips .dj-soundclip-container .sortable {
  width: 280px;
  height: 175px;
}
.dj-soundclip-edit-dialog .dialog-icon {
  cursor: pointer;
}
.dj-soundclip-edit-dialog .dialog-icon:hover {
  color: #a00000;
}
@media (min-width: 640px) {
  .dj-soundclips .dj-soundclip-container .sortable {
    float: left;
    margin: 0 20px 20px 0;
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.dialog-overlay {
  position: fixed;
  overflow: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  text-align: center;
}
.dialog-overlay.scrollable .dialog {
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: visible;
}
.dialog-overlay .dialog {
  animation: 200ms ease-out 0s 1 slideInFromLeft;
  display: inline-block;
  text-align: start;
  position: relative;
  box-sizing: border-box;
  margin: 5vh auto;
  box-shadow: 0 0 5vh 0.5vh black;
  min-width: 250px;
  -webkit-overflow-scrolling: initial;
}
.dialog-overlay .dialog .dialog-total-content {
  position: relative;
}
.dialog-overlay .dialog .dialog-total-content h1 {
  text-align: initial;
}
.dialog-overlay .dialog .dialog-icon-container {
  position: absolute;
  padding: 5px 5px 10px;
}
.dialog-overlay .dialog .dialog-icon-container .icon {
  cursor: pointer;
  color: white;
  background: none;
  border: none;
  font-size: 1.5em;
  line-height: 0.75;
  padding: 5px;
}
.dialog-overlay .dialog .dialog-icon-container .icon .hover-msg {
  font-size: 1rem;
}
.dialog-overlay .dialog .dialog-icon-container .icon .hover-msg .min-width {
  min-width: 220px;
}
.dialog-overlay .dialog .dialog-icon-container .icon .hover-msg .copy {
  white-space: nowrap;
  width: fit-content;
}
.dialog-overlay .dialog .dialog-icon-container .icon:hover {
  transform: translateY(-2px);
}
.dialog-overlay .dialog .dialog-icon-container .icon:disabled {
  color: gray;
  cursor: no-drop;
  transform: none;
}
.dialog-overlay .dialog .dialog-top {
  overflow: visible;
  padding: 50px 10px 20px 10px;
  background: linear-gradient(22.5deg, #000038, #00a79d);
  color: white;
}
.dialog-overlay .dialog .dialog-top h2 {
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .dialog-overlay .dialog .dialog-top {
    padding: 40px;
  }
}
.dialog-overlay .dialog .dialog-top .image-crop-form .form-group,
.dialog-overlay .dialog .dialog-top .sound-form .form-group {
  float: none;
}
.dialog-overlay .dialog .dialog-top .image-crop-form .btn,
.dialog-overlay .dialog .dialog-top .sound-form .btn {
  width: initial;
}
.dialog-overlay .dialog .dialog-top .btn-split-container {
  z-index: 1;
}
.dialog-overlay .dialog .dialog-bottom {
  position: relative;
  background: white;
  z-index: 0;
  min-height: 80px;
}
.dialog-overlay .dialog .dialog-bottom .dialog-icon {
  transition: all 200ms;
  cursor: pointer;
  color: black;
  position: absolute;
  right: 10pt;
  bottom: 10pt;
  font-size: 25pt;
}
.dialog-overlay .dialog .dialog-bottom .dialog-icon:hover {
  color: #b40000;
  border-color: #b40000;
}
.dialog-overlay .dialog .close-button {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  background: none;
  border: none;
  font-size: 2em;
  line-height: 0.75;
  padding: 10px;
}
.dialog-overlay .dialog .close-button .close-button i {
  line-height: inherit;
}
.dialog-overlay .dialog .divider {
  border-bottom: 1px solid white;
  margin: 20px -40px 20px -40px;
}
.dialog-overlay .dialog.narrow {
  max-width: 350px;
}
.dialog-overlay .dialog.sportswall .dialog-top {
  background: linear-gradient(22.5deg, #000038, #1b1464);
}
.dialog.ditj-dialog h1,
.dialog.ditj-dialog h2 {
  text-align: center;
}
.ditj-question {
  width: 236px;
  margin: auto auto 40px;
}
.ditj-question-form {
  width: 250px;
  margin: auto auto 40px;
}
.ditj-question-form .form-group {
  text-align: center;
}
.ditj-question-form .form-group .form-control {
  float: none;
}
.ditj-question-form .form-group .form-control.slider {
  margin: auto;
}
.ditj-question-form .form-group .font-color-container,
.ditj-question-form .form-group .font-family {
  vertical-align: middle;
}
.dialog.settings .ditj-skin-preview .skin-preview {
  width: 417px;
  margin-bottom: 5px;
}
.dialog.settings .ditj-skin-preview .flex {
  gap: 5px;
}
.dialog.settings .ditj-skin-preview .flex .ditj-preview-wrapper {
  width: 206px;
  height: 155px;
}
.dialog.settings .ditj-skin-preview .flex .ditj-preview-wrapper .ditj-question-preview {
  transform-origin: top left;
  scale: 0.5;
}
.ditj-form .ditj-answers {
  overflow: auto;
  border-bottom: 1px solid white;
}
.ditj-form .ditj-answers .answer {
  float: left;
  padding: 5px;
}
.ditj-form .ditj-answers .answer .form-group {
  max-width: 200px;
}
.ditj-form .ditj-answers .answer .form-control {
  width: 130px;
}
.ditj-form .ditj-answers .answer .text-alignment .btn {
  width: initial;
}
.ditj-form .ditj-answers .answer .btn {
  width: 82px;
}
.ditj-form .ditj-answers .answer .font-family {
  width: 80px;
}
.ditj-dialog .dialog-icon {
  cursor: pointer;
}
.ditj-dialog .dialog-icon:hover {
  color: #a00000;
}
.ditj-question-form-preview {
  padding: 20px;
  text-align: center;
}
.ditj-question-preview {
  position: relative;
  text-align: center;
  margin: auto;
  width: 412px;
  height: 311px;
}
.ditj-question-preview .question {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 22px;
  left: 43px;
  width: 310px;
  height: 130px;
  box-sizing: border-box;
  overflow: hidden;
  line-height: initial;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.ditj-question-preview .question img {
  max-width: 130px;
  min-width: 130px;
  height: fit-content;
}
.ditj-question-preview .question .myfloor-text {
  height: 100%;
}
.ditj-question-preview .question.image-and-text .myfloor-text {
  width: 58%;
}
.ditj-question-preview .question .audio {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2em;
}
.ditj-question-preview .answer {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.14);
  line-height: initial;
}
.ditj-question-preview .answer.image-and-text img {
  width: 75%;
}
.ditj-question-preview .answer.image-and-text div {
  padding: 0;
  height: 25%;
}
.ditj-question-preview .answer img {
  width: 100%;
}
.ditj-question-preview .answer div {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
}
.ditj-question-preview .answer.correct {
  border: 2px solid green;
}
.ditj-question-preview .answer.a {
  top: 168px;
  left: 43px;
}
.ditj-question-preview .answer.b {
  top: 168px;
  left: 164px;
}
.ditj-question-preview .answer.c {
  top: 168px;
  left: 282px;
}
.ditj-quizzes .ditj-category {
  margin-bottom: 40px;
}
.ditj-quizzes .ditj-category h3 {
  font-size: 1.5em;
  font-weight: bold;
}
.ditj-quizzes .ditj-category .category-name {
  padding: 8px 0;
  width: fit-content;
  margin-left: 0;
}
.ditj-quizzes .ditj-category .category-name.clickable {
  cursor: pointer;
}
.ditj-quizzes .ditj-category .category-name.clickable:hover {
  border: 3px dashed #676565;
  border-radius: 15px;
  padding: 5px;
  margin-left: -8px;
}
.ditj-quizzes .ditj-category .category-name.empty-category-name {
  color: #bdb5b5;
}
.ditj-quizzes .ditj-quizzes-container .ditj-preview-wrapper {
  margin: 5px;
}
.ditj-quizzes .ditj-quizzes-container .ditj-preview-wrapper {
  float: left;
  margin: 5px;
}
.ditj-quizzes .ditj-quizzes-container .ditj-preview-wrapper.clickable {
  cursor: pointer;
}
.ditj-quizzes .ditj-quizzes-container .ditj-preview-wrapper.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
@media screen and (min-width: 768px) {
  .dialog-overlay .dialog.ditj-dialog .dialog-top {
    padding: 40px 0;
  }
  .ditj-form .ditj-answers .answer {
    float: left;
  }
  .ditj-form .ditj-answers .answer .form-control {
    width: 230px;
  }
  .ditj-form .ditj-answers .answer .btn {
    width: 102px;
  }
  .ditj-form .ditj-answers .answer .font-family {
    width: 100px;
  }
}
.confirm {
  display: inline-block;
  position: fixed;
  box-sizing: border-box;
  box-shadow: 0 0 5vh 0.5vh black;
  min-width: 300px;
  background: white;
  color: black;
  margin-bottom: 20vh;
  padding: 20px;
  text-align: center;
  z-index: 101;
}
.confirm .confirm-text {
  padding: 20px 0;
  white-space: pre-wrap;
  line-height: normal;
}
.content .confirm .confirm-buttons .btn {
  min-width: 100px;
  margin: 10px;
  font-size: 1em;
}
.confirm-overlay {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 0, 0, 0.5);
  z-index: 5000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.component-slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.component-slider-container .component-slider {
  transition: all 500ms;
  display: flex;
  margin: 5px 0;
}
.component-slider-container .center {
  float: left;
  width: calc(100% - 160px);
}
.component-slider-container .arrow {
  color: white;
  position: relative;
  float: left;
  width: 80px;
  font-size: 60px;
  text-align: center;
  z-index: 5;
  transition: all 200ms;
  opacity: 0.3;
  margin: 5px 0;
}
.component-slider-container .arrow.right {
  background: linear-gradient(to right, transparent, white 85%);
}
.component-slider-container .arrow.left {
  background: linear-gradient(to left, transparent, white 85%);
}
.component-slider-container .arrow svg path {
  stroke: rgba(0, 0, 0, 0.5);
  stroke-width: 10px;
}
.component-slider-container .arrow.active {
  opacity: 1;
}
.component-slider-container .arrow.active svg path {
  stroke: rgba(0, 0, 0);
  stroke-width: 10px;
}
.component-slider-container .arrow.active:hover {
  opacity: 1;
  font-size: 80px;
}
.combination-preview {
  overflow: auto;
  border-top: 1px solid white;
  padding-top: 20px;
  margin-top: 20px;
}
.combination-preview .preview {
  text-align: left;
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px solid white;
  margin: 20px auto;
}
.combination-preview .preview h4 {
  position: absolute;
  padding: 5px;
}
.lillypad {
  position: relative;
  width: 158px;
  height: 168px;
}
.lillypad .lillypad-content {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 60px 40px 13px 13px;
}
.lillypad .lillypad-content span {
  width: 100%;
}
.lillypad .lillypad-content img {
  padding: 60px 40px 13px 13px;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
form.comboitem-form {
  max-width: 250px;
}
.combination .item-container .list .combo {
  margin: 10px;
  position: relative;
  transition: linear 200ms;
}
.combination .item-container .list .combo.clickable {
  cursor: pointer;
}
.combination .item-container .list .combo.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.combination .item-container .list .combo.dropable .lillypad-image {
  filter: drop-shadow(0 0 10px green);
}
.lillypad-audio {
  font-size: 2em;
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 0;
  color: black;
}
.lillypad-audio:hover {
  color: #323232;
}
.combo-item-dialog.dialog .dialog-bottom .dialog-icon {
  cursor: pointer;
  transition: all 200ms;
}
.combo-item-dialog.dialog .dialog-bottom .dialog-icon:hover {
  color: #b40000;
  border-color: #b40000;
}
.combo-item-dialog .hover-msg .copy-combination-max-elements {
  min-width: 220px;
}
@media screen and (min-width: 768px) {
  .combo-preview-container {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid white;
  }
}
.buzz-it-content {
  margin-top: 40px;
}
.buzz-it-content .item-container .list .buzz-it-preview-quiz {
  float: left;
  margin: 0 20px 20px 0;
}
.buzz-it-content .item-container .list .buzz-it-preview-quiz.clickable {
  cursor: pointer;
}
.buzz-it-content .item-container .list .buzz-it-preview-quiz.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.buzz-it-quiz-form-dialog {
  overflow: initial;
}
.buzz-it-quiz-form-dialog .buzz-it-form-question {
  width: 250px;
}
.buzz-it-quiz-form-dialog .buzz-it-form-question .form-group.small {
  width: initial;
}
.buzzIt-settings .flex-container {
  display: flex;
}
.buzzIt-settings .flex-container .flex-child {
  flex: 1;
  width: 320px;
  height: 290px;
}
.buzzIt-settings .form-group.player-image {
  max-width: 155px;
}
.buzzIt-settings .form-group.player-image .btn {
  width: 50px;
}
.buzzIt-settings form {
  width: 640px;
}
.buzzIt-settings form h3 {
  margin-top: 16px;
}
.buzzIt-settings .preview {
  position: relative;
  width: 640px;
  height: 400px;
  background-color: white;
  transform-origin: top left;
}
.buzzIt-settings .preview .buzzitquiz-preview {
  position: absolute;
  scale: 1;
  top: 20px;
  left: 45px;
}
.buzzIt-settings .preview .player {
  position: absolute;
  width: 60px;
  height: 60px;
  margin: 12px;
  scale: 0.7;
  border-radius: 50%;
}
.buzzIt-settings .preview .p1 {
  left: 60px;
  bottom: 32px;
}
.buzzIt-settings .preview .p2 {
  left: 215px;
  bottom: 45px;
}
.buzzIt-settings .preview .p3 {
  left: 362px;
  bottom: 44px;
}
.buzzIt-settings .preview .p4 {
  right: 45px;
  bottom: 32px;
}
.buzzIt-settings .preview .background-image {
  position: absolute;
  width: 640px;
  height: auto;
  max-width: 640px;
}
.buzz-it-quiz-form-dialog .answer-form {
  float: left;
  width: 200px;
}
.buzz-it-quiz-form-dialog .answer-form .image-button-quick .btn {
  width: calc(100% - 90px);
}
.buzz-it-quiz-form-dialog .answer-form-container > div {
  margin-right: 20px;
}
.buzz-it-quiz-form-dialog .answer-form-container > div:last-of-type {
  margin-right: 0;
}
.buzz-it-preview-quiz {
  position: relative;
  width: 544px;
  height: 231.5px;
}
.buzz-it-preview-quiz .question-box-image {
  width: 100%;
  position: absolute;
}
.buzz-it-preview-quiz .answers {
  position: absolute;
  left: 0;
  top: 125px;
  width: 540px;
  height: 83px;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
}
.buzz-it-preview-quiz .answers .answer1 {
  position: absolute;
  width: 80px;
  left: 25px;
}
.buzz-it-preview-quiz .answers img {
  width: 100%;
}
.buzz-it-preview-quiz .answers .answer2 {
  position: absolute;
  width: 80px;
  left: 230px;
}
.buzz-it-preview-quiz .answers .answer3 {
  position: absolute;
  width: 80px;
  left: 439px;
}
.buzz-it-preview-quiz .answers .text {
  height: 80px;
  width: 80px;
  vertical-align: bottom;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.buzz-it-preview-quiz .answers .image-and-text {
  width: 50px;
  height: 50px;
}
.buzz-it-preview-quiz .question {
  position: absolute;
  left: 5px;
  top: 15px;
  width: 534px;
  height: 80px;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buzz-it-preview-quiz .question p {
  line-height: initial;
  margin: 0 10px;
  white-space: pre-wrap;
}
.buzz-it-preview-quiz .question img {
  height: 80px;
}
.buzz-it-preview-quiz .question-sound {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  font-size: 26px;
  color: black;
}
.buzz-it-preview-quiz .answers .answer.correct:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 50, 0, 0.2);
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .buzz-it-content .item-container .list .buzz-it-preview-quiz {
    transform-origin: top left;
    transform: scale(0.7);
    margin: 0 20px -50px 0;
  }
}
.bike-race-preview-quiz {
  position: relative;
}
.bike-race-preview-quiz .clipboard-image {
  width: 100%;
  position: absolute;
}
.bike-race-preview-quiz .answers {
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
}
.bike-race-preview-quiz .question-block {
  /*background-color: #00a99d;*/
  position: absolute;
  overflow: hidden;
}
.bike-race-preview-quiz .question-block .question-image {
  width: 100%;
  display: flex;
  justify-content: center;
  /*text-align: center;*/
  margin-bottom: 5px;
}
.bike-race-preview-quiz .question-sound {
  position: absolute;
  color: #fff;
}
.bike-race-content .item-container .list .bike-race-preview-quiz.clickable {
  cursor: pointer;
}
.bike-race-content .item-container .list .bike-race-preview-quiz.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.bike-race-quiz-form-dialog form {
  width: 250px;
}
.bike-race-quiz-form-dialog form .form-group.small {
  width: initial;
}
.bike-race-quiz-form-dialog .dialog-icon {
  transition: all 200ms;
  cursor: pointer;
}
.bike-race-quiz-form-dialog .dialog-icon:hover {
  color: #b40000;
  border-color: #b40000;
}
.bike-race-quiz-form-dialog .checkbox-width {
  width: 250px;
}
.dialog .author-preview .author-name {
  color: white;
}
.dialog .author-preview.clickable:hover {
  filter: drop-shadow(0 0 6px white);
}
.dialog .author-preview.small.clickable:hover {
  filter: drop-shadow(0 0 2px white);
}
.author-preview {
  box-sizing: border-box;
  position: relative;
  float: left;
  width: 100px;
  height: 116px;
  padding: 5px;
}
.author-preview.small {
  width: 50px;
  height: 50px;
}
.author-preview.clickable {
  cursor: pointer;
  transition: all 200ms;
}
.author-preview.clickable:hover {
  filter: drop-shadow(0 0 6px black);
}
.author-preview.small.clickable:hover {
  filter: drop-shadow(0 0 2px black);
}
.author-preview .icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  color: #960000;
}
.author-preview .initials-container {
  width: 100%;
  position: relative;
  display: inline-block;
}
.author-preview .initials-container:before {
  content: "";
  display: block;
  margin-top: 100%;
}
.author-preview .initials-container .initials-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background-color: #F2F2F2;
}
.author-preview .initials-container .initials-image svg {
  width: 100%;
  height: auto;
}
.author-preview .initials-container .initials-image svg text {
  font-size: 265px;
  fill: #00A79D;
}
.author-preview img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: white;
}
.author-preview .author-name {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 0.75rem;
  font-size: 0.75rem;
  color: black;
}
.author-preview .hover-msg {
  top: 100%;
  white-space: nowrap;
}
.author-chooser-dialog .author-name {
  color: white;
}
.author-chooser-dialog .author-preview .icon {
  font-size: 1.2em;
  filter: drop-shadow(0 0 1px black);
}
.dialog-overlay .dialog.wos-quiz-form-dialog .dialog-top {
  padding: 40px 20px;
}
.dialog.settings .wos-skin-preview .skin-preview {
  width: 415px;
  margin-bottom: 5px;
}
.dialog.settings .wos-skin-preview .flex {
  gap: 5px;
}
.dialog.settings .wos-skin-preview .flex .preview-wrapper {
  width: 205px;
  height: 130px;
}
.dialog.settings .wos-skin-preview .flex .preview-wrapper .wos-quiz-preview {
  transform-origin: top left;
  scale: 0.5;
}
.woz-quiz-form .wos-answers {
  overflow: visible;
}
.woz-quiz-form .wos-answers .answer {
  width: 220px;
  padding: 5px;
  float: left;
}
.woz-quiz-form .wos-question {
  width: 250px;
  margin: auto;
}
.dialog.wos-quiz-form-dialog {
  overflow: initial;
}
.wos-quiz-preview {
  position: relative;
  text-align: center;
  margin: auto;
  width: 412px;
}
.wos-quiz-preview .quiz-background {
  width: 410px;
}
.wos-quiz-preview .question {
  position: absolute;
  top: 35px;
  left: 100px;
  width: 287px;
  height: 80px;
  box-sizing: border-box;
  overflow: hidden;
  line-height: initial;
  word-wrap: break-word;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wos-quiz-preview .question img {
  max-width: 80px;
  min-width: 80px;
  height: fit-content;
}
.wos-quiz-preview .question .myfloor-text {
  height: 100%;
}
.wos-quiz-preview .question.image-and-text .myfloor-text {
  width: 167px;
  margin-left: 10px;
}
.wos-quiz-preview .audio {
  position: absolute;
  top: 40px;
  left: 55px;
  font-size: 2em;
  color: black;
}
.wos-quiz-preview .answer {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  width: 85px;
  height: 85px;
  border-radius: 10px;
  line-height: initial;
}
.wos-quiz-preview .answer.correct {
  background: rgba(0, 255, 0, 0.2);
}
.wos-quiz-preview .answer.a {
  top: 130px;
  left: 100px;
}
.wos-quiz-preview .answer.b {
  top: 130px;
  left: 200px;
}
.wos-quiz-preview .answer.c {
  top: 130px;
  left: 300px;
}
.wos-quiz-preview .answer img {
  width: 100%;
  margin: 0 auto;
}
.wos-quiz-preview .answer div {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px;
  word-wrap: break-word;
}
.wos-quiz-preview .answer.image-and-text img {
  width: 75%;
}
.wos-quiz-preview .answer.image-and-text div {
  padding: 0;
  height: 25%;
}
.war-of-strategy {
  min-height: 500px;
}
.war-of-strategy .item-container .list .preview-wrapper {
  margin: 5px;
  float: left;
}
.war-of-strategy .item-container .list .preview-wrapper.clickable {
  cursor: pointer;
}
.war-of-strategy .item-container .list .preview-wrapper.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.wos-quiz-form-dialog .dialog-icon {
  cursor: pointer;
}
.wos-quiz-form-dialog .dialog-icon:hover {
  color: #AA0000;
}
@media (max-width: 450px) {
  .preview-wrapper {
    max-width: 260px;
    max-height: 196px;
  }
  .wos-quiz-form-preview .preview-wrapper {
    position: fixed;
    z-index: 1;
    bottom: 130px;
  }
  .preview-wrapper .wos-quiz-preview {
    transform: scale(0.67);
    transform-origin: top left;
  }
  .wos-quiz-form-preview {
    min-height: 160px;
  }
  .woz-quiz-form .wos-answers {
    text-align: center;
  }
  .woz-quiz-form .wos-answers .answer,
  .woz-quiz-form .wos-answers .answer .form-control,
  .woz-quiz-form .wos-answers .answer .form-group,
  .woz-quiz-form .wos-answers .answer .font-color-container {
    float: none;
    vertical-align: middle;
  }
  .woz-quiz-form .wos-answers .answer .form-group {
    text-align: center;
  }
  .woz-quiz-form .wos-answers .answer {
    padding: 20px;
  }
  .woz-quiz-form .wos-answers .answer .form-group .btn-icon-group .btn {
    width: 170px;
  }
  .woz-quiz-form .wos-answers .answer .form-group select.form-control.font-family {
    width: 160px;
  }
  .woz-quiz-form .wos-answers .answer .form-group .form-control {
    width: 200px;
  }
}
.sakura-content .item-container .list .sakura-item {
  float: left;
  margin: 0 20px 20px 0;
}
.sakura-content .item-container .list .sakura-item.clickable {
  cursor: pointer;
}
.sakura-content .item-container .list .sakura-item.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.sakura-content .sakura-item-edit-dialog .sakura-item {
  margin: 20px 0 20px 0;
  position: relative;
}
.sakura-content .sakura-item-edit-dialog .sakura-item img {
  position: relative;
  max-width: 100px;
  max-height: 100px;
}
.sakura-content .sakura-settings {
  min-width: 320px;
  min-height: 200px;
  position: relative;
}
.sakura-content .sakura-settings .background {
  width: 640px;
  height: 400px;
  background-color: white;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
.sakura-content .sakura-settings .youtube-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 640px;
  height: 400px;
}
.sakura-content .sakura-settings.small {
  min-width: 213.12px;
  min-height: 133.2px;
}
.sakura-content .sakura-settings.small .background {
  width: 426.24px;
  height: 266.4px;
}
.sakura-content .sakura-settings.small .youtube-image {
  width: 426.24px;
  height: 266.4px;
}
.jigsaw .dialog-overlay .dialog .dialog-bottom .dialog-icon {
  cursor: pointer;
}
.jigsaw .dialog-overlay .dialog .dialog-bottom .dialog-icon:hover {
  color: #AA0000;
}
.jigsaw .preview-container {
  display: flex;
  flex-wrap: wrap;
}
.jigsaw .preview-container .preview.clickable {
  cursor: pointer;
}
.jigsaw .selector {
  display: flex;
}
.jigsaw .selector .jigsaw-image {
  position: relative;
  height: 40px;
  margin: 0 10px 10px 0;
  overflow: hidden;
  cursor: pointer;
}
.jigsaw .selector .jigsaw-image.active {
  box-shadow: 0 0 10px lightyellow;
}
.jigsaw .selector .jigsaw-image.threebytwo {
  width: 60px;
}
.jigsaw .selector .jigsaw-image.fourbythree {
  width: 53px;
}
.jigsaw .selector .jigsaw-image.sixbyfour {
  width: 60px;
}
.jigsaw .selector .jigsaw-image .puzzle-outline {
  stroke-width: 10;
}
.jigsaw .selector .jigsaw-image .size-text {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 24px;
  color: white;
  font-weight: bold;
  text-shadow: 0 0 2px #000000;
  text-align: center;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.3);
}
.jigsaw .container {
  display: flex;
  flex-wrap: wrap;
}
.jigsaw .container .jigsaw-image {
  position: relative;
  height: 267px;
  margin: 0 20px 20px 0;
  overflow: hidden;
}
.jigsaw .container .jigsaw-image.threebytwo {
  width: 400px;
}
.jigsaw .container .jigsaw-image.fourbythree {
  width: 356px;
}
.jigsaw .container .jigsaw-image.sixbyfour {
  width: 400px;
}
.jigsaw .uploaded {
  position: absolute;
  height: 100%;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  margin: auto;
}
.jigsaw .puzzle-overlay-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.jigsaw .puzzle-overlay-image .puzzle-outline {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.license-content {
  padding: 10px;
  min-height: 200px;
}
.license-content .floor-preview .units-container {
  display: flex;
  flex-wrap: wrap;
}
.license-content .floor-preview .units-container .unit {
  display: flex;
  margin: 5px 5px 0 0;
  padding: 3px;
  border-radius: 3px;
  background: rgba(200, 200, 200, 0.5);
  cursor: pointer;
}
.license-content .floor-preview .units-container .unit svg {
  margin: 7px;
}
.license-content .floor-form-dialog .dialog-icon-container .icon .hover-msg {
  text-wrap: nowrap;
}
.license-history {
  max-width: 250px;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
}
.license-history .enable-form {
  gap: 0 50px;
}
.license-history .simple-table .license-field {
  color: burlywood;
}
.license-history .simple-table tr:hover {
  font-weight: unset;
}
.license-history .simple-table tr:hover .tag {
  font-weight: unset;
}
.license-history .simple-table tr:hover .tag:hover {
  font-weight: unset;
}
.license-history .simple-table td {
  overflow: visible;
  cursor: unset;
}
.license-history .simple-table td.hover-trigger {
  position: relative;
}
.license-history .simple-table td.hover-trigger .hover-msg {
  position: absolute;
  bottom: 5px;
  left: 15px;
  display: block;
  text-wrap: wrap;
  overflow-wrap: break-word;
  white-space: break-spaces;
  min-width: 50px;
  max-width: 500px;
}
.license-history .simple-table td .data-container {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100px;
}
.renewal-dialog .price-warning {
  padding: 10px;
  margin: 10px 0;
  border: dashed 3px lightcoral;
  font-size: 1.05em;
  font-weight: bold;
}
.license-content .floor-admin-preview .hover-msg {
  top: 60px;
}
.license-content .floor-admin-preview .xs-text {
  padding-top: 5px;
  font-family: monospace;
}
.license-content .container {
  display: flex;
  flex-wrap: wrap;
}
.license-content .floor-form-dialog {
  overflow: visible;
}
.license-content .floor-form-dialog .warning-text {
  max-width: 250px;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 7px;
  color: #ff9388;
  background: rgba(100, 100, 100, 0.5);
  font-size: 1.1em;
  line-height: 1.1em;
}
.license-content .floor-form-dialog form p {
  margin-top: -0.5em;
  margin-bottom: 0.5em;
}
.dialog.renewal-overview-dialog p {
  white-space: pre;
  border: 1px dashed white;
  padding: 0.5em;
  margin-bottom: 0.5em;
}
.floor-admin-preview {
  padding: 10px;
  margin: 0 20px 20px 0;
  border: 1px solid black;
  transition: all 200ms;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  color: black;
  min-width: 300px;
}
.floor-admin-preview .btn {
  margin-right: 7px;
  margin-top: 7px;
}
.floor-admin-preview .btn .svg-inline--fa {
  padding-right: 5px;
}
.floor-admin-preview .game-types-container {
  min-height: 3em;
}
.floor-admin-preview .game-types-container img {
  float: left;
  width: 20px;
}
.floor-admin-preview .game-types-container img.inactive {
  filter: grayscale(100);
}
.floor-admin-preview .playlist-container {
  min-height: 40px;
}
.floor-admin-preview .author-container {
  min-height: 40px;
}
.floor-admin-preview .group {
  margin-bottom: 20px;
}
.floor-admin-preview .btn.delete-btn {
  float: right;
  background-color: #933b38;
  color: white;
}
.sort-menu-dialog {
  max-width: 90vw;
}
.sort-menu-dialog .playlist-preview {
  float: none;
}
.sort-menu-dialog .sortable {
  float: left;
  margin: 0 10px 10px 0;
}
.license-table table tbody tr.expired:nth-child(even) {
  background-color: rgba(255, 125, 125, 0.3);
}
.license-table table tbody tr.expired:nth-child(odd) {
  background-color: rgba(255, 125, 125, 0.4);
}
.license-table table tbody tr.expire-soon:nth-child(even) {
  background-color: rgba(255, 200, 75, 0.2);
}
.license-table table tbody tr.expire-soon:nth-child(odd) {
  background-color: rgba(255, 200, 75, 0.3);
}
.form-group.currency .add-btn {
  width: initial;
  font-size: 0.8em;
  margin-right: 10px;
}
.form-group.currency table.volume-price-table thead th {
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid black;
  padding-bottom: 2px;
}
.form-group.currency table.volume-price-table thead th .form-control {
  color: black;
  border-color: black;
}
.form-group.currency table.volume-price-table thead th .add-icon {
  cursor: pointer;
  padding: 0 5px;
  transition: all 100ms;
  transform: rotate(0deg);
}
.form-group.currency table.volume-price-table thead th .add-icon:hover {
  color: #f48120;
  transform: rotate(90deg);
}
.form-group.currency table.volume-price-table tbody .truncate {
  max-width: 150px;
}
.form-group.currency table.volume-price-table tbody .btn-ish:hover {
  cursor: pointer;
  text-decoration: underline;
}
.form-group.currency table.volume-price-table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.form-group.currency table.volume-price-table tbody tr td {
  text-align: center;
  cursor: default;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.7);
}
.form-group.currency table.volume-price-table tbody tr td.month {
  min-width: 50px;
}
.form-group.currency table.volume-price-table tbody tr td.month .form-control {
  text-align: center;
}
.form-group.currency table.volume-price-table tbody tr td .btn {
  padding: 2px 6px;
}
.form-group.currency table.volume-price-table tbody tr td .form-control {
  cursor: text;
  border: none;
  margin: 0;
  width: 50px;
}
.form-group.currency table.volume-price-table tbody tr td .form-control.price {
  width: 70px;
}
.form-group.currency table.volume-price-table tbody tr td .form-control.currency {
  width: 53px;
  appearance: none;
}
.form-group.currency table.volume-price-table tbody tr td .trash {
  padding: 0 5px;
}
.form-group.currency table.volume-price-table tbody tr td .trash:hover {
  color: #933b38;
}
.resubscribe-dialog {
  max-width: 400px;
}
.resubscribe-dialog ul {
  margin-bottom: 10px;
}
.resubscribe-dialog ul li {
  list-style: outside disc;
  margin: 20px 0 20px 20px;
}
.resubscribe-dialog .warning {
  white-space: pre-wrap;
  color: #ffd900;
}
table.license-renew-table tr.month-row {
  font-size: 0.8em;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.33);
}
table.license-renew-table tr td,
table.license-renew-table tr th {
  text-align: start;
  padding: 5px;
}
table.license-renew-table tr td .form-group,
table.license-renew-table tr th .form-group {
  margin-bottom: 0;
}
table.license-renew-table tr td .form-group.error .form-control,
table.license-renew-table tr th .form-group.error .form-control {
  border-color: red;
}
table.license-renew-table tr td .form-group.success .form-control,
table.license-renew-table tr th .form-group.success .form-control {
  border-color: green;
}
table.license-renew-table tr td .form-group.wide,
table.license-renew-table tr th .form-group.wide {
  width: 650px;
  max-width: 650px;
}
table.license-renew-table tr td .form-group.wide .form-control,
table.license-renew-table tr th .form-group.wide .form-control {
  width: 650px;
}
table.license-renew-table thead {
  border-bottom: 1px solid white;
}
.game-related .chart {
  height: 120px;
}
.game-related .dialog {
  max-width: 80vw;
}
.game-related .count {
  font-size: 0.6em;
}
.game-related h2 {
  margin: 0 2px 10px 0;
}
.game-related .group {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 10px 2px;
  transition: all 200ms;
  cursor: pointer;
}
.game-related .group span {
  margin-left: 10px;
  font-weight: bold;
  cursor: pointer;
}
.game-related .group label {
  cursor: pointer;
}
.game-related .game-list .game .game-description::after {
  background: none;
}
.game-related .playlist-preview .pl-description::after {
  background: none;
}
.first-visit {
  padding: 40px;
}
.first-visit .first-visit-options {
  margin: 30px 0 50px 0;
}
.first-visit .first-visit-options .btn {
  float: left;
  margin-right: 10px;
}
.first-visit .eula {
  position: relative;
  margin-top: 20px;
  padding: 20px;
  background: #dedede;
  max-width: 1920px;
}
.first-visit .eula p {
  margin: 10px 0;
}
.first-visit .eula .btn {
  margin: 20px 0;
  position: relative;
}
.first-visit .eula .btn.accepted {
  background: #326432;
  color: white;
}
.first-visit .eula .btn .svg-inline--fa {
  padding-right: 5px;
}
.content .admin {
  padding: 1%;
}
.admin-create-featured-dialog {
  margin-top: 400px;
  padding: 20px;
  color: white;
}
.admin-create-featured-dialog .close-icon {
  font-size: 3em;
  position: fixed;
  right: 20px;
  top: 75px;
  color: white;
  z-index: 999;
}
.admin-create-featured-dialog .preview {
  position: fixed;
  top: 60px;
  left: 0;
  height: 400px;
  max-width: 100%;
  padding-left: 240px;
  z-index: 99;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .admin-create-featured-dialog .preview {
    top: 40px;
    padding-left: 0;
  }
}
.admin-create-featured-dialog form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.admin-create-featured-dialog form .form-group {
  width: 260px;
  box-sizing: border-box;
}
.admin-create-featured-dialog form .form-group .form-control {
  color: white;
  border-color: white;
}
.admin-create-featured-dialog form .form-group .form-control.slider input {
  color: white;
  border-color: white;
}
.admin-create-featured-dialog .well {
  padding: 10px;
  margin: 0;
  background: linear-gradient(22.5deg, #000038, #00a79d);
}
.admin-create-featured-dialog .dialog-icon {
  cursor: pointer;
}
.admin-create-featured-dialog .dialog-icon:hover {
  color: #AA0000;
}
.content .landing-background {
  height: calc(100vh - 40px);
  background: linear-gradient(22.5deg, #000038, #00a79d);
}
.content .landing {
  padding: 40px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.content .landing .landing-content {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  background: white;
  border-radius: 14px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
  max-width: 95vw;
}
.content .landing .landing-content h1 svg {
  margin-right: 0.2em;
  color: #00A79D;
}
.content .landing .landing-content .split {
  padding: 20px 20px;
}
.content .landing .landing-content .split.left {
  width: 300px;
  max-width: 90vw;
}
.content .landing .landing-content .more-info {
  margin-top: 40px;
}
.content .landing .landing-content .right {
  width: 350px;
  max-width: 75vw;
  padding-right: 30px;
  padding-bottom: 30px;
}
.content .landing .landing-content .right .menu-img-container {
  width: 100%;
  position: relative;
}
.content .landing .landing-content .right .menu-img-container .menu-img {
  width: 100%;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
.content .landing .landing-content .right .menu-img-container .playlist-wrapper {
  text-align: left;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -30px;
  right: -30px;
}
.content .landing .landing-content .right .menu-img-container .playlist-wrapper .plus-icon {
  background: #00A79D;
  border-radius: 100%;
  position: absolute;
  bottom: -20px;
  left: -20px;
  padding: 15px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  color: white;
}
.content .landing .landing-content .right .menu-img-container .playlist-wrapper .playlist-preview {
  float: none;
  width: initial;
  cursor: initial;
}
.content .landing .landing-content .right .menu-img-container .playlist-wrapper .playlist-preview .icon {
  border: 4px solid #00A79D;
}
.content .landing .landing-content .btn {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .content .landing-background {
    height: calc(100vh - 65px);
  }
  .content .landing {
    padding: 40px;
  }
  .content .landing .landing-content .split {
    padding: 80px 40px;
  }
}
.super_sorter .dialog .dialog-icon {
  color: black;
  cursor: pointer;
}
.super_sorter .dialog .dialog-icon:hover {
  color: #AA0000;
}
.super_sorter .well {
  padding: 20px;
}
.super_sorter .well h2:after {
  content: "";
  display: block;
  margin: 20px 0;
  width: 12.5%;
  border-bottom: 1px solid black;
}
.super_sorter .form-preview {
  display: flex;
  justify-content: center;
  align-items: center;
}
.super_sorter .form-preview .super-sorter-preview {
  margin: 15px 0 15px 0;
}
.super_sorter .game-overview {
  display: flex;
  flex-wrap: wrap;
}
.super_sorter .game-overview .well {
  max-width: 660px;
}
.super_sorter .game-overview .well {
  min-width: 220px;
}
.super_sorter .grid2x2 {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  z-index: 9;
  width: 640px;
  height: 400px;
}
.super_sorter .grid2x2 > div {
  display: flex;
  flex-basis: calc(50%);
  justify-content: center;
  flex-direction: column;
  z-index: 9;
}
.super_sorter .grid2x2 > div > div {
  display: flex;
  justify-content: center;
  flex-direction: row;
  z-index: 11;
}
.super_sorter .grid2x2 .rotateX {
  transform: rotateX(180deg);
}
.super_sorter .grid2x2 .rotateY {
  transform: rotateY(180deg);
}
.super_sorter .grid2x2 .rotateZ {
  transform: rotate(180deg);
}
.super_sorter .grid2x2 .short,
.super_sorter .grid2x2 .long {
  left: 13px;
  top: 16px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 11;
}
.super_sorter .grid2x2 .short .invisible-filler-container,
.super_sorter .grid2x2 .long .invisible-filler-container {
  height: 28.8px;
  width: 40px;
}
.super_sorter .grid2x2 .short {
  height: 165px;
  flex-direction: column;
}
.super_sorter .grid2x2 .short .super-sorter-preview {
  transform: rotate(-90deg);
}
.super_sorter .grid2x2 .long {
  width: 233px;
  flex-direction: row;
}
.super_sorter .grid2x2 .element {
  bottom: 20px;
}
.super_sorter .grid-image {
  position: absolute;
  z-index: 10;
}
.super_sorter .game-containers-preview {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.super_sorter .horizontalColors {
  display: flex;
  flex-wrap: nowrap;
}
.super_sorter .colorPicker-item {
  margin-left: 20px;
}
.super_sorter .super-sorter-settings-dialog form {
  max-width: 250px;
}
.super_sorter .super-sorter-settings-dialog form .filler {
  height: 43px;
}
.super_sorter .small-background {
  position: absolute;
  width: 640px;
  height: 400px;
}
.super_sorter .small-background img {
  width: 100%;
}
.super_sorter .super-sorter-game-preview {
  position: relative;
  transform-origin: top left;
  height: 400px;
  background-color: black;
}
.super_sorter .super-sorter-game-preview img.medal {
  position: absolute;
  width: 30px;
  top: 110px;
  left: 176px;
}
.super_sorter .super-sorter-game-preview .element-pos {
  position: absolute;
  left: 241px;
  top: 100px;
  width: 155px;
  display: flex;
  justify-content: center;
}
.super_sorter .super-sorter-game-preview .container-pos {
  position: absolute;
  left: 70px;
  bottom: 0;
  padding: 10px;
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  box-sizing: border-box;
  max-height: 80px;
}
.super_sorter .overflow {
  overflow: hidden;
}
.super_sorter .player-count-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.super_sorter .player-count-buttons .btn {
  min-width: 50px;
  background: #e6e6e6;
}
.super_sorter .player-count-buttons .btn.active {
  background: #00A79D;
  color: white;
}
.super_sorter .super-sorter-game-previews {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.super_sorter .super-sorter-element-dialog .split-content {
  display: flex;
}
.super_sorter .super-sorter-element-dialog .split-content .left {
  margin-right: 20px;
}
.super_sorter .super-sorter-element-dialog form {
  max-width: 250px;
}
.super_sorter .super-sorter-element-dialog .container-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.super_sorter .super-sorter-element-dialog .container-container .container-wrapper {
  margin: 0 40px 20px 0;
}
.super_sorter .super-sorter-element-dialog .container-container .new-container-btn {
  cursor: pointer;
  width: 100px;
  height: 100px;
  font-size: 50px;
  line-height: 96px;
  border: 2px dashed white;
  border-radius: 100%;
  text-align: center;
  box-sizing: border-box;
  transition: color 200ms, border-color 200ms;
}
.super_sorter .super-sorter-element-dialog .container-container .new-container-btn:hover {
  border-width: 3px;
  line-height: 94px;
  color: #f48120;
  border-color: #f48120;
}
.super_sorter .control {
  position: relative;
}
.super_sorter .control .edit {
  margin-top: 80px;
  cursor: pointer;
  background: black;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 7px;
  border-radius: 3px;
  z-index: 99;
}
.super_sorter .frame-custom-background-wrapper {
  position: absolute;
  overflow: hidden;
}
.super_sorter .frame-custom-background {
  width: 1200px;
  height: 800px;
  position: absolute;
}
.super_sorter .container,
.super_sorter .element {
  cursor: default;
  position: relative;
  box-sizing: content-box;
  transform-origin: top left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.super_sorter .container.Circle .frame-container,
.super_sorter .element.Circle .frame-container {
  border-radius: 100%;
}
.super_sorter .container.Circle .frame-container img,
.super_sorter .element.Circle .frame-container img {
  border-radius: 100%;
  width: 120%;
  height: 120%;
}
.super_sorter .container.Circle .img-container,
.super_sorter .element.Circle .img-container {
  border-radius: 100%;
}
.super_sorter .container.Rectangle,
.super_sorter .element.Rectangle,
.super_sorter .container.None,
.super_sorter .element.None,
.super_sorter .container.Square,
.super_sorter .element.Square {
  border-radius: 0;
}
.super_sorter .container.Rectangle .text-container,
.super_sorter .element.Rectangle .text-container,
.super_sorter .container.None .text-container,
.super_sorter .element.None .text-container,
.super_sorter .container.Square .text-container,
.super_sorter .element.Square .text-container {
  padding: 10px;
}
.super_sorter .container.Rectangle .text-container .text,
.super_sorter .element.Rectangle .text-container .text,
.super_sorter .container.None .text-container .text,
.super_sorter .element.None .text-container .text,
.super_sorter .container.Square .text-container .text,
.super_sorter .element.Square .text-container .text {
  white-space: pre-wrap;
}
.super_sorter .container .frame-container,
.super_sorter .element .frame-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  overflow: visible;
}
.super_sorter .container .frame-container img,
.super_sorter .element .frame-container img {
  width: 110%;
  height: 110%;
}
.super_sorter .container .img-container,
.super_sorter .element .img-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.super_sorter .container img,
.super_sorter .element img {
  transform-origin: center;
  position: absolute;
  overflow: visible;
  width: 100%;
  height: 100%;
}
.super_sorter .container.clickable,
.super_sorter .element.clickable {
  cursor: pointer;
}
.super_sorter .container .text-container,
.super_sorter .element .text-container {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  vertical-align: middle;
  align-items: center;
  width: 100%;
  padding: 8%;
  box-sizing: border-box;
}
.super_sorter .container .text-container .myfloor-text,
.super_sorter .element .text-container .myfloor-text {
  width: initial;
}
.super_sorter .element {
  box-sizing: border-box;
  width: 220px;
  height: 220px;
}
.super_sorter .element.Rectangle,
.super_sorter .element.None {
  width: 300px;
  height: 176px;
}
.super_sorter .element.None {
  border-width: 0;
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
}
.super_sorter .container {
  box-sizing: content-box;
  width: 96px;
  height: 96px;
}
.super_sorter .container.Rectangle {
  width: 112px;
  height: 74px;
}
.super_sorter .container.Rectangle.selected .frame-container {
  box-shadow: 9px 9px 20px 0 yellow;
}
.super_sorter .container.Square {
  width: 96px;
  height: 96px;
  align-self: center;
}
.super_sorter .container.Square .frame-container img {
  width: 125%;
  height: 125%;
}
.super_sorter .container.Square.selected .frame-container {
  box-shadow: 9px 9px 17px 5px yellow;
}
.super_sorter .container.None {
  width: 112px;
  height: 74px;
  border-width: 0;
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
}
.super_sorter .container.None.selected .frame-container {
  box-shadow: 5px 9px 17px 0 yellow;
}
.super_sorter .container.Circle.selected .frame-container {
  box-shadow: 9px 5px 15px 2px yellow;
}
.super_sorter .element-container {
  display: flex;
  flex-wrap: wrap;
}
.super_sorter .element-container .super-sorter-preview {
  margin: 20px;
}
.ordering-game .ordering-element-preview {
  position: relative;
  display: flex;
  justify-content: center;
}
.ordering-game .ordering-element-preview .myfloor-text {
  text-align: center;
  overflow-wrap: break-word;
}
.ordering-game .ordering-element-preview .divider-label {
  background-color: black;
  color: white;
  position: absolute;
  margin-top: 310px;
  padding: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.ordering-game .ordering-element-preview .ordering-timeline-item {
  background-size: cover;
  background-repeat: no-repeat;
  mask-size: 100% 100%;
  position: absolute;
}
.ordering-game .ordering-element-preview .ordering-timeline-item.rect {
  width: 361px;
}
.ordering-game .ordering-element-preview .ordering-timeline-item .ordering-timeline-content-container {
  height: 241px;
  width: 100%;
  position: relative;
}
.ordering-game .ordering-element-preview .ordering-timeline-item .ordering-image-pos {
  width: 100%;
  position: absolute;
}
.ordering-game .ordering-element-preview .ordering-timeline-item .myfloor-text {
  position: absolute;
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  width: 100%;
  height: 100%;
}
.ordering-game .ordering-element-preview .ordering-timeline-item.clickable:hover {
  transform-origin: bottom center;
  scale: 1.2;
  z-index: 1;
}
.ordering-game .ordering-element-preview .ordering-timeline-item.clickable:hover .ordering-divider-label {
  scale: 1.2;
}
.ordering-game .ordering-arrow-timeline {
  height: 11px;
  width: 719px;
  margin-top: -6px;
  margin-bottom: 6px;
}
.ordering-game .ordering-spans {
  display: flex;
  width: 720px;
}
.ordering-game .ordering-space {
  margin: 20px 0px;
}
.ordering-game .ordering-settings-text {
  margin: 10px 0px 20px 10px;
  color: #c9c9c9;
}
.ordering-game .ordering-span {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  line-height: 30px;
  align-items: flex-start;
  flex-direction: column;
}
.ordering-game .ordering-span.empty {
  font-size: 20px;
  font-weight: bold;
  border: 1px dashed;
  border-radius: 15px;
}
.ordering-game .ordering-span.empty:hover {
  color: hsl(147, 100%, 27%);
  border-color: hsl(147, 100%, 27%);
  overflow: visible;
}
.ordering-game .ordering-span.invisible-opacity {
  opacity: 0.2;
}
.ordering-game .ordering-span.clickable:hover {
  border-bottom: 1px dashed #b1b1b1;
  border-left: 1px dashed #b1b1b1;
  border-right: 1px dashed #b1b1b1;
  border-radius: 0px 0px 15px 15px;
  margin-bottom: -1px;
}
.ordering-game .ordering-span .myfloor-text {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}
.ordering-game .flat-span {
  width: 100%;
  height: 4px;
  flex-shrink: 0;
}
.ordering-game .full-width {
  width: 100%;
}
.ordering-game .ordering-timeline {
  display: flex;
  width: 720px;
  margin-top: 20px;
}
.ordering-game .flex-gap-2em {
  gap: 2em;
}
.ordering-game .dialog.settings .ordering-game-preview img {
  max-width: none;
}
.ordering-game .exit-image {
  position: absolute;
}
.ordering-game .ordering-loading-image {
  width: 1280px;
  height: 800px;
  position: absolute;
}
.ordering-game .ordering-game-preview {
  width: 1280px;
  height: 800px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transform-origin: top left;
}
.ordering-game .ordering-game-preview img {
  max-width: none;
}
.ordering-game .ordering-game-preview .ordering-span.invisible-opacity {
  opacity: 0;
}
.ordering-game .ordering-game-preview .timeline {
  position: absolute;
  transform-origin: center center;
}
.ordering-game .ordering-game-preview .timeline.preview-bottom {
  bottom: -1px;
}
.ordering-game .ordering-game-preview .timeline.preview-top {
  top: -1px;
  rotate: 180deg;
}
.ordering-game .ordering-game-preview .timeline.preview-left {
  left: -484px;
  top: 0px;
  bottom: 0px;
  rotate: 90deg;
}
.ordering-game .ordering-game-preview .timeline.preview-right {
  right: -484px;
  top: 0px;
  bottom: 0px;
  rotate: -90deg;
}
@keyframes bob {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes rotate {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(2);
  }
  60% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
.ordering-game .always-scroll {
  height: calc(100vh - 64px);
  box-sizing: border-box;
}
.ordering-game .ordering-zoom-area {
  transform-origin: top left;
  display: inline-block;
}
.ordering-game .ordering-zoom-area .index-chevron {
  margin-bottom: 20px;
  animation-name: bob;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
.ordering-game .ordering-button-container {
  display: flex;
  align-items: flex-end;
  transform-origin: top left;
}
.ordering-game .ordering-button-container .ordering-new-span-button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px dashed black;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ordering-game .ordering-button-container .ordering-new-span-button:hover {
  border-color: green;
  margin: 1px;
  border-width: 2px;
  color: green;
  font-weight: bold;
}
.ordering-game .ordering-button-container.non-interactable .timeline-main-container .sortable {
  cursor: default;
}
.ordering-game .ordering-button-container.non-interactable .ordering-new-span-button {
  cursor: default;
}
.ordering-game .ordering-button-container.non-interactable .ordering-new-span-button:hover {
  margin: 2px;
  border: 1px dashed black;
  color: initial;
  font-weight: initial;
}
.ordering-game .ordering-button-container.non-interactable .clickable:hover {
  scale: 1;
  border: none;
  margin-bottom: initial;
}
.ordering-game .ordering-button-container.non-interactable .clickable:hover .ordering-divider-label {
  scale: 1;
}
.ordering-game .hover-msg .ordering-element-preview {
  margin-top: 10px;
}
.ordering-game .hover-msg .ordering-span {
  margin-top: 10px;
  width: 200px;
}
.ordering-game .order-span-sort {
  box-sizing: border-box;
  padding: 2px;
}
.ordering-game .ordering-timeline-content-area {
  position: relative;
  padding-top: 10px;
  width: 91%;
  padding-left: 13px;
  height: 207px;
  box-sizing: border-box;
}
.ordering-game .ordering-timeline-content-area.rect {
  padding-left: 10px;
}
.ordering-game .ordering-overlap {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.ordering-game .ordering-divider-label {
  margin-top: 230px;
  background-color: white;
  color: black;
  z-index: 1000;
  font-size: 30px;
  border: 2px dashed black;
  border-radius: 130px;
  height: 130px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ordering-game .span-hover-msg {
  bottom: 0px;
  margin-bottom: 34px;
  transform-origin: bottom left;
  scale: 0.5;
}
.ordering-game .margin-bottom {
  margin-bottom: 2em;
}
.ordering-game .ordering-element-form .flex {
  gap: 20px;
}
.ordering-game .ordering-element-form .column {
  min-width: 250px;
}
.ordering-game .ordering-element-form .column.preview {
  width: 410px;
  display: flex;
  justify-content: center;
}
.ordering-game .preview-column {
  width: 510px;
  height: 319px;
}
.ordering-game .flex.gap-20 {
  gap: 20px;
}
.ordering-game .ordering-span-form .ordering-span {
  overflow-y: hidden;
}
.ordering-game .item-selector-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.ordering-game .timeline-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.ordering-game .timeline-main-container .sortable {
  cursor: pointer;
}
.ordering-game .span-explainer-text {
  font-size: 20px;
  margin-bottom: 1em;
}
.ordering-game .span-explainer-text .inline-circle-plus {
  border: 1px dashed black;
  border-radius: 15px;
  padding: 0px 7px;
  margin: 5px;
}
.ordering-game .span-explainer-text.margin-bottom {
  margin-bottom: 2em;
}
.demo-menu-index {
  padding: 40px;
}
.demo-menu-index .item-form {
  min-width: 900px;
}
.demo-menu-index .item-form .previews {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 420px;
}
.demo-menu-index .item-form .previews .popup-preview {
  margin-left: 20px;
  white-space: pre-wrap;
}
.demo-menu-index .item-form form {
  display: flex;
  flex-wrap: wrap;
}
.demo-menu-index .item-form form .form-group {
  width: 250px;
  margin-right: 20px;
}
.demo-menu-index .item-form form .form-group label {
  height: unset;
  line-height: unset;
}
.demo-menu-index .item-form form h2 {
  width: 100%;
}
.demo-menu-index .item-form form .part {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 420px;
}
.container .demo-wall-preview {
  width: 640px;
  margin: 0 20px 20px 0;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
.container .demo-wall-preview img.background {
  width: 100%;
}
.popup-preview {
  position: relative;
}
.popup-preview img {
  width: 100%;
}
.popup-preview .text-box {
  position: absolute;
  background-color: rgba(100, 0, 0, 0.7);
  border: 1px solid red;
  box-sizing: border-box;
}
.demo-wall-preview {
  width: 1280px;
}
.demo-wall-preview .preview.abs {
  position: relative;
}
.demo-wall-preview .preview.abs img.background {
  width: 100%;
}
.demo-wall-preview .preview.abs .item {
  position: absolute;
}
.demo-wall-preview .preview.abs .item.temp {
  border: 1px solid red;
  box-sizing: border-box;
  background-color: rgba(100, 0, 0, 0.7);
}
.demo-wall-preview .preview.abs .item img {
  width: 100%;
}
.demo-wall-preview .preview.abs .item .trash-icon {
  position: absolute;
  bottom: 2px;
  right: 2px;
}
.demo-wall-preview .preview.abs .item .trash-icon:hover {
  cursor: pointer;
  color: #AA0000;
}
.demo-wall-preview .preview.abs .item .edit-icon {
  position: absolute;
  bottom: 2px;
  right: 20px;
}
.demo-wall-preview .preview.abs .item .edit-icon:hover {
  cursor: pointer;
  color: #00c736;
}
.floor-index .floor-picker .form-group .form-control {
  border-color: black;
  color: black;
}
.floor-form-dialog form .flex {
  display: flex;
  flex-direction: row;
}
.floor-form-dialog form .flex .left {
  margin-right: 10px;
}
.floor-form-dialog p {
  max-width: 600px;
}
.floor-form-dialog .form-area {
  margin-bottom: 10px;
}
.floor-form-dialog .form-area hr {
  margin: 20px 0;
}
.floor-form-dialog .form-area p {
  max-width: 230px;
  font-size: 0.8em;
}
.floor-form-dialog h3 {
  font-weight: bold;
}
.floor-form-dialog form .form-group {
  width: 250px;
}
.floor-form-dialog .admin-reseller-create {
  background: #652d90;
  margin: 20px 0 40px 0;
  padding: 10px;
  border-radius: 4px;
}
table.invoice-table thead th,
table.styled-table thead th {
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid black;
  padding-right: 10px;
}
table.invoice-table thead th.sortable,
table.styled-table thead th.sortable {
  cursor: pointer;
}
table.invoice-table thead th .form-control,
table.styled-table thead th .form-control {
  color: black;
  border-color: black;
}
table.invoice-table tbody .truncate,
table.styled-table tbody .truncate {
  max-width: 150px;
}
table.invoice-table tbody tr:nth-child(even),
table.styled-table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.2);
}
table.invoice-table tbody tr:nth-child(even).reseller,
table.styled-table tbody tr:nth-child(even).reseller {
  background-color: #F48120F2;
}
table.invoice-table tbody tr:nth-child(odd),
table.styled-table tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0);
}
table.invoice-table tbody tr:nth-child(odd).reseller,
table.styled-table tbody tr:nth-child(odd).reseller {
  background-color: #F48120F2;
}
table.invoice-table tbody tr:hover,
table.styled-table tbody tr:hover {
  background-color: #00ADEEF2;
}
table.invoice-table tbody tr td,
table.styled-table tbody tr td {
  text-align: left;
  cursor: default;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 1px 2px 1px 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
table.invoice-table tbody tr td .btn,
table.styled-table tbody tr td .btn {
  padding: 2px 6px;
}
table.invoice-main tr:nth-child(even),
table.invoice-lines tr:nth-child(even),
.invoice-generate-table tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.2);
}
table.invoice-main tr:nth-child(even).reseller,
table.invoice-lines tr:nth-child(even).reseller,
.invoice-generate-table tr:nth-child(even).reseller {
  background-color: #F48120F2;
}
table.invoice-main tr:nth-child(odd),
table.invoice-lines tr:nth-child(odd),
.invoice-generate-table tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0);
}
table.invoice-main tr:nth-child(odd).reseller,
table.invoice-lines tr:nth-child(odd).reseller,
.invoice-generate-table tr:nth-child(odd).reseller {
  background-color: #F48120F2;
}
table.invoice-main th,
table.invoice-lines th,
.invoice-generate-table th {
  font-weight: bold;
  padding: 2px 7px 2px 0;
  text-align: left;
}
table.invoice-main td.pointer,
table.invoice-lines td.pointer,
.invoice-generate-table td.pointer {
  cursor: pointer;
}
table.invoice-lines td {
  padding: 3px 10px 3px 0;
}
table.invoice-lines td .line-text-field.form-group {
  max-width: initial;
}
table.invoice-lines td .line-text-field.form-group .form-control {
  width: 500px;
}
table.invoice-lines td.trash {
  cursor: pointer;
}
table.invoice-lines td.trash:hover {
  color: #AA0000;
}
table.invoice-lines thead tr {
  border-bottom: 1px solid white;
}
table.invoice-lines tbody td.bold {
  font-weight: bold;
}
.dialog.invoice-details h3 {
  margin-top: 10px;
  font-size: 1.2em;
  text-decoration: underline;
}
table.invoice-generate-table {
  cursor: default;
}
table.invoice-generate-table tbody tr:hover {
  cursor: pointer;
  background-color: #00ADEEF2;
}
table.invoice-generate-table tbody tr td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 2px 10px 2px 2px;
}
table.invoice-generate-table tbody tr td .btn {
  padding: 2px 6px;
}
.invoice-generate p {
  max-width: 400px;
  padding: 5px 0;
}
.economic-settings-form .form-group textarea.form-control {
  min-width: 500px;
  min-height: 60vh;
}
.dialog .invoice-container .invoice-lines {
  margin-bottom: 40px;
}
.invoice-predict .dialog-top {
  min-height: 220px;
}
.geekster-game-preview img {
  width: 400px;
  height: 250px;
}
.geekster-task {
  width: 400px;
  height: 250px;
}
.geekster-task .youtube-preview .youtube-text {
  font-size: 1em;
  line-height: 1.2em;
}
.drums {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button {
  margin: 70px;
  width: 140px;
  height: 50px;
  color: black;
  background-color: grey;
}
.painter .item-container .canvas-preview,
.painter .item-container .color-preview {
  transition: linear 200ms;
}
.painter .item-container .canvas-preview.clickable,
.painter .item-container .color-preview.clickable {
  cursor: pointer;
}
.painter .item-container .canvas-preview.clickable:hover,
.painter .item-container .color-preview.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.painter .dialog-icon :hover {
  color: #AA0000;
}
.painter .item-container .list .canvas-preview {
  margin: 15px;
}
.painter .item-container .list .color-preview {
  margin: 5px;
}
.painter .color-preview {
  width: 121px;
  height: 71px;
  display: inline-block;
  position: relative;
}
.painter .color-preview .image-overlay,
.painter .color-preview .canvas-color {
  position: absolute;
  width: 100%;
  height: 100%;
}
.painter .canvas-preview {
  width: 330px;
  height: 285px;
  display: flex;
}
.painter .canvas-preview .easel,
.painter .canvas-preview .frame,
.painter .canvas-preview .image {
  position: absolute;
}
.painter .canvas-preview .canvas {
  width: 338px;
  height: 291px;
}
.painter .canvas-preview .image {
  margin: 8px;
  width: 320px;
  height: 200px;
}
.painter .canvas-preview .image.black {
  background-color: black;
}
.painter .paint-game-preview {
  width: 416px;
  height: 260px;
  position: relative;
}
.painter .paint-game-preview .background-image {
  position: absolute;
  width: 100%;
  height: 100%;
}
.painter .paint-game-preview .canvasimage {
  position: absolute;
  width: 297px;
  height: 186px;
  left: 53px;
  top: 33px;
}
.painter .paint-game-preview .game-color-flex {
  display: flex;
  flex-flow: column;
  margin-left: 371px;
}
.painter .paint-game-preview .game-color-flex .color-preview {
  width: 35.2px;
  height: 22px;
  margin-bottom: 5px;
}
.painter .paint-game-preview .game-color-flex .marginTop {
  margin-top: 33px;
}
.deal-index .index-table th {
  cursor: pointer;
}
.deal-index .index-table th .form-group {
  max-width: none;
}
.deal-index .index-table th .form-group input,
.deal-index .index-table th .form-group select {
  width: 100%;
  padding: 0.1em;
  height: 1.3em;
}
.deal-index table tr td {
  max-width: 150px;
  font-size: 0.85em;
}
.deal-index table tr.Pending td {
  background-color: rgba(0, 0, 255, 0.2);
}
.deal-index table tr.Rejected td,
.deal-index table tr.Cancelled td {
  background-color: rgba(255, 0, 0, 0.2);
}
.deal-index table tr.Approved td {
  background-color: rgba(0, 255, 0, 0.2);
}
.deal-index {
  padding: 20px;
}
.deal-form h1 {
  margin-bottom: 20px;
  font-weight: bold;
}
.deal-form .form-division {
  margin-top: 85px;
}
.system-settings-dialog p {
  line-height: 1.5em;
  margin-bottom: 0.5em;
}
.system-settings-dialog p .sub-clue {
  padding: 2px;
  background: #1e1e1e;
  background-clip: padding-box;
  border: 1px solid grey;
  border-radius: 3px;
}
.deal-details h1 span {
  font-size: 0.5em;
}
.deal-details h3 {
  font-weight: bold;
  margin-bottom: 0;
}
.deal-details p {
  max-width: 500px;
}
.deal-details p.unit-count {
  font-style: italic;
  font-weight: normal;
  margin-bottom: 1em;
}
.deal-details p.unit-count span {
  font-style: normal;
  font-size: 0.7em;
}
.deal-details p.comments {
  margin-bottom: 1em;
}
.deal-details .info-group,
.deal-details .info-group-table {
  margin-bottom: 0.5em;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  padding: 5px;
  max-width: 500px;
}
.deal-details .info-group h3,
.deal-details .info-group-table h3 {
  display: inline-block;
}
.deal-details .info-group h3:after,
.deal-details .info-group-table h3:after {
  content: "";
  display: block;
  width: 75%;
  border-top: 1px solid white;
  margin: 2px 0 4px 0;
}
.deal-details .info-group .author-preview,
.deal-details .info-group-table .author-preview {
  float: none;
  max-height: 100px;
}
.deal-details .info-group .author-preview .author-name,
.deal-details .info-group-table .author-preview .author-name {
  color: white;
}
.deal-details .info-group-table {
  max-width: unset;
}
.deal-details .info-group-table .styled-table thead th {
  border-color: white;
}
.item-icon {
  box-sizing: border-box;
  position: relative;
  width: 220px;
}
.item-icon:hover {
  z-index: 1;
}
.item-icon .badge-container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.item-icon .badge-container .expired-label {
  margin: 0 0 -2px -1px;
  position: relative;
  width: 110px;
}
.item-icon .badge-container .expired-label img {
  width: 100%;
}
.item-icon .badge-container .expired-label .text {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  bottom: 0;
  right: 0;
  width: 70%;
  padding: 3%;
  box-sizing: border-box;
  color: white;
  font-weight: bold;
  font-size: 9pt;
  text-align: center;
  text-shadow: 0 0 2px #000000;
}
.item-icon .badge-container .expired-label .text .hover-msg {
  top: -45px;
  left: -31px;
  font-size: 10pt;
  width: 200px;
  text-shadow: none;
}
.item-icon .badge-container .badge {
  padding: 5px;
  font-size: 0.8em;
  color: black;
  font-weight: bold;
}
.item-icon .badge-container .floor-badge {
  background: rgba(210, 255, 210, 0.8);
}
.item-icon .badge-container .new-badge {
  background: rgba(255, 243, 220, 0.8);
}
.item-icon .badge-container .update-badge {
  background: rgba(233, 205, 255, 0.8);
}
.item-icon [dir="rtl"] .author-preview .hover-msg,
.item-icon [dir="rtl"] .playlist-length .hover-msg {
  left: 0;
  right: initial;
}
.item-icon .author-preview {
  margin-top: 5px;
  width: 21px;
  height: 21px;
  padding: 0;
}
.item-icon .author-preview .profile-img {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
.item-icon .author-preview .hover-msg {
  right: 0;
  left: initial;
}
.item-icon .playlist-meta {
  display: flex;
  gap: 3px;
}
.item-icon .playlist-length {
  margin-top: 5px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  text-align: center;
  font-size: 0.75em;
  line-height: 21px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  font-family: "Lato";
  color: black;
  background: white;
}
.item-icon .playlist-length .bold {
  font-weight: bold;
}
.item-icon .playlist-length .hover-msg {
  line-height: 1.2em;
  white-space: nowrap;
  right: 0;
  left: initial;
}
.item-icon.clickable {
  cursor: pointer;
  transition: transform 100ms;
}
.item-icon.clickable:hover {
  transform: scale(1.03);
}
.item-icon .draft {
  width: 100%;
  height: 100%;
}
.item-icon .draft-label {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to right, #AA0000F2, rgba(0, 0, 0, 0));
  color: white;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}
.item-icon .draft-label .draft-title {
  font-weight: bold;
  font-size: 1.3em;
}
.item-icon .draft-label .draft-text {
  font-size: 0.8em;
  font-style: italic;
}
.item-icon .publish-label,
.item-icon .public-label,
.item-icon .draft-label-2 {
  position: absolute;
  top: 0;
  padding: 0.2em;
  font-size: 0.62em;
  font-weight: bold;
  color: #FFF;
  width: 35%;
  right: 0;
  text-align: center;
}
.item-icon .public-label {
  background-color: rgba(0, 200, 0, 0.8);
}
.item-icon .publish-label {
  background-color: rgba(175, 200, 0, 0.8);
}
.item-icon .draft-label-2 {
  background-color: rgba(200, 0, 0, 0.8);
}
.item-icon .icon {
  transform-origin: top left;
  width: 220px;
  height: 150px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}
.item-icon .icon .icon {
  box-shadow: none;
}
.item-icon .icon img {
  width: 100%;
}
.item-icon .icon .icon-title {
  position: absolute;
  top: 0;
  padding: 10px 5px;
  box-sizing: border-box;
}
.item-icon .icon .icon-label {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50%;
}
.item-icon .icon .gicon {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.item-icon .pl-image {
  vertical-align: bottom;
}
.item-icon .title {
  max-width: calc(100% - 25px);
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-icon .taglist {
  max-height: 2.4em;
  position: relative;
}
.item-icon .taglist .tag {
  line-height: initial;
  font-size: 0.7em;
  padding: 3px;
}
.item-icon .taglist .tag.language .hover-msg {
  position: fixed;
  right: initial;
  left: initial;
}
.item-icon .text-wrapper .description {
  overflow: hidden;
  font-size: 1rem;
  height: 3rem;
  white-space: pre-wrap;
  mask-image: linear-gradient(180deg, #000, transparent);
}
.item-icon .grid-cover {
  display: flex;
  flex-wrap: wrap;
  height: 150px;
  align-content: flex-start;
}
.item-icon .grid-cover .grid-item {
  width: calc(100% * 0.3333);
  height: calc(100% * 0.3333);
}
.item-icon .grid-cover .grid-item .item-icon {
  cursor: inherit;
  transform-origin: top left;
  transform: scale(0.3333);
  width: 220px;
}
.item-icon .grid-cover .grid-item .item-icon:hover {
  transform: scale(0.3333);
}
.item-icon .hover-msg .taglist {
  max-height: initial;
}
.list .item-icon .title {
  max-width: calc(100% - 50px);
}
/*RTL FIXES*/
.playlist-explorer .item-container[dir="rtl"] .icon {
  transform-origin: top right;
}
[dir="rtl"] .item-icon .icon {
  transform-origin: top right;
}
[dir="rtl"] .item-icon .grid-cover .grid-item .item-icon {
  transform-origin: top right;
}
[dir="rtl"] .grid-cover .grid-item .item-icon {
  transform-origin: top right;
}
.presentation .slide-sortable {
  margin-bottom: 20px;
  border-right: 2px dashed black;
  height: 200px;
  width: 320px;
}
.presentation .slide-form {
  width: 950px;
  height: 100%;
}
.presentation .slide-form.flex {
  flex-wrap: wrap;
  justify-content: space-between;
}
.presentation .slide-form.flex .slide-preview-container {
  width: 640px;
  height: 400px;
}
.presentation .slide-form .slide-form-fields {
  max-width: 250px;
}
.presentation .slide-preview {
  width: 1280px;
  height: 800px;
  background-color: gray;
  position: relative;
  transform-origin: top left;
  overflow: hidden;
}
.presentation .slide-preview.floor .arrow-left {
  top: 730px;
}
.presentation .slide-preview.floor .arrow-right {
  top: 730px;
}
.presentation .slide-preview.floor .sound-icon {
  left: 616px;
  top: 730px;
}
.presentation .slide-preview.wall .arrow-left {
  top: 400px;
}
.presentation .slide-preview.wall .arrow-right {
  top: 400px;
}
.presentation .slide-preview.wall .sound-icon {
  left: 15px;
  top: 30px;
}
.presentation .slide-preview .background-image {
  width: 1280px;
  height: 800px;
  position: absolute;
}
.presentation .slide-preview .blank {
  background-color: grey;
  width: 1280px;
  height: 800px;
  position: absolute;
}
.presentation .slide-preview .arrow-left {
  position: absolute;
  left: 40px;
  z-index: 1;
}
.presentation .slide-preview .arrow-left.navigatable {
  cursor: pointer;
}
.presentation .slide-preview .arrow-right {
  position: absolute;
  left: 1230px;
  z-index: 10000;
}
.presentation .slide-preview .arrow-right.navigatable {
  cursor: pointer;
}
.presentation .slide-preview .exit-button {
  position: absolute;
  width: 55px;
  height: 55px;
  top: 30px;
  left: 1185px;
  z-index: 10000;
}
.presentation .slide-preview .sound-icon {
  position: absolute;
  z-index: 1;
}
.presentation .preview-container {
  width: 384px;
  height: 240px;
}
.presentation .addon-form {
  height: 100%;
}
.presentation .addon-form .gap {
  gap: 30px;
}
.presentation .addon-form .gap h5 {
  width: 250px;
}
.presentation .addon-form .activity-preview {
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
.presentation .addon-form .preview-size {
  height: 430px;
  width: 640px;
}
.presentation .addon-form .preview-information {
  height: 40px;
  width: 640px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}
.presentation .addon-form .preview-information h5 {
  margin-right: 20px;
  width: 25%;
}
.presentation .addon-form .button-margin {
  margin-right: 5px;
}
.presentation .addon-form svg {
  margin-right: 0em;
}
.presentation .addon-form .container-with-background {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3em;
  padding: 5px;
  width: 250px;
  box-sizing: border-box;
}
.presentation .addon-form .container-with-background .chosen-effects {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}
.presentation .addon-form .container-with-background .chosen-effects .btn {
  display: flex;
  gap: 5px;
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 3px;
}
.presentation .addon-form .container-with-background .form-group {
  margin-bottom: 0;
}
.presentation .addon-form .effect-controls-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.presentation .addon-form .effect-controls-container .effect-control {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  margin: 0 20px 20px 0;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.presentation .addon-form .effect-controls-container .effect-control .effect-name {
  display: flex;
  justify-content: space-between;
}
.presentation .addon-form .effect-controls-container .effect-control .color-group {
  background: unset;
}
.presentation .quiz-form {
  width: 890px;
}
.presentation .quiz-form .flex {
  gap: 30px;
}
.presentation .quiz-form .quiz-answers {
  display: flex;
  flex-direction: row;
  gap: 45px;
  margin-top: 20px;
}
.presentation .popup-form .activity-preview {
  transform-origin: top left;
}
.presentation .popup-form .activity-preview .activity-preview-inner {
  transform-origin: top left;
  padding: 10px;
}
.presentation .popup-form .activity-preview .activity-preview-inner .activity-sound {
  width: 50%;
  height: 50%;
}
.presentation .popup-form .activity-preview .activity-preview-inner .activity-sound img {
  width: 100%;
  height: 100%;
}
.presentation .popup-form .activity-preview .activity-preview-inner .activity-empty {
  width: 960px;
  height: 100%;
  padding: 3px;
}
.presentation .nav-bar {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  margin-bottom: 20px;
}
.presentation .addon-wrapper {
  position: absolute;
  transform-origin: top left;
}
.presentation .addon-wrapper.deemphasize {
  opacity: 0.5;
}
.presentation .addon-wrapper .addon-preview .text-center {
  width: 300px;
  height: 300px;
  display: table-cell;
  vertical-align: middle;
}
.presentation .addon-wrapper .addon-preview .myfloor-text {
  white-space: pre-wrap;
}
.presentation .addon-wrapper .addon-preview .swing {
  animation: swing-anim;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
.presentation .addon-wrapper .addon-preview .shadow {
  animation: shadow-anim alternate infinite var(--shadow-duration);
}
.presentation .addon-wrapper .addon-preview .fade {
  animation: fade-anim;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
.presentation .activities-list {
  width: 880px;
  margin-top: 20px;
}
.presentation .activities-list .list {
  gap: 3px;
}
.presentation .activities-list .list .activity-preview {
  min-height: 70px;
  min-width: 90px;
  cursor: pointer;
  position: relative;
}
.presentation .activities-list .list .activity-preview .activity-sound img {
  width: 40px;
}
.presentation .activities-list .list .activity-preview .label {
  padding: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.presentation .addon-list-item {
  width: 920px;
  margin-top: 20px;
  z-index: 2;
}
.presentation .addon-list-item .list {
  gap: 10px;
}
.presentation .addon-list-item .addon-collection {
  transform-origin: top left;
  position: relative;
}
.presentation .addon-list-item .addon-collection .activity-preview {
  position: absolute;
  width: 50px;
  height: 40px;
  top: 2px;
  left: 2px;
}
.presentation .addon-list-item .addon-collection .activity-preview .activity-preview-inner {
  transform-origin: top left;
}
.presentation .addon-list-item .addon-collection .activity-preview .activity-sound img {
  width: 90%;
  height: 90%;
}
.presentation .addon-list-item .addon-preview {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.presentation .addon-list-item .addon-preview .myfloor-text {
  white-space: pre-wrap;
  font-size: 8px !important;
}
.presentation .addon-list-item .addon-preview img {
  max-height: 100px;
  max-width: 100px;
  display: block;
}
.presentation .activity-container {
  position: absolute;
  border-radius: 40px;
  z-index: 101;
}
.presentation .activity-container .middle-position {
  position: absolute;
  top: 50px;
  left: 140px;
}
.presentation .popup-preview .inner-preview {
  width: 960px;
  transform-origin: top left;
  border-radius: 40px;
}
.presentation .popup-preview .inner-preview .background {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.presentation .popup-preview.background16x10 .inner-preview {
  height: 600px;
}
.presentation .popup-preview.background16x9 .inner-preview {
  height: 560px;
}
.presentation .popup-preview .popup-container {
  width: calc(100% - 70px);
  height: calc(100% - 66px);
  top: 33px;
  left: 35px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  mask-size: 100% 100%;
}
.presentation .popup-preview .popup-container.Default.ratio16x10 {
  mask-image: url("./images/games/presentation/Mask/Default16x10.png");
}
.presentation .popup-preview .popup-container.Default.ratio16x9 {
  mask-image: url("./images/games/presentation/Mask/Default16x9.png");
}
.presentation .popup-preview .popup-container .sound-icon {
  position: absolute;
  z-index: 104;
  left: 820px;
  top: 10px;
}
.presentation .popup-preview .popup-container .image {
  position: absolute;
  width: 100%;
}
.presentation .popup-preview .popup-container .myfloor-text {
  position: absolute;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 100%;
  overflow: hidden;
}
.presentation .small-activity-preview .popup-preview img {
  position: absolute;
}
.presentation .small-activity-preview .popup-preview .popup-container {
  scale: 1;
  position: relative;
}
.presentation .soundform-flex {
  height: 61px;
  display: flex;
  align-items: center;
}
.presentation .soundform-flex .activity-sound {
  margin-left: 10px;
  margin-top: -7px;
}
.presentation .soundicon {
  width: 100px;
  height: 100px;
  background-color: rgba(69, 69, 69);
  position: absolute;
  border-radius: 20px;
  top: 350px;
  left: 530px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.presentation .soundpreview-icon {
  width: 61px;
  height: 61px;
  background-color: '#1c4966';
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
.presentation .soundbutton {
  pointer-events: all;
  cursor: pointer;
}
.presentation .press-area {
  border: solid, black;
  background-color: red;
  opacity: 0.3;
  position: absolute;
  z-index: 101;
}
.presentation .quiz {
  width: 900px;
  height: 700px;
  transform-origin: top left;
}
.presentation .quiz .background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.presentation .quiz.quiz-softcorners {
  border-radius: 40px;
}
.presentation .quiz .sound-icon {
  position: absolute;
  z-index: 104;
  left: 760px;
  top: 65px;
}
.presentation .quiz .quiz-container {
  position: absolute;
  width: 900px;
  height: 700px;
}
.presentation .quiz .quiz-container .question {
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 70px 0px 70px;
  box-sizing: border-box;
  height: 66%;
}
.presentation .quiz .quiz-container .question .myfloor-text {
  max-height: 80%;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
}
.presentation .quiz .quiz-container .answers {
  height: 34%;
  display: flex;
  justify-content: space-between;
  padding: 0 67px 0 67px;
  box-sizing: border-box;
  gap: 10px;
}
.presentation .quiz .quiz-container .answers .answer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.3%;
  height: 165px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}
.presentation .quiz .quiz-container .answers .answer .front {
  z-index: 1;
}
.presentation .quiz .quiz-container .answers .answer .myfloor-text {
  width: 250px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.presentation .quiz .quiz-container .answers .answer.correct {
  background: rgba(113, 255, 102, 0.5);
}
.presentation .element-highlight-new-feature {
  width: 350px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: #ebf3f3;
  border-radius: 5px;
  padding: 5px;
  gap: 15px;
}
.presentation .element-highlight-new-feature .btn {
  width: fit-content;
  padding: 5px;
}
.presentation .element-highlight-new-feature .hover-msg {
  min-width: 100px;
  bottom: 90%;
  left: 100%;
}
/* RTL FIX */
.presentation [dir="rtl"] .popup-preview .inner-preview,
.presentation [dir="rtl"] .quiz,
.presentation [dir="rtl"] .slide-preview {
  transform-origin: top right;
}
.convert-dialog {
  width: fit-content;
}
.convert-dialog.game-chosen {
  width: 90vw;
}
.quiz-converter .label {
  margin-bottom: -0.09em;
}
.quiz-converter .global-settings {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.quiz-converter .global-settings .skin-and-categories {
  max-width: 503px;
}
.quiz-converter .global-settings .categories {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}
.quiz-converter .global-settings .categories .hidden {
  visibility: hidden;
  position: absolute;
}
.quiz-converter .text-style-modifiers-horizontal {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 5px;
  width: fit-content;
}
.quiz-converter .text-style-modifier-with-label {
  display: flex;
  flex-direction: column;
  max-width: calc(33% - 5px);
  min-width: 225px;
  margin-bottom: 0.2em;
}
.quiz-converter .text-style-modifier-with-label .text-style-modifier {
  max-width: unset;
  min-width: unset;
  margin-bottom: unset;
}
.quiz-converter .text-style-modifier {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.2em;
  display: flex;
  width: fit-content;
  max-width: calc(33% - 5px);
  min-width: 225px;
  margin-bottom: 0.2em;
}
.quiz-converter .text-style-modifier .form-group {
  margin-bottom: 0;
  max-width: initial;
}
.quiz-converter .text-style-modifier .form-group .chrome-picker {
  left: -35px;
}
.quiz-converter .text-style-modifier .form-group .form-control {
  border: none;
  width: initial;
}
.quiz-converter .text-style-modifier .form-group .form-control.slider {
  padding: 0.1em;
}
.quiz-converter .text-style-modifier .form-group .form-control.slider .number {
  width: 30%;
}
.quiz-converter .text-style-modifier .form-group .form-control.slider input {
  width: 70%;
}
.quiz-converter .text-style-modifier .modifier.hover-msg {
  top: 2.5em;
  left: 2em;
  max-width: 240px;
  z-index: 99;
  word-wrap: break-word;
}
.quiz-converter .main-text-style-modifiers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}
.quiz-converter .quiz-text-style-modifiers {
  row-gap: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.quiz-converter .items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.quiz-converter .items .convert-item {
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  width: fit-content;
  box-sizing: border-box;
}
.quiz-converter .items .convert-item .item-category {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.quiz-converter .items .convert-item .item-category .select-category .form-group {
  width: 200px;
}
.quiz-converter .items .convert-item .item-category .select-category .form-group .checkbox-container {
  height: 2em;
}
.quiz-converter .items .convert-item .item-category .select-category .form-group .checkbox-container .checkbox-slide {
  margin-top: 0;
}
.quiz-converter .items .convert-item .item-category .select-category .form-group .checkbox-container .checkbox-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 156px;
}
.popit-content .pop-element-preview-wrapper {
  width: 250px;
  height: 250px;
  padding: 20px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popit-content .pop-element-preview-wrapper.preview-with-sound-icon {
  position: relative;
}
.popit-content .pop-element-preview-wrapper.preview-with-sound-icon .pop-element-sound-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
.popit-content .pop-element-preview-wrapper.preview-with-sound-icon .pop-element-sound-icon svg {
  height: 2em;
}
.popit-content .pop-element-preview-wrapper.clickable {
  cursor: pointer;
}
.popit-content .pop-element-preview-wrapper.clickable:hover {
  filter: drop-shadow(0 0 5px black);
}
.popit-content .pop-element-preview-wrapper .pop-element {
  max-width: 250px;
  max-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popit-content .pop-element-preview-wrapper .pop-element .myfloor-text {
  overflow-wrap: break-word;
  overflow: hidden;
  width: fit-content;
  max-height: 250px;
  max-width: 250px;
  white-space: pre-wrap;
}
.popit-content .pop-element-preview-wrapper .pop-element .invisible {
  max-width: 250px;
  max-height: 250px;
}
.popit-content .pop-element-scale-wrapper {
  width: 145px;
  height: 145px;
}
.popit-content .pop-element-scale-wrapper .pop-element-preview-wrapper {
  scale: 0.5;
  transform-origin: top left;
}
.popit-content .pop-it-game-preview-scale-wrapper {
  width: 640px;
  height: 400px;
}
.popit-content .pop-it-game-preview-scale-wrapper .popit-game-preview {
  scale: 0.5;
  transform-origin: top left;
}
.popit-content .pop-it-game-preview-scale-wrapper.small {
  width: 422.4px;
  height: 264px;
}
.popit-content .pop-it-game-preview-scale-wrapper.small .popit-game-preview {
  scale: 0.33;
  transform-origin: top left;
}
.popit-content .popit-element-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.popit-content .popit-element-form .pop-element-preview-wrapper {
  background-color: rgba(255, 255, 255, 0.1);
}
.popit-content .popit-element-form .section {
  display: flex;
  justify-content: space-between;
}
.popit-content .popit-element-form .section .pop-element-scale-wrapper {
  width: 217.5px;
  height: 217.5px;
}
.popit-content .popit-element-form .section .pop-element-scale-wrapper .pop-element-preview-wrapper {
  scale: 0.75;
  transform-origin: top left;
}
.popit-content .popit-element-form .animation {
  width: 250px;
  height: 250px;
  border-radius: 10px;
}
.popit-content .popit-element-form .item-container {
  width: 475px;
}
.popit-content .popit-element-form .style-preview-hover-msg {
  display: flex;
  flex-direction: column;
}
.popit-content .popit-element-form .style-preview-hover-msg .style-preview {
  display: flex;
  flex-direction: row;
}
.popit-content .popit-element-form .player-wrapper {
  margin: 0 40px 20px 0;
}
.popit-content .popit-element-form .player-wrapper.selected:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 5px;
  right: 5px;
  transform: rotate(30deg);
  height: 40px;
  width: 15px;
  border-bottom: 10px solid #ffa500;
  border-right: 10px solid #ffa500;
  filter: drop-shadow(0px 0px 1px #000000);
}
.popit-content .popit-element-form .player-wrapper.selected .player-frame {
  filter: drop-shadow(0 0 15px orange);
}
.popit-content .popit-element-form .player-wrapper.selected .clickable:hover .player-frame {
  filter: drop-shadow(0 0 15px orange);
}
.popit-content .popit-element-form .player-wrapper .edit {
  margin-left: -10px;
  cursor: pointer;
  background: black;
  color: white;
  position: absolute;
  padding: 5px 7px;
  border-radius: 3px;
  z-index: 1;
}
.popit-content .popit-element-form .player-wrapper .player-preview:hover {
  z-index: initial;
}
.popit-content .popit-element-form .player-wrapper.disabled .player-preview {
  opacity: 0.5;
}
.popit-content .popit-element-form .new-game-item .new-game-item-btn {
  scale: 1.2;
  border-color: white;
}
.popit-content .popit-element-form .new-game-item:hover .new-game-item-btn {
  color: #f48120;
  border-color: #f48120;
}
.popit-content .popit-element-form .new-game-item:hover.disabled .new-game-item-btn {
  color: rgba(0, 0, 0, 0.2);
}
.popit-content .popit-element-form .new-game-item .hover-msg {
  min-width: 75px;
  max-width: 115px;
}
.popit-content .popit-element-form .no-text-image-msg {
  max-width: 244px;
  padding: 3px;
}
.popit-content .popit-game-preview {
  position: relative;
  width: 1280px;
  height: 800px;
  background-color: #5E5E5E;
}
.popit-content .popit-game-preview .background {
  position: absolute;
  width: inherit;
  height: inherit;
}
.popit-content .popit-game-preview .pop-elements-container {
  width: inherit;
  height: inherit;
  position: relative;
}
.popit-content .popit-game-preview .pop-elements-container .pop-element-preview-wrapper.pop-element-position-wrapper {
  position: absolute;
  z-index: 1;
  background-color: transparent;
  border-radius: unset;
  padding: unset;
}
.popit-content .player-preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popit-content .player-preview.image {
  width: 100px;
  height: 100px;
}
.popit-content .player-preview.text {
  width: 150px;
  height: 88px;
}
.popit-content .player-preview.clickable {
  cursor: pointer;
}
.popit-content .player-preview.clickable:hover .player-frame {
  filter: drop-shadow(0 0 5px black);
}
.popit-content .player-preview .player-frame {
  width: 100%;
  position: absolute;
}
.popit-content .player-preview .player-image {
  width: 75%;
  position: absolute;
  border-radius: 100%;
}
.popit-content .player-preview .missing-player-content {
  text-align: center;
  position: relative;
  width: 75%;
  border-radius: 100%;
}
.popit-content .player-preview .hover-msg {
  bottom: 75%;
  left: 50%;
  min-width: 120px;
}
.popit-content .player-preview .player-text {
  position: absolute;
  margin-left: 3px;
  max-width: 80%;
  overflow: hidden;
  text-wrap: nowrap;
}
.popit-content .pop-it-contents-preview {
  box-shadow: unset;
}
.popit-content .pop-it-contents-preview .list {
  gap: 20px;
}
.popit-content .pop-it-contents-preview .pop-it-item-container .item-container-heading:after {
  border-bottom: none;
}
.reseller-dashboard-not p {
  padding: 20px 0;
}
.reseller-dashboard p {
  white-space: pre-wrap;
}
.reseller-dashboard .carousell-wrapper {
  margin: 0 -40px 0 -40px;
}
.reseller-dashboard .well {
  position: relative;
  margin: 0;
  margin-bottom: 10px;
  padding: 10px;
}
.reseller-dashboard .well .well-title {
  margin-bottom: 20px;
}
.reseller-dashboard .well .well-title p {
  margin-bottom: 10px;
}
.reseller-dashboard .well .sum-total {
  display: inline-block;
  text-align: start;
}
.reseller-dashboard .gauge-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
.reseller-dashboard .gauge-container > div {
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.reseller-dashboard .gauge-container .chart-wrapper {
  width: 600px;
}
.reseller-dashboard .item-container-table .table-sub-heading {
  margin-bottom: 10px;
}
.license-warning-settings .flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.license-warning-settings .admin-info {
  font-size: 0.9em;
  font-weight: bold;
  color: darkgoldenrod;
  padding: 5px;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5em;
}
.license-warning-settings .input-explain {
  width: 250px;
  font-size: 0.8em;
  font-weight: bold;
  margin-top: -0.5em;
  margin-bottom: 0.5em;
}
.license-warning-settings .license-warning-preview {
  background-image: url("./images/Container_708x300pxLicense_Expiration.png");
  width: 708px;
  height: 300px;
}
.license-warning-settings .license-warning-preview h2 {
  margin-left: 158px;
  width: 550px;
  text-align: center;
  line-height: 100px;
  margin-bottom: 50px;
  font-size: 26px;
  font-weight: bold;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
}
.license-warning-settings .license-warning-preview p {
  width: 100%;
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 20px;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
}
.license-warning-settings .license-warning-preview .custom-content {
  display: flex;
  flex-direction: row;
  color: black;
  justify-content: space-evenly;
  align-items: center;
}
.license-warning-settings .license-warning-preview .custom-content .contact-info {
  margin: 10px 0;
  height: 100px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.license-warning-settings .license-warning-preview .custom-content .contact-info div {
  margin-bottom: 5px;
}
.license-warning-settings .license-warning-preview .custom-content img {
  margin: 10px 0;
  width: 100px;
  height: 100px;
}
.license-warning-settings .curtain-preview .curtain {
  padding: 20px;
  width: 708px;
  text-align: center;
  background: #930a0a;
  color: white;
}
.word-finder .alphabet-preview {
  color: #969696;
}
.word-finder .category-preview {
  width: 150px;
  height: 55px;
  position: relative;
  display: flex;
  flex-direction: row;
}
.word-finder .category-preview .category-title-background {
  width: 100%;
}
.word-finder .category-preview .category-title {
  position: absolute;
  line-height: unset;
  letter-spacing: 0.2em;
  margin: 2px 2px 8px 8px;
  max-width: 135px;
  max-height: 20px;
  align-self: flex-end;
  overflow: hidden;
  text-wrap: nowrap;
}
.word-finder .category-preview .sound-icon {
  position: absolute;
  right: -10px;
}
.word-finder .category-preview.clickable:hover {
  scale: 1.05;
}
.word-finder .word-finder-word-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 125px;
  min-height: 66px;
  height: fit-content;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  color: black;
}
.word-finder .word-finder-word-preview.image {
  padding: 8px 5px 7px 5px;
  justify-content: space-around;
  gap: 5px;
}
.word-finder .word-finder-word-preview.image .hint-image-wrapper {
  height: 102px;
  width: 102px;
}
.word-finder .word-finder-word-preview.image .hint-image {
  position: absolute;
  max-width: 140px;
  max-height: 140px;
  scale: 0.6;
}
.word-finder .word-finder-word-preview.invalid {
  color: red;
}
.word-finder .word-finder-word-preview.invalid .sound-icon {
  color: black;
}
.word-finder .word-finder-word-preview .word-finder-word-to-find-preview {
  font-family: 'Secular One';
  font-size: 13px;
  white-space: pre;
}
.word-finder .word-finder-word-preview .sound-icon {
  position: absolute;
  right: -17px;
  top: -7px;
  font-size: 20px;
  align-self: center;
  color: black;
  z-index: 1;
}
.word-finder .word-finder-word-preview.clickable:hover {
  scale: 1.02;
}
.word-finder .hint-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 170px;
  height: 170px;
}
.word-finder .hint-image-wrapper .hint-image-background {
  position: relative;
  width: 100%;
  height: 100%;
}
.word-finder .hint-image-wrapper .hint-image {
  position: absolute;
  max-width: 140px;
  max-height: 140px;
  padding-top: 15px;
  padding-left: 3px;
}
.word-finder .hint-board-with-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.word-finder .hint-board-with-title .category-preview {
  left: 5px;
  scale: 1.5;
}
.word-finder .hint-board {
  width: 195px;
  height: 430px;
  display: flex;
  justify-content: center;
}
.word-finder .hint-board img.hint-board-background {
  position: absolute;
  width: 195px;
}
.word-finder .hint-board .hints-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: center;
  width: 141px;
  max-height: 365px;
  margin: 30px 35px 40px 43px;
}
.word-finder .hint-board .hints-container .hint-text {
  overflow: hidden;
  width: 140px;
  margin: 5px 0 10px 0;
  padding-top: 3px;
  font-family: 'Solitreo Regular';
  color: black;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  white-space: pre;
}
.word-finder .hint-board .hints-container .hint-text.rtl {
  font-family: Secular One;
}
.word-finder .hint-board .hints-container.images {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin: 30px 0 0 0;
}
.word-finder .hint-board .hints-container.images .hint-image-wrapper {
  width: 68px;
  height: 68px;
}
.word-finder .hint-board .hints-container.images .hint-image-wrapper .hint-image {
  max-width: 140px;
  max-height: 140px;
  scale: 0.4;
}
.word-finder .word-finder-form {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.word-finder .word-finder-form .top {
  height: fit-content;
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.word-finder .word-finder-form .top .right {
  position: relative;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.word-finder .word-finder-form .top .word-finder-form-msg {
  width: 250px;
}
.word-finder .word-finder-form .top .general-error {
  box-sizing: border-box;
}
.word-finder .word-finder-form .categories-container {
  max-width: 550px;
  box-shadow: unset;
  padding: 0;
  background-color: unset;
}
.word-finder .word-finder-form .categories-container .list {
  gap: 50px;
}
.word-finder .word-finder-form .categories-container .category-choice.selected .category-preview {
  filter: drop-shadow(9px 9px 20px yellow);
}
.word-finder .word-finder-form .categories-container .category-choice .edit {
  margin-left: -10px;
  cursor: pointer;
  background: black;
  color: white;
  position: absolute;
  padding: 5px 7px;
  border-radius: 3px;
  z-index: 1;
}
.word-finder .word-finder-form .categories-container .category-choice.disabled .category-preview {
  opacity: 0.5;
}
.word-finder .word-finder-form .categories-container .category-choice.disabled .hover-msg {
  width: 160px;
}
.word-finder .word-finder-form .categories-container .new-game-item .new-game-item-btn {
  scale: 1.2;
  border-color: white;
}
.word-finder .word-finder-form .categories-container .new-game-item:hover .new-game-item-btn {
  color: #f48120;
  border-color: #f48120;
}
.word-finder .word-finder-form .categories-container .new-game-item:hover.disabled .new-game-item-btn {
  color: rgba(0, 0, 0, 0.2);
}
.word-finder .word-finder-form .categories-container .new-game-item .hover-msg {
  min-width: 75px;
  max-width: 115px;
}
.word-finder .word-finder-form .categories-container .hover-msg {
  bottom: 50px;
  left: 10px;
}
.word-finder .word-finder-form .categories-container[dir='rtl'] .hover-msg {
  left: 0;
  right: 10px;
}
.word-finder .word-finder-grid-preview-wrapper {
  margin-top: 20px;
  position: relative;
  display: flex;
  width: 495px;
  height: 420px;
}
.word-finder .word-finder-grid-preview-wrapper .word-finder-grid-preview-background {
  width: 495px;
  height: 420px;
  position: relative;
}
.word-finder .word-finder-grid-preview-wrapper table {
  width: 414px;
  height: 370px;
  position: absolute;
  margin: 25px 0 0 44px;
  color: white;
  table-layout: fixed;
}
.word-finder .word-finder-grid-preview-wrapper table td {
  text-align: center;
  align-content: center;
  font-family: 'Solitreo Regular';
  font-size: 25px;
  font-weight: bolder;
}
.word-finder .word-finder-grid-preview-wrapper.rtl td {
  font-family: 'Secular One';
}
.word-finder .word-finder-game-preview {
  width: 426px;
  height: 291px;
}
.word-finder .word-finder-game-preview .word-finder-round-preview {
  display: flex;
  flex-direction: row;
  gap: 20px;
  scale: 0.6;
  transform-origin: top left;
  width: fit-content;
  height: fit-content;
}
.word-finder .word-finder-game-preview .word-finder-round-preview .hint-board-with-title {
  margin-top: -10px;
}
.word-finder .word-finder-contents-preview {
  box-shadow: unset;
}
.word-finder .word-finder-contents-preview .list {
  gap: 20px;
}
.word-finder .word-finder-contents-preview .grid-contents-preview {
  height: fit-content;
}
.word-finder .word-finder-contents-preview .grid-contents-preview .item-container-heading:after {
  border-bottom: none;
}
.word-finder .word-finder-contents-preview .grid-contents-preview .list {
  width: fit-content;
  justify-content: center;
  gap: 10px;
  margin-left: 5px;
}
.word-finder .dialog-total-content {
  min-width: 380px;
}
.storage-unit-content {
  margin: 10px;
}
.storage-unit-content .dialog-overlay.scrollable .remote-config-form .btn-container {
  overflow: visible;
  position: relative;
  width: 100%;
  bottom: -3.5em;
  left: 0;
  z-index: 1;
}
.storage-unit-content .dialog-overlay.scrollable .remote-config-form .btn-container .btn {
  display: block;
  margin: auto;
  padding: 0.5em 6em;
}
.storage-unit-content .dialog-overlay.scrollable .remote-config-form .btn-container .btn:hover {
  padding: 0.5em 6em;
}
.globetrotter {
  /* position:relative on parent and position:absolute on children allows pixel placements relative to parent container */
}
.globetrotter .globetrotter-map-info-column {
  margin-right: 20px;
}
.globetrotter .globetrotter-hint-list-form {
  width: 450px;
}
.globetrotter .flex {
  gap: 15px;
}
.globetrotter .item-container.scroll {
  overflow-y: auto;
  height: 800px;
}
.globetrotter .location-item {
  background-color: rgba(0, 0, 0, 0.212);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  border: 3px solid transparent;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.globetrotter .location-item .location-item-content {
  display: flex;
  justify-content: space-between;
}
.globetrotter .location-item.warning {
  background-color: rgba(255, 0, 0, 0.425);
}
.globetrotter .location-item.highlight {
  border: 3px solid black;
}
.globetrotter .location-item h3 {
  font-size: 20px;
}
.globetrotter .location-radius {
  display: flex;
  align-items: baseline;
  gap: 20px;
}
.globetrotter .location-radius .form-group {
  width: 170px;
}
.globetrotter .location-item:hover {
  background-color: rgba(0, 0, 0, 0.512);
}
.globetrotter .hint-item {
  transform: scale(0.75);
  height: 330px;
  width: 100px;
}
.globetrotter .pin-element {
  position: absolute;
  pointer-events: none;
}
.globetrotter .pin-element .preview-pin {
  position: absolute;
  height: 58px;
  left: -19px;
  top: -58px;
}
.globetrotter .pin-element .pin-name {
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 5px;
  background-color: white;
  position: absolute;
  width: 80px;
  text-align: center;
  left: -45px;
  top: 8px;
  font-size: 12px;
  pointer-events: none;
}
.globetrotter .pin-element .pin-hints {
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 5px;
  background-color: white;
  position: absolute;
  width: 50px;
  text-align: center;
  left: -28px;
  top: 28px;
  font-size: 12px;
  pointer-events: none;
}
.globetrotter .pin-element .pin-hints.empty {
  background-color: #b90000;
  color: white;
}
.globetrotter .pin-element .radius-circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(0, 255, 0, 0.2);
  border: 1px dashed black;
}
.globetrotter .pin-element .radius-circle.disabled {
  background-color: rgba(0, 0, 0, 0.2);
}
.globetrotter .pin-element .radius-circle.active {
  background-color: rgba(37, 136, 167, 0.2);
}
.globetrotter .hint-preview-scaler {
  transform: scale(0.6);
  transform-origin: top left;
  width: 310px;
  height: 250px;
}
.globetrotter .hints-item-container {
  width: 310px;
}
.globetrotter .hint-preview {
  position: relative;
  width: 503px;
  height: 407px;
}
.globetrotter .hint-preview .board-bg {
  position: absolute;
}
.globetrotter .hint-preview .board-overlay {
  position: absolute;
  margin-top: -37px;
}
.globetrotter .hint-preview .hint-image {
  position: absolute;
  left: 96px;
  top: 76px;
  width: 374px;
}
.globetrotter .hint-preview .hint-text {
  position: absolute;
  left: 108px;
  top: 76px;
  width: 350px;
  height: 223px;
  overflow: hidden;
  align-items: center;
  display: flex;
}
.globetrotter .hint-preview .hint-title {
  position: absolute;
  left: 168px;
  z-index: 200;
  width: 258px;
  height: 50px;
  top: -10px;
  line-height: 50px;
  text-align: center;
}
.globetrotter .globetrotter-map-form .map-edit {
  width: 800px;
  height: 800px;
  position: relative;
}
.globetrotter .globetrotter-map-form .map-edit .map-static {
  position: absolute;
}
.globetrotter .globetrotter-map-form .map-edit .map-interactive {
  position: absolute;
  width: 800px;
  height: 800px;
}
.globetrotter .globetrotter-map-form .map-edit .map-interactive .line {
  position: absolute;
  pointer-events: none;
}
.globetrotter .globetrotter-map-form .map-edit .map-interactive .line.vertical {
  border-top: 1px dashed black;
  width: 100%;
}
.globetrotter .globetrotter-map-form .map-edit .map-interactive .line.horizontal {
  border-left: 1px dashed black;
  height: 100%;
}
.globetrotter .globetrotter-map-form .map-edit .map-interactive .info {
  position: absolute;
  font-size: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.612);
  padding: 3px 6px;
  border-bottom-right-radius: 5px;
}
.globetrotter .globetrotter-map-form .map-edit .map-interactive .place-location-description {
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.412);
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.globetrotter .globetrotter-map-form .map-edit .map-interactive .place-location-description h2 {
  color: white;
  background-color: rgba(0, 0, 0, 0.568);
  padding: 10px;
  border-radius: 10px;
}
.globetrotter .reorder-map-preview {
  width: 200px;
  height: 224px;
}
.globetrotter .reorder-map-preview .map-preview {
  scale: 0.25;
  transform-origin: top left;
}
.globetrotter .reorder-map-preview h3 {
  text-align: center;
}
.globetrotter .map-preview {
  width: 800px;
  height: 800px;
  position: relative;
  overflow: hidden;
  user-select: none;
}
.globetrotter .map-preview .background-image {
  width: 800px;
  height: 800px;
  position: absolute;
  mask-repeat: no-repeat;
}
.globetrotter .map-preview .scale-legend {
  position: absolute;
  width: 100px;
  text-align: center;
  right: 40px;
  top: 40px;
  color: black;
  display: flex;
  flex-direction: column;
}
.globetrotter .map-preview .scale-legend .ruler {
  background-color: black;
  height: 4px;
}
.globetrotter .map-preview .scale-legend .ruler.white {
  background-color: white;
}
.globetrotter .map-preview .scale-legend .text {
  font-size: 12px;
  margin: 2px;
}
.globetrotter .map-preview .scale-legend .white {
  color: white;
}
/* Keyframes for height animation */
@keyframes expand {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}
@keyframes collapse {
  from {
    height: auto;
  }
  to {
    height: 0;
  }
}
.duplicated-licenses {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.duplicated-licenses .org-title {
  user-select: none;
  cursor: pointer;
}
.duplicated-licenses .org-title svg {
  margin-left: 10px;
}
.duplicated-licenses .org-container {
  margin-left: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}
.duplicated-licenses .org-container.min {
  height: 0;
  animation: collapse 0.5s forwards;
}
.duplicated-licenses .org-container.max {
  opacity: 1;
  transform: scaleY(1);
}
.duplicated-licenses .org-container .bulk-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.duplicated-licenses .org-container table .actions {
  display: flex;
  align-items: center;
  gap: 5px;
}
.duplicated-licenses .license {
  background: rgba(0, 0, 0, 0.05);
  padding: 5px;
  border-radius: 5px;
}
/* RESET CSS*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
button {
  font-family: inherit;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overflow-y: scroll;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html,
body {
  font-family: 'Lato', 'Secular one', sans-serif;
  color: black;
}
#root.hebrew-font {
  font-family: 'Secular one', 'Lato', sans-serif;
}
.crop-container .ReactCrop {
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
}
.ReactCrop div {
  overflow: initial;
}
a {
  text-decoration: none;
}
h1,
h2,
h3 {
  margin-bottom: 0.5em;
}
h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h1 + p {
  margin-top: -0.5em;
}
.hand {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
.relative {
  position: relative;
}
.xs-text {
  font-size: 0.5em;
}
.content {
  position: relative;
  max-width: 100%;
  min-height: 50vh;
}
.content .layout-content {
  padding: 40px;
}
.clear-fix {
  float: none;
  overflow: auto;
  height: 0;
  width: 100%;
}
.half {
  float: left;
  width: 50%;
}
.padding {
  padding: 40px;
}
.padding-vertical {
  padding: 40px 0 40px 0;
}
.padding-s {
  padding: 10px;
}
.margin {
  margin: 20px;
}
.margin-top {
  margin-top: 20px;
}
.margin-bot {
  margin-bottom: 20px;
}
.margin-bot-sm {
  margin-bottom: 10px;
}
.float {
  float: left;
}
.float-right {
  float: right;
}
.general-error {
  color: red;
}
.table-hover-trigger {
  position: relative;
}
.table-hover-trigger .hover {
  position: absolute;
  left: 0;
  display: inline-block;
  transform: scale(0);
}
.table-hover-trigger .not-hover {
  display: inline-block;
  transform: scale(1);
}
.table-hover-trigger:hover .hover {
  transform: scale(1);
}
.table-hover-trigger:hover .not-hover {
  transform: scale(0);
}
.copy-form {
  width: 250px;
}
form .form-area {
  max-width: 250px;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
  box-sizing: border-box;
}
form .form-area .form-group {
  max-width: 230px;
}
form .form-area .form-group.currency .form-control {
  width: 150px;
}
form .form-area .form-group.currency select.form-control {
  width: 80px;
}
form .form-area .form-group .form-control {
  width: 230px;
}
form .form-area .form-group .btn {
  width: 233px;
}
.preview-slide-show {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}
.preview-slide-show .selector {
  display: flex;
}
.preview-slide-show .selector img {
  position: relative;
  width: 60px;
  margin: 0 10px 10px 0;
  overflow: hidden;
  cursor: pointer;
}
.preview-slide-show .selector img.active {
  box-shadow: 0 0 10px #000;
}
.preview-slide-show .container {
  display: flex;
  flex-wrap: wrap;
}
.preview-slide-show .container img {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  width: 400px;
  margin: 0 20px 20px 0;
  overflow: hidden;
}
.changes-icon {
  position: absolute;
  display: inline-block;
  right: -4px;
  top: -8px;
  color: yellow;
  font-size: 0.9em;
}
.hover-msg {
  cursor: default;
  position: absolute;
  visibility: hidden;
  left: 0;
  background: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  color: #323232;
  text-align: left;
  padding: 7px;
  border-radius: 5px;
  z-index: 99999;
  pointer-events: none;
  white-space: pre-wrap;
}
.hover-msg.unsaved-changes {
  background: #323232;
  color: yellow;
  top: -10px;
  right: -6px;
  padding: 3px 22px 3px 3px;
  left: initial;
  font-size: 0.7em;
  z-index: initial;
}
.hover-trigger {
  position: relative;
}
.hover-trigger.not-relative {
  position: initial;
}
.hover-trigger:hover {
  z-index: 9;
}
.hover-trigger:hover .hover-msg {
  visibility: visible;
}
.hover-msg-2 {
  cursor: default;
  position: absolute;
  visibility: hidden;
  left: 0;
  background: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  color: #323232;
  text-align: left;
  padding: 7px;
  border-radius: 5px;
  z-index: 99999;
  pointer-events: none;
}
.hover-msg-2 .taglist {
  max-height: unset;
}
.hover-msg-2.unsaved-changes {
  background: #323232;
  color: yellow;
  top: -10px;
  right: -6px;
  padding: 3px 22px 3px 3px;
  left: initial;
  font-size: 0.7em;
  z-index: initial;
}
.hover-trigger-2 {
  position: relative;
}
.hover-trigger-2.not-relative {
  position: initial;
}
.hover-trigger-2:hover {
  z-index: 9;
}
.hover-trigger-2:hover .hover-msg-2 {
  visibility: visible;
}
.link-text {
  cursor: pointer;
  transition: all 100ms;
}
.link-text:hover {
  text-decoration: underline;
  color: #f48120;
  padding-left: 5px;
}
.link-text svg {
  margin-right: 0.3em;
}
.basegame-form .title-section {
  margin: 10px 0;
  padding: 10px 0 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.basegame-form .sub-section {
  margin-left: 10px;
}
.unit-profile-manager {
  display: flex;
  margin-bottom: 0.5em;
}
.unit-profile-manager .form-group {
  margin-bottom: 0;
  margin-right: 10px;
  max-width: initial;
}
.unit-profile-manager .manage-btn {
  margin-right: 10px;
}
.admin-table {
  display: flex;
}
.admin-table tr {
  border-bottom: 1px dashed rgba(50, 50, 50, 0.2);
}
.admin-table thead tr th {
  text-align: left;
  font-weight: bold;
  padding: 5px 10px 0 0;
}
.admin-table td {
  padding: 5px 10px 5px 0;
  vertical-align: middle;
}
.admin-table td img {
  vertical-align: bottom;
}
.admin-table td .playlist-preview {
  vertical-align: bottom;
}
.admin-table td .btn {
  padding: 4px 8px;
}
.admin-table .title {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.admin-table .description {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.hidden-controls {
  display: none;
}
.icon-menu {
  display: initial;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 200ms;
  z-index: 11;
}
.icon-menu.hidden {
  visibility: hidden;
  transform: translateY(-102%);
}
.icon-menu .close-icon {
  width: 100%;
  text-align: center;
  background: #323232F2;
  height: 10%;
  position: relative;
}
.icon-menu .close-icon span {
  position: absolute;
  font-size: 25px;
  color: white;
  cursor: pointer;
  bottom: calc(50% - 12.5px);
}
.icon-menu .close-icon:hover {
  background: #710640;
}
.icon-menu .menu-container {
  position: relative;
  height: 100%;
}
.icon-menu .menu-point {
  box-sizing: border-box;
  color: white;
  height: 30%;
  padding-left: 7%;
  text-align: left;
  transition: all 200ms;
  font-size: 0.8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: linear-gradient(to bottom, #323232F2, #323232, #323232F2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon-menu .menu-point:hover {
  background: linear-gradient(to bottom, #710640, #710640, #710640);
  padding-left: 10%;
}
.icon-menu .menu-point:hover .fa-icon {
  transform: scale(1.2);
}
.icon-menu .menu-point .fa-icon {
  position: absolute;
  right: 8%;
}
.standalone-error {
  padding: 1em;
  border: 2px dashed #ff6464;
  border-radius: 0.2em;
  margin: 1em 0;
  color: #ff9696;
  font-size: 1.1em;
  font-weight: bold;
  font-style: italic;
}
.width-100 {
  width: 100%;
}
.scroll-margin {
  scroll-margin: 20px;
}
.dialog .item-container .item-container-heading:after {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
.gametype-picker-icons {
  display: flex;
  flex-wrap: wrap;
}
.gametype-picker-icons .item {
  position: relative;
  height: 30px;
  filter: grayscale(1);
  opacity: 0.8;
  transition: filter 200ms, opacity 200ms;
}
.gametype-picker-icons .item.selected {
  filter: grayscale(0);
  opacity: 1;
}
.gametype-picker-icons .item.selected svg {
  scale: 1;
}
.gametype-picker-icons .item img {
  height: 100%;
  filter: drop-shadow(0 0 0 black);
}
.gametype-picker-icons .item svg {
  scale: 0;
  color: green;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: scale 200ms;
}
.full-width {
  width: 100%;
}
.item-container.latest-games .list {
  padding: 5px;
  padding-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
  border-top: 0;
}
.item-container.latest-games .list-item .item-icon.clickable:hover {
  transform: scale(1);
}
.item-container.latest-games .list-item .latest-info {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 3px;
  color: #323232;
}
.item-container.latest-games .list-item .latest-info svg {
  font-size: 0.75em;
  color: #0aaae9;
  padding-bottom: 0.08em;
  margin-inline-end: 3px;
}
.item-container {
  position: relative;
  background: rgba(200, 200, 200, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
.item-container.scroll-x {
  box-sizing: border-box;
  max-width: 100%;
}
.item-container.scroll-x .list {
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
.item-container .item-container-heading {
  display: inline-block;
  text-align: initial;
}
.item-container .item-container-heading:after {
  content: "";
  display: block;
  width: 33%;
  margin: 10px 0 20px 0;
  box-sizing: content-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.item-container .item-container-sub-heading {
  text-align: initial;
  display: block;
  font-size: 0.9em;
  margin-bottom: 20px;
  margin-top: -15px;
}
.item-container.vertical {
  border-radius: 5px;
}
.item-container.vertical .item-container-heading {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.item-container.vertical .list {
  flex-direction: column;
}
.item-container.big-gap .list {
  gap: 20px;
}
.item-container .list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.item-container .secondary {
  color: #7d7d7d;
}
.item-container .general-error {
  width: 100%;
}
.chart-tool-tip {
  line-height: initial;
}
.chart-tool-tip .author-name {
  line-height: 0.75em;
}
.featured-game {
  position: relative;
  overflow: hidden;
  width: 98%;
  margin: 1%;
  height: 400px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  text-align: initial;
  display: flex;
  justify-content: center;
}
.featured-game .game .game-description:after {
  display: none;
}
.featured-game .featured-type {
  color: white;
  display: inline-block;
  padding: 0.3em 0.7em;
  width: initial;
}
.featured-game .hero-image {
  height: 100%;
}
.featured-game .featured-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.featured-game .featured-content .content-shadow .wrapper {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
}
.featured-game .featured-content .centered {
  width: 100%;
  border-radius: 3px;
  background: linear-gradient(to bottom, rgba(25, 25, 25, 0.3), rgba(30, 30, 30, 0.8), #1e1e1e);
  padding: 10px 55px;
}
.featured-game .featured-content .centered .game-actions {
  position: absolute;
  right: 5%;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.featured-game .featured-content .centered .game-actions .btn {
  width: 220px;
  margin: 5px 10px;
}
.featured-game .featured-content .centered .game-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.featured-game .featured-content .centered .game-info .game-wrapper,
.featured-game .featured-content .centered .game-info .account-wrapper,
.featured-game .featured-content .centered .game-info .playlist-wrapper {
  position: absolute;
  right: 5%;
  bottom: 90px;
  padding: 10px;
  border-radius: 3px;
  height: 302px;
  box-sizing: border-box;
  color: white;
}
.featured-game .featured-content .centered .game-info .account-wrapper {
  height: 270px;
}
.featured-game .featured-content .centered .game-info .account-wrapper .author-preview {
  width: 220px;
}
.featured-game .featured-content .centered .game-info .playlist-wrapper .playlist-preview {
  float: none;
  color: white;
  cursor: initial;
}
.featured-game .featured-content .centered .game-info .playlist-wrapper .playlist-preview .pl-description:after {
  display: none;
}
.featured-game .featured-content .centered .game-info .game {
  float: none;
  color: white;
}
.featured-game .featured-content .centered .game-info .myfloor-text.shadow {
  text-shadow: 0 0 10px black;
}
.featured-game .featured-content .centered .game-info .text {
  position: absolute;
  left: 20px;
  bottom: 20px;
  max-width: calc(100% - 15% - 220px);
  border-radius: 3px;
  margin-left: 20px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  word-break: break-word;
}
.featured-game .featured-content .centered .game-info .text .title {
  color: white;
}
.featured-game .featured-content .centered .game-info .text p {
  white-space: pre-wrap;
}
.featured-game .backdrop {
  min-width: 100%;
  text-align: center;
}
.time-select {
  display: flex;
}
.time-select .clock-container {
  margin-left: 1em;
}
.time-select .clock-container .form-group {
  width: initial;
}
.time-select .form-group {
  width: 130px;
}
.time-select .form-group label {
  margin-bottom: 0.4em;
}
.trash {
  cursor: pointer;
}
.trash:hover {
  color: #AA0000;
}
.scheduled-items .cmd {
  margin: 1em 0;
}
.scheduled-items .cmd h3 {
  border-bottom: 1px solid white;
}
.scheduled-items .times .day-of-week-time {
  margin: 0 0 0.5em 0;
  display: flex;
  justify-content: space-between;
}
.scheduled-items .times .day-of-week-time span:first-of-type {
  min-width: 100px;
}
.scheduled-items .times .day-of-week-time .trash {
  margin-left: 1em;
}
.clock-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clock-container .checkbox-container.active .checkbox-slide {
  background: #f48120;
}
.clock-container .am-check {
  margin-top: 0.5em;
  font-size: 0.5em;
}
.clock-container .am-check.form-group {
  width: 60px;
}
.clock-container .clock {
  width: 100px;
  height: 100px;
  background: rgba(30, 70, 200, 0.4);
  border-radius: 100%;
  position: relative;
  transform-origin: top left;
  transition: all 800ms;
}
.clock-container .clock.night {
  background: #4f5eab;
}
.clock-container .clock.day {
  background: #ecd52b;
}
.clock-container .clock .hours,
.clock-container .clock .minutes {
  background: #000000;
  position: absolute;
  left: 50px;
  top: 50px;
  transform-origin: top;
  transform: rotate(0deg);
  box-sizing: border-box;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}
.clock-container .clock .hours {
  left: 48px;
  width: 4px;
  height: 27px;
}
.clock-container .clock .minutes {
  left: 49px;
  width: 2px;
  height: 42px;
}
.clock-container .clock .id {
  position: absolute;
  width: 2px;
  height: 5px;
  background: rgba(0, 0, 0, 0.8);
  left: 49px;
  top: 50px;
  padding-top: 46px;
  background-clip: content-box;
  transform-origin: top;
}
.clock-container .clock .id.id-1 {
  transform: rotate(210deg);
}
.clock-container .clock .id.id-2 {
  transform: rotate(240deg);
}
.clock-container .clock .id.id-3 {
  transform: rotate(270deg);
}
.clock-container .clock .id.id-4 {
  transform: rotate(300deg);
}
.clock-container .clock .id.id-5 {
  transform: rotate(330deg);
}
.clock-container .clock .id.id-6 {
  transform: rotate(360deg);
}
.clock-container .clock .id.id-7 {
  transform: rotate(30deg);
}
.clock-container .clock .id.id-8 {
  transform: rotate(60deg);
}
.clock-container .clock .id.id-9 {
  transform: rotate(90deg);
}
.clock-container .clock .id.id-10 {
  transform: rotate(120deg);
}
.clock-container .clock .id.id-11 {
  transform: rotate(150deg);
}
.clock-container .clock .id.id-0 {
  transform: rotate(180deg);
}
.image-crop-form .form-group {
  margin: 10px 0;
}
.image-crop-form .crop-container {
  margin-top: 10px;
}
.image-crop-form .form-error {
  color: #ff5454;
}
.image-crop-form .final-image-preview img {
  max-width: 400px;
  max-height: 400px;
}
.color-image-group {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3em;
  width: 250px;
  box-sizing: border-box;
}
.color-image-group .form-group {
  float: left;
  margin-bottom: 0;
}
.color-image-group .form-group.float-right {
  float: right;
}
.color-image-group .form-group .font-color {
  margin: 5px 0 5px 5px;
}
.color-image-group .form-group .image-button-quick {
  background: none;
  width: calc(240px - 2.4em - 5px);
}
.color-image-group .form-group .image-button-quick .btn {
  width: calc(100% - 54px);
  max-width: initial;
}
.color-image-group .form-group .image-button-quick .form-error {
  color: #ff5454;
}
.myfloor-text {
  line-height: initial;
  width: 100%;
}
.dialog .form-group .form-control {
  color: white;
}
.radio-group {
  margin-bottom: 0.5em;
}
.radio-group .row {
  display: flex;
  flex-direction: row;
}
.radio-group .column {
  display: flex;
  flex-direction: column;
}
.radio-group .form-group {
  margin-bottom: 0em;
}
.radio-group .form-group .checkbox-container {
  border-radius: 0em;
  user-select: none;
}
.radio-group .form-group .checkbox-container.active {
  background-color: rgba(255, 0, 128, 0.4);
}
.radio-group .form-group .checkbox-container.active:hover {
  background-color: rgba(128, 0, 128, 0.4);
}
.radio-group .form-group .checkbox-container .checkbox-slide {
  border-radius: 50%;
}
.radio-group .form-group .checkbox-container:hover {
  background-color: rgba(255, 156, 0, 0.4);
}
.radio-group :first-child .checkbox-container {
  border-radius: 0.3em 0.3em 0em 0em;
}
.radio-group :last-child .checkbox-container {
  border-radius: 0em 0em 0.3em 0.3em;
}
form.white-background .form-group .form-control {
  color: black;
  border-color: black;
}
form.wide .form-group {
  max-width: 600px;
}
form.wide .form-group .form-control {
  width: 100%;
}
.dialog form .email-template-view .form-group .form-control {
  color: black;
}
.email-template-view {
  background-color: #ffffff;
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  margin-bottom: 0.5em;
}
.email-template-view .form-group textarea.form-control {
  height: 200px;
}
.email-template-view hr {
  border-color: #f0f0f0;
}
.email-template-view .form-group,
.email-template-view hr {
  margin: 0;
}
.email-template-view textarea,
.email-template-view input {
  color: black;
  padding: 10px;
  margin: 0;
}
.email-template-view input {
  font-size: 1.5em;
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
}
img.checkered {
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.dialog .form-group .form-error-container .form-error {
  color: #910101;
}
.form-group {
  position: relative;
  margin-bottom: 0.5em;
  max-width: 250px;
}
.form-group label {
  display: inline-block;
  margin-bottom: 0.1em;
  margin-right: 7px;
}
.form-group label.single-label {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3em;
  padding: 0.6em;
  box-sizing: border-box;
  margin-bottom: 0;
}
.form-group .form-error-container {
  background-color: rgba(245, 188, 188, 0.5);
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
}
.form-group .form-error-container .form-error {
  padding-top: 0.5em;
  text-align: left;
  color: #ff5454;
  font-size: 0.9em;
}
.form-group .form-error-container .form-error:first-of-type {
  padding-top: 0.1em;
}
.form-group.currency .form-control {
  width: 170px;
}
.form-group.currency select.form-control {
  width: 80px;
}
.form-group.color-group {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3em;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.form-group.color-group select.form-control.font-family {
  width: calc(100% - 40px);
  max-width: initial;
}
.form-group.color-group .color-label {
  width: 190px;
  margin: 0;
  padding-left: 5px;
  padding-top: 0.5em;
}
.form-group.small {
  width: 250px;
}
.form-group:hover .hover-msg {
  display: initial;
}
.form-group.bg-fill {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.2em;
  border-radius: 2px;
}
.form-group .btn {
  width: 243px;
  height: 2.2em;
  font-size: 1em;
}
.form-group .text-alignment {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3em;
}
.form-group .text-alignment .btn {
  width: initial;
  height: initial;
  margin: 5px;
  font-size: 0.9em;
}
.form-group .text-alignment .btn.active {
  color: #00c736;
  background: #c2c2c2;
}
.form-group .input-btn-group input {
  width: 198px;
}
.form-group .input-btn-group .btn {
  width: initial;
  margin-left: 5px;
}
.form-group .btn-icon-group .btn {
  width: 203px;
}
.form-group .btn-icon-group .icon {
  font-size: 1.6em;
  margin: 0 5px;
  cursor: pointer;
}
.form-group .btn-icon-group .icon:hover {
  color: #AA0000;
}
.form-group textarea.form-control {
  height: calc(6.4em + 2px);
}
.form-group select.form-control {
  padding: 5px;
}
.form-group select.form-control.font-family {
  width: 195px;
  float: right;
  max-width: calc(100% - 50px);
}
.form-group select option {
  color: black;
}
.form-group .image-button-quick,
.form-group .button-quick {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3em;
  vertical-align: middle;
  padding: 5px;
}
.form-group .image-button-quick .quick-upload,
.form-group .button-quick .quick-upload {
  float: left;
  text-align: center;
  margin-right: 5px;
}
.form-group .image-button-quick .quick-upload .drop-container .dropzone,
.form-group .button-quick .quick-upload .drop-container .dropzone {
  padding: 7px 12px;
  margin: 0;
}
.form-group .image-button-quick .btn,
.form-group .button-quick .btn {
  width: 150px;
}
.form-group .image-button-quick .remove-container,
.form-group .button-quick .remove-container,
.form-group .image-button-quick .remove-doc-container,
.form-group .button-quick .remove-doc-container {
  cursor: pointer;
}
.form-group .image-button-quick .remove-container:hover .remove-icon,
.form-group .button-quick .remove-container:hover .remove-icon,
.form-group .image-button-quick .remove-doc-container:hover .remove-icon,
.form-group .button-quick .remove-doc-container:hover .remove-icon,
.form-group .image-button-quick .remove-container:hover .icon-doc,
.form-group .button-quick .remove-container:hover .icon-doc,
.form-group .image-button-quick .remove-doc-container:hover .icon-doc,
.form-group .button-quick .remove-doc-container:hover .icon-doc {
  color: #b40000;
}
.form-group .image-button-quick .remove-container .icon-doc,
.form-group .button-quick .remove-container .icon-doc,
.form-group .image-button-quick .remove-doc-container .icon-doc,
.form-group .button-quick .remove-doc-container .icon-doc {
  font-size: 2em;
  vertical-align: -0.3em;
  padding-inline-start: 0.4em;
}
.form-group .image-button-quick .remove-icon,
.form-group .button-quick .remove-icon {
  position: absolute;
  font-size: 10px;
  top: 32px;
  right: 4px;
  vertical-align: middle;
}
.form-group .image-button-quick img,
.form-group .button-quick img {
  margin-left: 5px;
  max-width: 34px;
  max-height: 34px;
  vertical-align: middle;
}
.form-group .audio-button-quick {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3em;
  vertical-align: middle;
  padding: 5px;
}
.form-group .audio-button-quick .quick-upload {
  float: left;
  text-align: center;
  margin-right: 5px;
}
.form-group .audio-button-quick .quick-upload .drop-container .dropzone {
  padding: 7px 12px;
  margin: 0;
}
.form-group .audio-button-quick .btn {
  width: 150px;
  max-width: calc(100% - 90px);
}
.form-group .audio-button-quick .remove-container,
.form-group .audio-button-quick span {
  cursor: pointer;
}
.form-group .audio-button-quick .remove-container:hover .remove-icon,
.form-group .audio-button-quick span:hover .remove-icon,
.form-group .audio-button-quick .remove-container:hover svg,
.form-group .audio-button-quick span:hover svg {
  color: #b40000;
}
.form-group .audio-button-quick .remove-icon {
  font-size: 25px;
  margin-left: 12px;
  vertical-align: middle;
}
.form-group .audio-button-quick img {
  margin-left: 5px;
  max-width: 34px;
  max-height: 34px;
  vertical-align: middle;
}
.form-group .audio-button-quick .sound-container {
  height: 47px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.form-group .audio-button-quick .sound-container audio {
  position: absolute;
  scale: 0.75;
}
.form-group .form-control {
  display: inline-flex;
  box-sizing: border-box;
  padding: 0.5em;
  border-radius: 0.2em;
  border: 1px solid black;
  background: transparent;
  color: black;
  width: 250px;
  max-width: 100%;
  height: 2.2em;
  font-size: 1em;
}
.form-group .form-control.dir-rtl {
  direction: rtl;
}
.form-group .form-control.dir-ltr {
  direction: ltr;
}
.form-group .form-control::placeholder {
  color: #a0a0a0;
}
.form-group .form-control:focus {
  outline: none;
}
.form-group .form-control.unsaved-changes {
  border-color: yellow;
}
.form-group .form-control.control-dark {
  border: 1px solid black;
  color: black;
}
.form-group .form-control:disabled {
  background: rgba(100, 100, 100, 0.5);
  color: rgba(255, 255, 255, 0.5);
  border-style: hidden;
}
.form-group .form-control.slider {
  overflow: auto;
}
.form-group .form-control.slider .number {
  float: left;
  width: 20%;
  text-align: center;
  height: 100%;
  background: none;
  border: none;
}
.form-group .form-control.slider input {
  float: left;
  box-sizing: border-box;
  margin: 0;
  background: transparent;
  color: black;
  width: 80%;
  height: 100%;
}
.form-group.account-form-group .form-group {
  margin-bottom: 0;
}
.form-group label.full-width {
  float: none;
  width: 100%;
  line-height: 1em;
  height: inherit;
}
.form-group .font-color-container {
  display: inline-block;
  overflow: visible;
}
.form-group .font-color {
  width: 2.2em;
  height: 2.2em;
  border-radius: 5px;
  background: linear-gradient(#7f7f7f, #323232);
  text-align: center;
}
.form-group .font-color .font-color-icon {
  height: 2.2em;
  width: 1.5em;
}
.form-group .chrome-picker {
  position: absolute;
  z-index: 100;
  transform: translate(37px);
}
.form-group .btn-icon-group > * {
  vertical-align: middle;
}
/*****************************************************/
.form-group.select-pool .title {
  margin-bottom: 5px;
}
.form-group.select-pool {
  max-width: 512px;
}
.form-group.select-pool .option {
  padding: 5px;
  margin: 2px 2px;
  transition: all 200ms;
  float: left;
  background: #00a79d;
  color: white;
  border-radius: 0.2em;
  cursor: pointer;
}
.form-group.select-pool .option.disabled {
  background: #c8c8c8;
}
.form-group.select-pool .option .icon {
  float: right;
  padding-left: 10px;
}
.form-group.select-pool .option:hover .icon {
  color: #c80000;
}
.form-group.select-pool .option.disabled:hover .icon {
  color: inherit;
}
form.transfer-bulk-form table.simple-table {
  min-width: 100%;
}
form.transfer-bulk-form table.simple-table tr td {
  vertical-align: middle;
}
form.transfer-bulk-form table.simple-table tr td.select-indicator {
  text-align: center;
  font-size: 2em;
}
form.transfer-bulk-form table.simple-table tr td .form-error {
  white-space: normal;
  color: #ff7b74;
  text-shadow: 0 0 2px black;
}
form.transfer-bulk-form table.simple-table tr td .taglist {
  font-size: 0.85em;
}
form.transfer-bulk-form table.simple-table tr td .taglist .tag {
  padding: 2px 3px;
}
.form-group.icon-group .icon-input-wrap {
  display: flex;
  border-radius: 0.2em;
}
.form-group.icon-group .icon-input-wrap input {
  padding-left: 35px;
}
.form-group.icon-group .icon-input-wrap .input-icon {
  position: absolute;
  left: 0;
  line-height: 1em;
  font-size: 1.5em;
  padding: 5px;
  padding-inline-end: 0px;
  color: white;
  overflow: hidden;
}
.form-group.icon-group .icon-input-wrap.rtl input {
  padding-right: 35px;
  padding-left: 5px;
}
.form-group.icon-group .icon-input-wrap.rtl .input-icon {
  right: 0;
  left: initial;
}
.label-form-group .form-group {
  float: left;
}
.form-group .checkbox-container {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3em;
  height: 2.2em;
  padding: 0.6em 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.form-group .checkbox-container.disabled {
  background: rgba(100, 100, 100, 0.5);
  color: rgba(255, 255, 255, 0.5);
  border-style: hidden;
}
.checkbox-group .checkbox-container {
  padding: 0;
}
.label-form-group .form-group .checkbox-container {
  height: initial;
}
.label-form-group .form-group.color-group {
  border-radius: 3px 0 0 3px;
  border-right: 0;
  margin: 0 15px 0 0;
  vertical-align: bottom;
}
.label-form-group .form-group .font-color {
  margin: 0;
}
.label-form-group .form-group.checkbox-group {
  max-height: 2.2em;
  width: calc(100% - 2.2em);
}
.label-form-group .form-group.checkbox-group .checkbox-container {
  width: 100%;
  max-height: 2.2em;
  border-radius: 0.3em 0 0 0.3em;
}
.label-form-group .form-group.checkbox-group .checkbox-container .checkbox-label {
  box-sizing: border-box;
}
.label-form-group .form-group.checkbox-group .checkbox-container .checkbox-container {
  background: none;
}
.label-form-group .form-group.color-group .font-color-container {
  vertical-align: bottom;
}
.label-form-group .form-group.color-group .font-color-container .font-color {
  margin-right: 0;
}
/***********************************************************/
.dialog-overlay.scrollable .btn-split-container {
  overflow: visible;
  position: relative;
  width: 100%;
  bottom: -3.5em;
  left: 0;
}
.dialog-overlay.scrollable .btn-split-container .btn-split:hover {
  padding: 0.5em 6em;
}
.btn-split-container.btn-large {
  bottom: 4em;
}
.btn {
  position: relative;
  cursor: pointer;
  display: inline-block;
  border-radius: 4em;
  padding: 0.5em 1em;
  border-style: none;
  background: white;
  color: black;
  box-shadow: 0 0 2px 0 black;
  transition: all 0.2s;
}
.btn .svg-inline--fa {
  margin-right: 0.5em;
}
.btn.only-icon .svg-inline--fa {
  margin-right: 0;
}
.btn.btn-black {
  background: black;
  color: white;
  box-shadow: initial;
}
.btn.btn-l {
  font-size: 1.2em;
}
.btn:hover {
  box-shadow: 0 0 5px 0 black;
}
.btn-split {
  display: block;
  margin: auto;
  padding: 0.5em 6em;
}
.btn-split.btn-large {
  font-size: 2em;
  padding: 0.5em 2em;
}
.btn-split:hover {
  padding: 0.6em 7em;
}
.btn-split.btn-large:hover {
  padding: 0.6em 2.2em;
}
.btn:disabled {
  background: grey;
  color: white;
  box-shadow: initial;
  cursor: not-allowed;
}
.btn:disabled:hover {
  box-shadow: initial;
}
.btn-split:disabled:hover {
  padding: 0.5em 8em;
  box-shadow: 0 0 30px 0 black;
}
.btn-split.btn-large:disabled:hover {
  padding: 0.5em 2em;
}
.btn .svg-inline--fa + span {
  margin-left: 5px;
}
.fas-badge {
  position: absolute;
  font-size: 1.2em;
  bottom: 5%;
  right: 5%;
}
.drop-container .dropzone {
  cursor: pointer;
  box-sizing: border-box;
  padding: 1em;
  border: 2px dashed;
  width: 100%;
  margin: 0.5em 0;
  transition: all 0.2s;
}
.drop-container .dropzone.active {
  border: 2px dashed green;
}
.drop-container .dropzone:hover {
  border: 2px dashed;
  padding: 1.5em 1em;
}
.drop-container .dropzone p {
  vertical-align: middle;
}
.crop-container {
  max-width: 250px;
}
.checkbox-container {
  cursor: pointer;
}
.checkbox-container:hover .checkbox-slide {
  background: #f48120;
}
.checkbox-container.disabled {
  cursor: default;
}
.checkbox-container.disabled .checkbox-slide {
  background: #323232;
}
.checkbox-container .checkbox-slide.active {
  background: #00A79D;
}
.checkbox-container .checkbox-slide.active:after {
  border: solid white;
  border-width: 0 0.3em 0.3em 0;
}
.checkbox-container .checkbox-label {
  padding: 10px;
  padding-right: 35px;
  width: 100%;
}
.checkbox-container .checkbox-slide {
  position: absolute;
  display: block;
  right: 0;
  width: 1.2em;
  height: 1.2em;
  border-radius: 3px;
  background: #ebebeb;
  margin-right: 0.6em;
  margin-top: -0.1em;
  cursor: pointer;
  transition: all 200ms;
  float: right;
}
.checkbox-container .checkbox-slide:after {
  content: "";
  position: absolute;
  display: block;
  left: 0.4em;
  top: -0.35em;
  width: 0.45em;
  height: 0.95em;
  transform: rotate(35deg);
}
.loading-visuals.shown {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}
.loading-visuals.shown.no-background {
  background: none;
}
.loading-visuals {
  display: none;
}
.loading-visuals .loading-image {
  max-width: 75%;
  max-height: 75%;
  object-fit: contain;
  margin: auto;
  transform-origin: center;
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.clickable {
  cursor: pointer;
}
.btn:focus .hover-msg,
.btn:hover .hover-msg,
.btn:active .hover-msg {
  display: initial;
  transform: scale(1);
}
.btn .hover-msg {
  top: 2em;
  left: 0.5em;
  max-width: 50vw;
  white-space: nowrap;
}
.btn.link {
  border-radius: 0;
}
.btn.link:hover :first-child {
  transition: all 200ms;
  margin-left: 20px;
}
.sm-invis {
  display: none;
}
.form-group .autocomplete-list {
  position: absolute;
  z-index: 99;
  width: 250px;
  background: #969696;
  border-radius: 0.2em;
}
.form-group .autocomplete-list div {
  cursor: pointer;
  padding: 10px 5px;
}
.form-group .autocomplete-list div:hover {
  background: #4b4b4b;
}
.form-group .autocomplete-list div.active {
  background: #323232;
  border-radius: inherit;
}
.form-group .taglist {
  max-width: 250px;
  padding-top: 10px;
}
.form-group .taglist .no-tag-test {
  line-height: 2.7em;
}
.form-group-related-text-btn {
  cursor: pointer;
  margin-top: -7px;
  margin-bottom: 7px;
  color: white;
  filter: drop-shadow(0 0 2px black);
}
.form-group-related-text-btn:hover {
  color: #00A79D;
}
.taglist {
  display: flex;
  flex-wrap: wrap;
  margin: 2px 0;
}
.taglist .tag {
  cursor: default;
  color: #ffffff;
  border-radius: 0.2em;
  padding: 5px;
  margin: 0 2px 2px 0;
  background: #00a79d;
  max-height: 1em;
  white-space: nowrap;
}
.taglist .tag.subject {
  background: #f48120;
}
.taglist .tag.language {
  background: #652d90;
}
.taglist .tag.gametype {
  background: #00adee;
}
.taglist .tag.years {
  background: #e61088;
}
.form-group .taglist .tag {
  cursor: pointer;
}
.form-group .taglist .tag .tag-trash {
  font-size: 0.8em;
  margin-left: 4px;
}
.form-group .taglist .tag:hover .tag-trash {
  color: #AA0000;
}
.form-group .rc-slider-disabled {
  background: initial;
  color: rgba(255, 255, 255, 0.5);
  border-style: hidden;
}
.form-group .rc-slider {
  height: 2.2em;
  margin-top: 0.5em;
}
.rc-slider-tooltip {
  z-index: 999;
}
.rc-slider-tooltip .rc-slider-tooltip-inner {
  box-shadow: none;
}
.dialog-overlay .dialog.bulk-dialog {
  max-width: 75vw;
}
.dialog-overlay .dialog.bulk-dialog form .flex {
  gap: 10px;
  flex-wrap: wrap;
}
.dialog-overlay .dialog.bulk-dialog form .flex .org-category-field {
  background: rgba(255, 255, 255, 0.1);
  padding: 7px;
  border-radius: 3px;
  width: 100%;
  max-width: none;
}
.dialog-overlay .dialog.bulk-dialog .bulk-form {
  margin-top: 20px;
}
.dialog-overlay .dialog.bulk-dialog .well {
  padding: 20px;
}
.dialog-overlay .dialog.bulk-dialog .playlist-preview .pl-description:after {
  display: none;
}
.dialog-overlay .dialog.bulk-dialog .dialog-top {
  background: linear-gradient(202.5deg, #00a79d 200px, #000038 1200px);
}
.dialog .floor-table {
  margin-top: 20px;
}
.dialog .floor-table td .green {
  color: #00c736;
}
.dialog .floor-table td .yellow {
  color: #d6de23;
}
.dialog .floor-table td .red {
  color: #AA0000;
}
.dialog .floor-table td .max-width {
  max-width: 200px;
}
.pop-container {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 9999;
}
.pop-container .msg {
  /*animation: wiggle linear 100ms 5;*/
  transition: all 750ms;
  position: relative;
  right: 0;
  margin: 10px 0;
  min-width: 300px;
  background-color: #F2F2F2;
  color: black;
  border-bottom-left-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}
.pop-container .msg .msg-content {
  padding: 10px 20px 30px 20px;
}
.pop-container .msg .msg-content .title {
  font-weight: bold;
  margin-bottom: 10px;
}
.pop-container .msg.error:before {
  background-image: linear-gradient(to right, #AA0000, white, #AA0000, white, #AA0000);
}
.pop-container .msg:before {
  position: relative;
  animation: spinGradient linear 2s infinite;
  background-image: linear-gradient(to right, #00A79D, white, #00A79D, white, #00A79D);
  background-size: 200%;
  content: "";
  display: block;
  height: 8px;
}
.pop-container .spawn {
  transform: translate(120%);
}
.pop-container .in {
  transform: translate(0);
}
.pop-container .out {
  transform: translate(120%);
}
.dialog .sort-container {
  display: flex;
  flex-direction: row;
}
.dialog .sort-container .asc {
  cursor: pointer;
  font-size: 1.4em;
  padding: 5px 10px 5px 10px;
  border: 1px solid white;
  border-radius: 3px;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
}
.dialog .sort-container .checkbox-label {
  padding-left: 0;
  padding-right: 10px;
}
.monospace {
  font-family: 'Consolas', Courier, monospace;
}
.unit-dialog .well {
  position: relative;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}
.unit-dialog .network-info {
  display: flex;
  flex-wrap: wrap;
}
.unit-dialog .projector-info,
.unit-dialog .license-info,
.unit-dialog .versions-info,
.unit-dialog .network-info,
.unit-dialog .touch-stats {
  background: rgba(255, 255, 255, 0.1);
  padding: 1em;
  margin-bottom: 0.5em;
}
.unit-dialog .projector-info .network,
.unit-dialog .license-info .network,
.unit-dialog .versions-info .network,
.unit-dialog .network-info .network,
.unit-dialog .touch-stats .network {
  background: rgba(255, 255, 255, 0.1);
  padding: 1em;
  margin-bottom: 0.5em;
}
.unit-dialog .group {
  margin-bottom: 0.5em;
  display: flex;
}
.unit-dialog .group label {
  font-weight: bold;
  margin-right: 0.5em;
}
.unit-dialog .dialog-icon {
  cursor: pointer;
}
.unit-dialog .dialog-icon:hover {
  color: #AA0000;
}
.org-list .org-filters {
  display: flex;
  flex-wrap: wrap;
}
.org-list .org-filters .form-group {
  margin-right: 10px;
}
.org-list .form-control {
  border: 1px solid black;
  color: black;
}
.unit-index {
  padding: 20px;
}
.unit-index th .form-group .form-control {
  width: 100%;
}
.unit-table .hand {
  cursor: pointer;
}
.unit-table tbody td .tag-options {
  display: flex;
  gap: 2px;
  width: 100%;
  min-width: 100px;
}
.unit-table tbody td .tag-options input {
  border: none;
  width: 0;
  background: transparent;
}
.unit-table tbody td .tag-options input:focus {
  width: 150px;
}
.unit-table tbody td .taglist {
  flex-wrap: nowrap;
  margin: 0;
  gap: 2px;
}
.unit-table tbody td .taglist .tag {
  font-size: 0.95em;
  padding: 2px 4px;
  margin: 0;
}
.unit-table tbody td .taglist .tag:hover svg {
  width: 0.95em;
}
.unit-table tbody td .taglist .tag svg {
  width: 0;
  cursor: pointer;
  transition: width 250ms;
}
.unit-table tbody td .taglist .tag svg:hover {
  color: #AA0000;
}
.enable-form {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.enable-form .enable-item {
  margin-right: 5px;
  width: 200px;
}
.enable-form .enable-item .form-group .checkbox-container {
  background: #00ADEEF2;
}
.enable-form .form-group .checkbox-container .checkbox-label {
  margin-right: 35px;
}
.form-group.bulk-picker .label {
  font-weight: bold;
  margin: 4px 0;
}
.form-group.bulk-picker .item-container-table td {
  cursor: pointer;
}
.curtains-container {
  position: fixed;
  width: 100%;
  z-index: 9999999999;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.curtains-container .curtain {
  width: 100%;
  text-align: center;
  background: #930a0a;
  color: white;
}
.curtains-container .curtain .close {
  transition: 200ms;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2em;
  padding: 5px;
  cursor: pointer;
  transform: rotate(0deg);
}
.curtains-container .curtain .close :hover {
  color: lightblue;
}
.curtains-container .curtain .expired-license-curtain,
.curtains-container .curtain .no-license-curtain,
.curtains-container .curtain .expiring-license-curtain {
  padding: 20px;
  text-align: center;
}
.item-container-table .scroll {
  max-height: 164px;
  overflow-y: scroll;
  margin-bottom: 5px;
  padding: 0 2px 2px 2px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
}
.item-container-table .scroll table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #202020;
  padding: 4px;
}
.item-container-table .banner-status.active {
  color: #5A5;
}
.item-container-table .banner-status.ended {
  color: #C22;
}
.item-container-table .banner-status.future {
  color: #06D;
}
.item-container-table .pages {
  margin-top: 10px;
}
.dialog .simple-table thead tr th {
  border-color: white;
}
.dialog .simple-table input {
  border-color: white;
}
table.simple-table {
  scroll-margin: 120px 260px;
  font-size: 0.9em;
}
table.simple-table thead .form-group {
  width: inherit;
  margin-bottom: 2px;
  position: relative;
  min-height: 1.8em;
}
table.simple-table thead .form-group .filter-icon {
  vertical-align: 0.5em;
}
table.simple-table thead .form-group input,
table.simple-table thead .form-group select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  height: initial;
  padding: 4px;
  font-size: 0.8em;
}
table.simple-table tbody .form-group {
  margin-bottom: 0;
}
table.simple-table tbody .form-group input {
  height: 1.5em;
  padding: 1px 2px;
}
table.simple-table tbody tr:hover {
  background-color: rgba(255, 136, 0, 0.2);
}
table.simple-table tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}
table.simple-table tr:nth-child(even).reseller {
  background-color: #F48120F2;
}
table.simple-table tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0);
}
table.simple-table tr:nth-child(odd).reseller {
  background-color: #F48120F2;
}
table.simple-table tr.seperator {
  font-size: 0.8em;
  font-weight: bold;
  background: rgba(255, 47, 116, 0.33);
}
table.simple-table tr.empty {
  height: 1em;
}
table.simple-table tr.warning {
  background-color: #aa00001c;
}
table.simple-table tr th {
  font-weight: bold;
  text-align: left;
  padding-right: 4px;
  border-bottom: 1px solid black;
  user-select: none;
}
table.simple-table tr th.sortable {
  cursor: pointer;
}
table.simple-table tr td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 2px 10px 2px 2px;
}
table.simple-table tr td.overflow {
  overflow: visible;
}
table.simple-table tr td.flex-items {
  display: flex;
  gap: 3px;
}
table.simple-table tr td.warning {
  color: white;
  background-color: #8f0101d9;
}
table.simple-table tr td.warning-light {
  color: white;
  background-color: #d3ac00d9;
}
table.simple-table tr td.xstable.simple-table tr td.s,
table.simple-table tr td.m,
table.simple-table tr td.xl {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
table.simple-table tr td.xs {
  width: 50px;
  max-width: 50px;
}
table.simple-table tr td.s {
  width: 100px;
  max-width: 100px;
}
table.simple-table tr td.m {
  width: 200px;
  max-width: 200px;
}
table.simple-table tr td.xl {
  width: 300px;
  max-width: 300px;
}
table.simple-table tr td .btn-list {
  display: flex;
  gap: 4px;
}
table.simple-table tr td .btn {
  padding: 2px 6px;
}
.org-table .org-cat,
.simple-table .org-cat {
  display: inline-block;
  padding: 1px 3px;
  margin-right: 4px;
  box-shadow: 0 0 2px black;
}
.org-table table,
.simple-table table {
  table-layout: fixed;
}
.org-table table tbody tr:nth-child(even).reseller,
.simple-table table tbody tr:nth-child(even).reseller {
  background-color: #F48120F2;
}
.org-table table tbody tr:nth-child(even) .org-cat.SalesPartner,
.simple-table table tbody tr:nth-child(even) .org-cat.SalesPartner {
  background-color: #fb9c4b;
}
.org-table table tbody tr:nth-child(odd).reseller,
.simple-table table tbody tr:nth-child(odd).reseller {
  background-color: #f48020c0;
}
.org-table table tbody tr:nth-child(odd) .org-cat.SalesPartner,
.simple-table table tbody tr:nth-child(odd) .org-cat.SalesPartner {
  background-color: #f48120;
}
.org-table table tbody tr:hover .org-cat,
.simple-table table tbody tr:hover .org-cat {
  font-weight: initial;
}
.sortable.dragged {
  z-index: 99999;
}
.dialog.deleted-games .game {
  margin: 0 10px 10px 0;
}
.unicode-table thead th {
  padding-right: 5px;
}
.unicode-table tbody td {
  padding: 5px;
  border: 1px solid black;
}
.text-trick-container {
  display: flex;
}
.text-trick-container .text-trick {
  margin-right: 40px;
}
.text-tips-form .form-group .form-control {
  border-color: black;
  color: black;
}
.flex {
  display: flex;
}
.flex.wrap {
  flex-wrap: wrap;
}
.flex.gap {
  gap: 20px;
}
.flex.gap-sm {
  gap: 10px;
}
.flex.space-between {
  justify-content: space-between;
}
.flex.wide {
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
}
.browser-record {
  margin: 20px 20px 20px 0;
}
.browser-record .control-icon {
  font-size: 2em;
  margin-right: 10px;
  cursor: pointer;
}
.browser-record .control-icon.recording {
  color: red;
}
.keyboard {
  border: 1px solid #323232;
  padding: 7px 15px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  box-sizing: border-box;
}
.well {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  margin: 0 20px 20px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.dialog .general-error {
  color: #ff8f8f;
}
.general-error {
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(100, 0, 0, 0.2);
  color: #fc3636;
  margin: 10px 0;
}
.general-error svg {
  margin-inline-end: 0.2em;
}
.general-error .form-error-container .form-error {
  margin: 0.5em 0;
}
.general-error ul {
  list-style: circle;
}
.general-error ul li {
  margin-inline-start: 1em;
  font-size: 0.9em;
  font-style: italic;
}
.dialog .pages span {
  color: white;
}
.pages {
  width: 100%;
  user-select: none;
}
.pages span {
  border: 1px solid #f0f0f0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 8px;
  margin-right: 4px;
  width: 22px;
  display: inline-block;
  text-align: center;
  transition: all 125ms;
  cursor: pointer;
  color: #323232;
  font-size: 0.8em;
}
.pages span.disabled {
  cursor: default;
  border-color: #d8d8d8;
  background: #d8d8d8;
}
.pages span.disabled:hover {
  transform: none;
}
.pages span.active {
  font-weight: bold;
  border-width: 2px;
  padding: 7px;
}
.pages span:hover {
  transform: translateY(-4px);
}
.react-datepicker-popper {
  z-index: 2;
}
.margin-right {
  margin-right: 5px;
}
.smart-add-form .playlist-preview {
  float: none;
}
.dialog-account form {
  max-width: 250px;
}
.sub-navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}
.sub-navigation .title {
  width: 100%;
  font-size: 0.9em;
  margin-bottom: -3px;
  font-weight: bold;
}
.sub-navigation .btn.active {
  background-color: #882144;
}
form .form-group .form-control {
  color: black;
  border-color: black;
}
form .form-group .form-control.pointer {
  cursor: pointer;
}
form .form-group.icon-group .icon-input-wrap .input-icon {
  color: black;
}
form .btn .hover-msg {
  white-space: initial;
}
.dialog form .form-group.icon-group .input-icon {
  color: white;
}
.dialog form .form-group .form-control {
  border: 1px solid white;
  color: white;
}
.dialog form .form-group .form-control::placeholder {
  color: #a0a0a0;
}
.dialog form .form-group .form-control:disabled {
  background: rgba(100, 100, 100, 0.5);
  color: rgba(255, 255, 255, 0.5);
}
.dialog form .form-group .form-control.slider input {
  color: white;
}
.dialog form .form-group.highlight .form-control {
  border-color: #f10feb;
  box-shadow: 0px 0px 10px #f10feb;
}
.detailed-preview {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.detailed-preview .part {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  max-width: 540px;
}
.detailed-preview .part .text-content {
  max-width: 530px;
  flex: 0 0 100%;
  white-space: pre-wrap;
}
.detailed-preview .part .text-content .document-btn {
  margin-top: 0.5em;
  margin-right: 0.5em;
}
.detailed-preview .part .text-content .details {
  background: rgba(0, 0, 0, 0.05);
  padding: 5px;
  font-size: 0.8em;
  margin-top: 0.5em;
}
.detailed-preview .part .text-content .edit-date label {
  font-weight: bold;
}
.detailed-preview .part .authors {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}
.detailed-preview .part .authors h2 {
  flex-basis: 100%;
}
.detailed-preview .part .authors img {
  border-radius: 100%;
}
.detailed-preview .part .authors .authors-container {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
}
.detailed-preview .part .authors .authors-container .author-preview {
  float: none;
}
.detailed-preview .part .authors .authors-container .fake-img {
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.detailed-preview .author-and-stats .left {
  float: left;
  max-width: 640px;
  margin-right: 20px;
}
.detailed-preview .author-and-stats .left .game {
  margin-right: 20px;
}
.detailed-preview .author-and-stats .left .game-and-authors {
  display: flex;
  flex-wrap: wrap;
}
.form-width {
  width: 250px;
}
.ad-index form .flex {
  gap: 20px;
}
.ad-index form p {
  max-width: 660px;
}
.ad-index form .bulk-price {
  margin: 10px 0;
}
.ad-index form .form-width p {
  margin-bottom: 0.2em;
  max-width: 250px;
}
.ad-index .ad-preview {
  width: 320px;
  height: 200px;
  position: relative;
}
.ad-index .ad-preview .background {
  width: 100%;
  height: 100%;
}
.ad-index .ad-preview .BeforeGameStart,
.ad-index .ad-preview .AfterGameEnd {
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  height: 200px;
}
.ad-index .ad-preview .AboveGridPlaylist {
  position: absolute;
  top: 0;
  left: 35px;
  width: 250px;
  scale: 0.8;
}
.ad-index .ad-preview .ExitDialog {
  position: absolute;
  top: 15px;
  left: 35px;
  width: 250px;
}
.ad-index .ad-preview .LockScreen {
  position: absolute;
  width: 200px;
  top: 15px;
  left: 60px;
}
.ad-index .item-container-table .scale-container {
  width: calc(0.2 * 320px);
  height: calc(0.2 * 200px);
}
.ad-index .item-container-table .scale-container .ad-preview {
  scale: 0.2;
  transform-origin: bottom left;
}
.share-dialog .copy-icon {
  font-size: 2em;
}
.share-dialog p {
  max-width: 500px;
  margin-bottom: 10px;
}
.share-dialog .hover-trigger {
  cursor: pointer;
}
.share-dialog input {
  padding: 5px;
  display: block;
  margin-bottom: 10px;
  width: 500px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 3px;
  border: 1px solid white;
}
table.info-table th {
  font-weight: bold;
}
table.info-table th,
table.info-table td {
  border: 1px solid white;
  text-align: left;
  padding: 2px 4px;
}
.simple-info-table {
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 20px;
}
.simple-info-table .info {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
}
.simple-info-table .info .label {
  color: #4b4b4b;
}
@keyframes spinGradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
@keyframes swing-anim {
  65% {
    transform: rotate(0deg);
  }
  67.5% {
    transform: rotate(5deg);
  }
  72.5% {
    transform: rotate(-5deg);
  }
  77.5% {
    transform: rotate(5deg);
  }
  82.5% {
    transform: rotate(-5deg);
  }
  87.5% {
    transform: rotate(5deg);
  }
  92.5% {
    transform: rotate(-5deg);
  }
  97.5% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    transform: translateX(-100%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.5);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}
@keyframes shadow-anim {
  from {
    filter: drop-shadow(0 0 var(--blur-length) var(--shadow-color));
  }
  to {
    filter: drop-shadow(0 0 0 var(--shadow-color));
  }
}
@keyframes fade-anim {
  65% {
    opacity: 1;
  }
  69.375% {
    opacity: 0.5;
  }
  73.75% {
    opacity: 1;
  }
  78.125% {
    opacity: 0.5;
  }
  82.5% {
    opacity: 1;
  }
  86.875% {
    opacity: 0.5;
  }
  91.25% {
    opacity: 1;
  }
  95.625% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media handheld {
  body {
    min-height: 101vh;
  }
}
@media screen and (min-width: 768px) {
  .xl-hide,
  .hide-xl {
    display: none;
  }
  .crop-container {
    max-width: 400px;
  }
  .sm-invis {
    display: none;
  }
}
.new {
  animation-name: appear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.action-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
.action-group .description {
  text-wrap: wrap;
}
.action-group .btn {
  padding: 5px;
  min-width: 50px;
}
.color-multiplied-img {
  max-width: 100%;
  max-height: 100%;
  background-blend-mode: multiply;
  background-size: 100%;
  mask-size: 100%;
  display: inline-block;
}
.color-multiplied-img .invisible {
  opacity: 0;
  vertical-align: top;
  max-width: 100%;
  max-height: 100%;
}
.user-search-dropdown {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}
.user-search-dropdown .search-result {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.user-search-dropdown .search-result:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.2);
}
.user-search-dropdown .search-result:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0);
}
.user-search-dropdown .search-result:hover {
  font-weight: bold;
}
.user-search-dropdown .search-result div {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  padding: 2px;
}
.verified-mark {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 10px;
  width: 130px;
}
.verified-mark img {
  width: 90px;
}
.verified-mark .verified-mark-explanation {
  color: #03746c;
  font-weight: bold;
  text-align: center;
}
.dialog .verified-mark-explanation {
  color: #cabd42;
}
.taglist[dir="rtl"] .tag.verified-tag-box {
  padding-right: 3px;
  padding-left: 0;
}
.taglist[dir="rtl"] .tag.verified-tag-box .verified-mark-tag {
  left: -0.2em;
  right: 0;
}
.detailed-preview .taglist[dir="rtl"] .tag.verified-tag-box {
  padding-right: 5px;
  padding-left: 0;
}
.taglist .tag.verified-tag-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F9B417;
  letter-spacing: 0.05em;
  padding-right: 0;
  border-radius: 0.8em;
}
.taglist .tag.verified-tag-box .verified-mark-tag {
  width: 1.8em;
  height: 1.8em;
  right: -0.2em;
  position: relative;
}
.youtube-preview {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.youtube-preview .youtube-image {
  position: absolute;
  background-color: #323232;
  border-radius: 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.youtube-preview .youtubebutton-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.youtube-preview .youtubebutton-container .youtubebutton {
  width: fit-content;
  height: fit-content;
}
.youtube-preview .youtubebutton-container .youtubebutton:hover {
  scale: 1.5;
}
.youtube-preview .youtubebutton-container .youtubebutton.inactive {
  pointer-events: none;
}
.youtube-preview .youtube-text {
  position: absolute;
  color: white;
  padding: 13px;
  box-sizing: border-box;
  font-family: "YouTube Sans", "Roboto", sans-serif;
  line-height: 2.8rem;
  font-weight: 600;
  background: linear-gradient(black, transparent);
  width: 100%;
  height: 20%;
  border-radius: 20px 20px 0 0;
}
.detailed-preview .tag.verified-tag-box .verified-mark-tag {
  width: 2em;
  height: 2em;
}
.mail-message-form {
  display: flex;
  gap: 20px;
}
.mail-message-form .mail-preview {
  width: 500px;
  height: 600px;
  background-color: white;
  color: black;
  padding: 10px;
}
